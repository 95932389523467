import { Button, Modal } from "react-bootstrap"

interface ConfirmationProps {
    message: string
    show: boolean
    onHide: () => void
    onConfirm: () => void
}

function Confirmation(props: ConfirmationProps) {
    return (
        <Modal show={props.show} onHide={props.onHide} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Confirm!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <b>{props.message}</b>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={props.onHide}>No</Button>
                <Button variant="danger" onClick={() => props.onConfirm()}>Yes, do it</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default Confirmation