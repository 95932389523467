import { ChangeEvent, RefObject, SyntheticEvent, useEffect, useRef, useState } from "react"
import EventService, { IEvent, IEventSchedule } from "../../services/EventService"
import { Alert, Button, ButtonGroup, Dropdown, Form, Modal, Spinner, ToggleButton, ToggleButtonGroup } from "react-bootstrap"
import { DayNames, MonthNames } from "../../components/DaysOfWeekCheckboxes"
import moment from "moment"
import EventTicketService, { IReservedSeatFilter, IEventTicketCreate } from "../../services/EventTicketService"
import Configs from "../../Configs"
import Utils from "../../utils/Utils"
import { ISeat } from "../../services/SeatService"
import TimeZoneService from "../../services/TimeZoneService"
import Clock from "../../components/Clock"
import { PaymentServiceType } from "../../constants/PaymentServiceType"
import { PaymentStatusType } from "../../constants/PaymentStatusType"
import uuid from "react-uuid"

interface IMyEventTicketCreateModalProps {
    providerId: number | null
    show: boolean
    onClose: () => void
    onSuccess: (eventTicketId: number) => void
}

function MyEventTicketCreateModal(props: IMyEventTicketCreateModalProps) {
    const SeatSizes = ["Small", "Medium", "Large"]
    const [currentSeatSize, setCurrentSeatSize] = useState("Medium")

    const initEventTicketCreate: IEventTicketCreate = {
        eventId: null,
        eventDate: null,
        eventScheduleId: null,
        seatIds: [],
        quantity: null,
        note: "",
        paymentService: PaymentServiceType.CASH,
        paymentStatus: PaymentStatusType.COMPLETED,
        customer: {
            id: null,
            name: "",
            tel: "",
            address: "",
            email: "",
            personalId: ""
        }
    }

    const [eventTicketCreate, setEventTicketCreate] = useState<IEventTicketCreate>(initEventTicketCreate)

    const handleSeatChange = (e: ChangeEvent<HTMLInputElement>) => {
        var seatId = parseInt(e.target.value)
        var seatIds = eventTicketCreate.seatIds.map(x => x)

        if (e.target.checked) {
            if (!seatIds.includes(seatId)) {
                seatIds.push(seatId)
                setEventTicketCreate({
                    ...eventTicketCreate,
                    seatIds: seatIds
                })
            }
        } else {
            setEventTicketCreate({
                ...eventTicketCreate,
                seatIds: seatIds.filter(x => x !== seatId)
            })
        }
    }

    const [events, setEvents] = useState<Array<IEvent>>([])
    const [dates, setDates] = useState<Array<Date>>([])
    const [schedules, setSchedules] = useState<Array<IEventSchedule>>([])
    const [seatMatrix, setSeatMatrix] = useState<Array<Array<any>>>([])
    const [orderedSeats, setOrderedSeats] = useState<{
        seatIds: Array<number>,
        availableQuantity: number | null
    }>({
        seatIds: [],
        availableQuantity: null
    })
    const [timeZoneDisplayName, setTimeZoneDisplayName] = useState<string>()
    const [zonedDateTime, setZonedDateTime] = useState<string>()

    const LoadEvents = async () => {
        if (props.providerId && props.show) {
            const res = await EventService.listAllPublished(props.providerId)
            if (res?.isSuccess) {
                setEvents(res.data)
            } else {
                console.log(res?.message)
                setEvents([])
            }
        } else {
            setEvents([])
        }
    }

    const [isLoadDates, setIsLoadDates] = useState<string>("")
    const [pageOfDates, setPageOfDates] = useState<number>(1)
    const LoadDates = async (pageId: number) => {
        if (props.providerId && eventTicketCreate.eventId && pageId > 0) {
            setPageOfDates(pageId)
            const res = await EventService.getEventDates(props.providerId, eventTicketCreate.eventId, pageId)
            if (res?.isSuccess) {
                const list: Array<Date> = res.data.map((x: string) => Utils.stringToDate(x))

                //set default date
                setEventTicketCreate({ ...eventTicketCreate, eventDate: list.length ? moment(list[0]).format("YYYY-MM-DD") : null })

                setDates(list)
                setIsLoadDates(uuid())
            } else {
                console.log(res?.message)
                setDates([])
                setSchedules([])
                setSeatMatrix([])
                setEventTicketCreate(initEventTicketCreate)
            }
        } else {
            setDates([])
            setSchedules([])
            setSeatMatrix([])
            setEventTicketCreate(initEventTicketCreate)
        }
    }

    const handleNextDates = () => {
        LoadDates(pageOfDates + 1)
    }

    const handlePrevDates = () => {
        if (pageOfDates > 1)
            LoadDates(pageOfDates - 1)
    }

    const handleCurrentDates = () => {
        LoadDates(1)
    }

    const showClock = async () => {
        if (eventTicketCreate.eventId) {
            const event = events.find(x => x.id === eventTicketCreate.eventId)
            if (event?.timeZoneInfo?.id) {
                setTimeZoneDisplayName(event?.timeZoneInfo?.displayName || "")
            } else {
                setTimeZoneDisplayName("")
            }

            const res = await TimeZoneService.getCurrentDateTimeById(event?.timeZoneInfo?.id || "")
            if (res?.isSuccess) {
                setZonedDateTime(res.data)
            } else {
                console.log(res?.message)
            }
        } else {
            setTimeZoneDisplayName("")
            setZonedDateTime("")
        }
    }

    const LoadSeats = () => {
        if (eventTicketCreate.eventId) {
            const event = events.find(x => x.id === eventTicketCreate.eventId)
            if (event) {
                const seats = event.seats || []
                const letters = seats.map((x) => x.letter).filter((value, index, self) => self.indexOf(value) === index).sort((a, b) => a > b ? 1 : -1)
                const minNumber = Math.min(...seats.map(x => x.number))
                const maxNumber = Math.max(...seats.map(x => x.number))

                const matrix: Array<any> = []
                letters.forEach(letter => {
                    const row: Array<any> = []
                    for (let num = minNumber; num <= maxNumber; num++) {
                        const seat = seats.find(x => x.letter === letter && x.number === num)
                        if (seat) {
                            let disabled = orderedSeats.seatIds.includes(seat.id || 0)

                            row.push(<li className={`seat ${disabled ? "ordered" : ""}`} key={seat.id}>
                                <input type="checkbox" name="seat" id={`seat-${seat.id || 0}`} value={seat.id || 0} disabled={disabled} checked={eventTicketCreate.seatIds.includes(seat.id || 0)} onChange={handleSeatChange} />
                                <label htmlFor={`seat-${seat.id || 0}`} style={{ "--color": seat.seatType?.color || "#ced4da" } as React.CSSProperties}>{seat.letter}{seat.number}</label>
                            </li>)
                        } else {
                            row.push(<li className="seat unserved" key={`${letter}${num}`}><label style={{ "--color": "#ced4da" } as React.CSSProperties}></label></li>)
                        }
                    }

                    matrix.push(<ul key={letter} className="seat-row">{row}</ul>)
                })

                setSeatMatrix(matrix)
            } else {
                setSeatMatrix([])
            }
        } else {
            setSeatMatrix([])
        }
    }

    const LoadSchedules = async () => {
        if (props.providerId && eventTicketCreate.eventId && eventTicketCreate.eventDate) {
            const res = await EventService.getEventSchedules(props.providerId, eventTicketCreate.eventId, eventTicketCreate.eventDate)

            if (res?.isSuccess) {
                const list: IEventSchedule[] = res.data;

                setSchedules(list)

                //set default time
                if (list.filter(x => x.id === eventTicketCreate.eventScheduleId).length === 0) {
                    setEventTicketCreate({ ...eventTicketCreate, eventScheduleId: list.length ? list[0].id : null, seatIds: [], quantity: null })
                } else {
                    setEventTicketCreate({ ...eventTicketCreate, seatIds: [], quantity: null })
                }
            } else {
                console.log(res?.message)
                setSchedules([])
            }
        } else {
            setSchedules([])
        }
    }

    useEffect(() => {
        setMessage("")
        setValidatedForm(false)
        setEventTicketCreate(initEventTicketCreate)
        LoadEvents()
    }, [props.providerId, props.show])

    useEffect(() => {
        LoadDates(1)
        showClock()
    }, [eventTicketCreate.eventId])

    useEffect(() => {
        LoadSchedules()
    }, [isLoadDates])

    useEffect(() => {
        setEventTicketCreate({ ...eventTicketCreate, seatIds: [], quantity: null })
    }, [eventTicketCreate.eventScheduleId])

    useEffect(() => {
        LoadSeats()
    }, [eventTicketCreate.seatIds, orderedSeats])

    const [isCheckSeats, setIsCheckSeats] = useState<boolean>(false)
    useEffect(() => {
        setIsCheckSeats(true)
    }, [eventTicketCreate.eventId, eventTicketCreate.eventDate, eventTicketCreate.eventScheduleId])

    const GetReservedSeats = async () => {
        if (props.providerId && isCheckSeats) {
            setIsCheckSeats(false)

            if (eventTicketCreate.eventId && eventTicketCreate.eventDate && eventTicketCreate.eventScheduleId) {
                const eventSchedule = (events.find(x => x.id === eventTicketCreate.eventId)?.schedules || []).find(x => x.id === eventTicketCreate.eventScheduleId)
                if (eventSchedule) {
                    var filter: IReservedSeatFilter = {
                        eventId: eventTicketCreate.eventId,
                        eventDate: eventTicketCreate.eventDate,
                        eventScheduleId: eventTicketCreate.eventScheduleId
                    }

                    const res = await EventTicketService.getReservedSeats(props.providerId, filter)
                    if (res?.isSuccess) {
                        setOrderedSeats({
                            seatIds: res.data.map((x: ISeat) => x.id || 0),
                            availableQuantity: res.availableQuantity
                        })
                    } else {
                        console.log(res?.message)
                        setOrderedSeats({
                            seatIds: [],
                            availableQuantity: null
                        })
                    }
                }
            }
        }
    }

    useEffect(() => {
        GetReservedSeats()
    }, [isCheckSeats])

    const [message, setMessage] = useState("")
    const [validatedForm, setValidatedForm] = useState(false)
    const formRef = useRef<HTMLFormElement>(null)
    const [processing, setProcessing] = useState<boolean>(false)

    const handleCreate = async (e: SyntheticEvent) => {
        e.preventDefault()

        if (!processing) {
            setProcessing(true)
            if (props.providerId) {
                if (formRef.current?.checkValidity()) {
                    const res = await EventTicketService.createByUser(props.providerId, eventTicketCreate)

                    if (res?.isSuccess) {
                        props.onSuccess(res.data.id)
                        props.onClose()
                        setMessage(res.message)
                    } else {
                        setMessage(res?.message)
                    }

                } else {
                    setValidatedForm(true)
                }
            }
            setProcessing(false)
        }
    }

    const handleCustomerChange = (e: ChangeEvent<HTMLInputElement>) => {
        setEventTicketCreate({ ...eventTicketCreate, customer: { ...eventTicketCreate.customer, [e.target.name]: e.target.value } })
    }

    const displayTicketDetails = () => {
        var event = events.find(x => x.id === eventTicketCreate.eventId)
        var date = eventTicketCreate.eventDate ? moment(eventTicketCreate.eventDate).format(Configs.DATE_FORMAT) : ""
        var schedule = schedules.find(x => x.id === eventTicketCreate.eventScheduleId)
        var time = `${schedule?.startTime.substring(0, 5) || ""} - ${schedule?.endTime.substring(0, 5) || ""}`

        var seats = event?.seats || []
        var selectedSeats = seats.filter(x => eventTicketCreate.seatIds.includes(x.id || 0))
        var seatsText = selectedSeats.map(x => `${x.letter}${x.number}`).join(", ")
        var amount = selectedSeats.map(x => x.seatType).map(x => x.price || 0).reduce((a, b) => a + b, 0)

        if (eventTicketCreate.quantity) {
            amount = (event?.ticketPrice || 0) * eventTicketCreate.quantity;
        }

        return (
            <dl className="ticket-details">
                <dt>Event:</dt><dd>{event?.name || ""}</dd>
                <dt>Date:</dt><dd>{date}</dd>
                <dt>Time:</dt><dd>{time}</dd>
                <dt>Quantity:</dt><dd><span className="text-danger">{eventTicketCreate.quantity || selectedSeats.length}</span> {selectedSeats.length > 0 && <>({seatsText})</>}</dd>
                <dt>Amount:</dt><dd><span className="text-danger">{Utils.formatNumber(amount)}</span> {event?.currency}</dd>
            </dl>
        )
    }

    return (
        <Modal show={props.show} onHide={props.onClose} backdrop="static" keyboard={false} fullscreen>
            <Modal.Header closeButton>
                <Modal.Title>Create New Event Ticket</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={validatedForm} ref={formRef as RefObject<HTMLFormElement>} style={{ display: "flex", minHeight: "100%" }}>
                    <div style={{ flex: "1 1 auto", minHeight: "100%", width: "calc(100% - 330px)", paddingRight: "1rem" }}>
                        <Form.Group className="mb-3" controlId="eventId">
                            <Form.Label>Event</Form.Label>
                            <Form.Select name="eventId" required value={eventTicketCreate.eventId || ""} onChange={(e) => setEventTicketCreate({ ...eventTicketCreate, eventId: e.target.value ? parseInt(e.target.value) : null })}>
                                <option value=""></option>
                                {events.map((item: IEvent) => <option key={item.id} value={item.id || 0}>{item.name}</option>)}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">Select a Event.</Form.Control.Feedback>
                            <Form.Text>{timeZoneDisplayName}</Form.Text>
                            {zonedDateTime && <Clock value={zonedDateTime}></Clock>}
                        </Form.Group>
                        {dates.length > 0 &&
                            <Form.Group className="mb-3" controlId="date">
                                <Form.Label style={{ display: "flex", alignItems: "center" }}>
                                    <span style={{ flex: 1 }}>
                                        Date
                                    </span>
                                    <ButtonGroup size="sm">
                                        <Button variant="default" className="text-muted" onClick={handlePrevDates}><i className="fas fa-chevron-left"></i> Prev</Button>
                                        <Button variant="default" className="text-muted" onClick={handleCurrentDates}>[ Today ]</Button>
                                        <Button variant="default" className="text-muted" onClick={handleNextDates}>Next <i className="fas fa-chevron-right"></i></Button>
                                    </ButtonGroup>
                                </Form.Label>
                                <div style={{ overflow: "auto", padding: "0px 1px 5px 1px" }}>
                                    <ToggleButtonGroup type="radio" name="date" value={eventTicketCreate.eventDate}>
                                        {dates.map(date => {
                                            let dayOfMonth = date.getDate()
                                            let dayName = DayNames[date.getDay()] || ""
                                            let monthName = MonthNames[date.getMonth()] || ""

                                            let value = moment(date).format("YYYY-MM-DD")
                                            return <ToggleButton
                                                key={dayOfMonth}
                                                variant="default"
                                                className="date-button btn-flat"
                                                id={`date-${dayOfMonth}`}
                                                value={value}
                                                onChange={() => setEventTicketCreate({ ...eventTicketCreate, eventDate: value })}
                                            >
                                                <span>{monthName}</span>
                                                <span>{dayOfMonth < 10 ? "0" : ""}{dayOfMonth}</span>
                                                <span>{dayName}</span>
                                            </ToggleButton>
                                        })}
                                    </ToggleButtonGroup>
                                </div>
                            </Form.Group>}
                        {schedules.length > 0 &&
                            <Form.Group className="mb-3" controlId="eventScheduleId">
                                <Form.Label style={{ width: "100%" }}>Time</Form.Label>
                                <div style={{ overflow: "auto", padding: "0px 1px 5px 1px" }}>
                                    <ToggleButtonGroup type="radio" name="eventScheduleId" value={eventTicketCreate.eventScheduleId}>
                                        {schedules.map(schedule => {
                                            return <ToggleButton
                                                key={schedule.id}
                                                variant="default"
                                                className="time-button btn-flat"
                                                id={`time-${schedule.id}`}
                                                value={schedule.id || 0}
                                                onChange={() => setEventTicketCreate({ ...eventTicketCreate, eventScheduleId: schedule.id })}
                                            >
                                                {schedule.startTime.substring(0, 5)} - {schedule.endTime.substring(0, 5)}
                                            </ToggleButton>
                                        })}
                                    </ToggleButtonGroup>
                                </div>
                            </Form.Group>}
                        {seatMatrix.length > 0 ?
                            <Form.Group controlId="seats">
                                <Form.Label style={{ display: "flex", alignItems: "center", marginBottom: 0 }}>
                                    <span style={{ flex: 1 }}>
                                        Seats
                                        {(orderedSeats.availableQuantity || 0) > 0 && <span className="text-muted"> ({(orderedSeats.availableQuantity || 0) - eventTicketCreate.seatIds.length} available)</span>}
                                    </span>
                                    <span>Size: </span>
                                    <Dropdown align="end">
                                        <Dropdown.Toggle size="sm" variant="default" className="text-muted">
                                            {currentSeatSize}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {SeatSizes.map((size, index) => <Dropdown.Item key={index} active={size === currentSeatSize} onClick={() => setCurrentSeatSize(size)}>{size}</Dropdown.Item>)}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Form.Label>
                                <div className={`event-seats ${currentSeatSize.toLowerCase()}`}>
                                    <div className="event-seats-inner">
                                        {seatMatrix}
                                    </div>
                                </div>
                            </Form.Group> :
                            <>{eventTicketCreate.eventId &&
                                <Form.Group className="mb-3" controlId="quantity">
                                    <Form.Label>
                                        Quantity
                                        {(orderedSeats.availableQuantity || 0) > 0 && <span className="text-muted"> ({(orderedSeats.availableQuantity || 0) - (eventTicketCreate.quantity || 0)} available)</span>}
                                    </Form.Label>
                                    <Form.Control type="number" max={orderedSeats.availableQuantity || undefined} name="quantity" required value={eventTicketCreate.quantity || ""} onChange={(e) => setEventTicketCreate({ ...eventTicketCreate, quantity: e.target.value ? parseInt(e.target.value) : null })} />
                                    <Form.Control.Feedback type="invalid">Quantity is required.</Form.Control.Feedback>
                                </Form.Group>
                            }</>
                        }
                    </div>
                    <div style={{ flex: "0 0 330px", borderLeft: "dashed 1px #ced4da", minHeight: "100%", paddingLeft: "1rem" }}>
                        <fieldset>
                            <legend>Ticket Details</legend>
                            {displayTicketDetails()}
                        </fieldset>
                        <fieldset>
                            <legend>Payment</legend>
                            <Form.Group className="mb-1" controlId="paymentService">
                                <Form.Label className="mb-0">Type</Form.Label>
                                <Form.Select name="paymentService" required value={eventTicketCreate.paymentService || ""} onChange={(e) => setEventTicketCreate({ ...eventTicketCreate, paymentService: e.target.value })}>
                                    <option value=""></option>
                                    <option value={PaymentServiceType.CASH}>{PaymentServiceType.CASH}</option>
                                    <option value={PaymentServiceType.BANK}>{PaymentServiceType.BANK}</option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">Select a Payment Type.</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-1" controlId="paymentStatus">
                                <Form.Label className="mb-0">Status</Form.Label>
                                <Form.Select name="paymentStatus" required value={eventTicketCreate.paymentStatus || ""} onChange={(e) => setEventTicketCreate({ ...eventTicketCreate, paymentStatus: e.target.value })}>
                                    <option value=""></option>
                                    <option value={PaymentStatusType.COMPLETED}>{PaymentStatusType.COMPLETED}</option>
                                    <option value={PaymentStatusType.PENDING}>{PaymentStatusType.PENDING}</option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">Select a Payment Type.</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="note">
                                <Form.Label className="mb-0">Note</Form.Label>
                                <Form.Control as="textarea" rows={2} type="text" name="note" value={eventTicketCreate.note || ""} onChange={(e) => setEventTicketCreate({ ...eventTicketCreate, note: e.target.value })} />
                            </Form.Group>
                        </fieldset>
                        <fieldset>
                            <legend>Customer</legend>
                            <Form.Group className="mb-1" controlId="name">
                                <Form.Label className="mb-0">Name</Form.Label>
                                <Form.Control type="text" name="name" required value={eventTicketCreate.customer.name || ""} onChange={(e) => handleCustomerChange(e as any)} />
                                <Form.Control.Feedback type="invalid">Name is required.</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-1" controlId="tel">
                                <Form.Label className="mb-0">Tel</Form.Label>
                                <Form.Control type="text" name="tel" required value={eventTicketCreate.customer.tel || ""} onChange={(e) => handleCustomerChange(e as any)} />
                                <Form.Control.Feedback type="invalid">Tel is required.</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-1" controlId="email">
                                <Form.Label className="mb-0">Email</Form.Label>
                                <Form.Control type="email" name="email" value={eventTicketCreate.customer.email || ""} onChange={(e) => handleCustomerChange(e as any)} />
                            </Form.Group>
                            <Form.Group className="mb-1" controlId="address">
                                <Form.Label className="mb-0">Address</Form.Label>
                                <Form.Control type="text" name="address" value={eventTicketCreate.customer.address || ""} onChange={(e) => handleCustomerChange(e as any)} />
                            </Form.Group>
                            <Form.Group className="mb-1" controlId="personalId">
                                <Form.Label className="mb-0">Personal ID</Form.Label>
                                <Form.Control type="text" name="personalId" value={eventTicketCreate.customer.personalId || ""} onChange={(e) => handleCustomerChange(e as any)} />
                                <Form.Text>Priority custormer</Form.Text>
                            </Form.Group>
                        </fieldset>
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                {message !== "" && <Alert variant="danger" onClose={() => setMessage("")} dismissible style={{ flex: "1" }}>{message}</Alert>}
                <Button variant="light" onClick={props.onClose}><i className="fas fa-times"></i> Cancel</Button>
                <Button variant="info" onClick={handleCreate} disabled={processing}>
                    {processing ? <Spinner animation="border" size="sm" /> : <i className="fas fa-save"></i>} Create
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default MyEventTicketCreateModal