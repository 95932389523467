import axiosClient, { ApiResponse, axiosCustomError } from "./axiosClient"
import { ILanguage } from "./LanguageService"
import { ISeat } from "./SeatService"
import { ITimeZoneInfo } from "./TimeZoneService"

export interface IEventTranslation {
    eventId: number | null
    languageId: number
    name: string
    description: string
    venue: string
    address: string
    isActive: boolean
    language: ILanguage
}

export interface IEventSchedule {
    id: number | null
    startTime: string
    endTime: string
    byDays: Array<string>
}

export interface IEvent {
    id: number | null
    name: string
    thumbnail: string
    tel: string
    email: string
    website: string
    orderNumber: number | null
    ticketQuantity: number | null
    ticketPrice: number | null
    timeZoneId: string
    currency: string
    startDate: string | null
    endDate: string | null
    openingTime: number | null
    closingTime: number | null
    published: boolean
    translations: Array<IEventTranslation>
    schedules: Array<IEventSchedule>
    seats: Array<ISeat>
    timeZoneInfo: ITimeZoneInfo | null
}

export interface IConfirmationEmailTemplate {
    confirmationEmailSubject: string
    confirmationEmailBody: string
}

export default class EventService {
    static async filter(providerId: number, pageIndex: number, pageSize: number, keyword: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Events/${providerId}?keyword=${keyword}&pageSize=${pageSize}&pageIndex=${pageIndex}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async get(providerId: number, id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Events/${providerId}/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async listAllPublished(providerId: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Events/${providerId}/ListAllPublished`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async getEventDates(providerId: number, id: number, pageId?: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Events/${providerId}/${id}/GetEventDates?pageId=${pageId || 1}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async getEventSchedules(providerId: number, id: number, date: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Events/${providerId}/${id}/${date}/GetEventSchedules`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async delete(providerId: number, id: number) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/Events/${providerId}/${id}`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async update(providerId: number, id: number, formData: FormData) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/Events/${providerId}/${id}`, formData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async create(providerId: number, formData: FormData) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/Events/${providerId}/Create`, formData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async updateConfirmationEmailTemplate(providerId: number, id: number, itemData: IConfirmationEmailTemplate) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/Events/${providerId}/UpdateConfirmationEmailTemplate/${id}`, itemData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async changePublished(providerId: number, id: number) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/Events/${providerId}/ChangePublished/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async search(providerId: number, keyword: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Events/${providerId}/Search?keyword=${keyword}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }
}