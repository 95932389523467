import { Alert, Button, Dropdown, Form, Modal, Spinner, Tab, Table, Tabs } from "react-bootstrap"
import useGlobalContext from "../../hooks/useGlobalContext"
import LocalizationService, { ILocalization, ILocalizationTranslation, ILocalizationTranslationUpdate } from "../../services/LocalizationService"
import { ChangeEvent, SyntheticEvent, useEffect, useState } from "react"
import AddOrRemoveLanguages, { LanguageActionTypes } from "../../components/AddOrRemoveLanguages"
import { ILanguage } from "../../services/LanguageService"
import Configs from "../../Configs"
import ApiKeyService, { IApiKey } from "../../services/ApiKeyService"

interface ILocalizationsModalProps {
    providerId: number
    apiKeyId: string
    title: string
    show: boolean
    onClose: () => void
}

function LocalizationsModal(props: ILocalizationsModalProps) {
    const { languages } = useGlobalContext()
    const [localizations, setLocalizations] = useState<Array<ILocalization>>([])
    const [apiKeys, setApiKeys] = useState<Array<IApiKey>>([])
    const [message, setMessage] = useState("")
    const [activeTabKey, setActiveTabKey] = useState(languages.find(x => x.code === Configs.DEFAULT_LANGUAGE_CODE)?.id?.toString())

    const loadData = async () => {
        if (props.providerId && props.apiKeyId) {
            const res = await LocalizationService.listByApiKeyId(props.providerId, props.apiKeyId)

            if (res?.isSuccess) {
                setLocalizations(res.data)
            } else {
                console.log(res?.message)
            }
        } else {
            setLocalizations([])
        }
    }

    const loadApiKeys = async () => {
        const res = await ApiKeyService.listAll()

        if (res?.isSuccess) {
            setApiKeys(res.data)
        } else {
            console.log(res?.message)
        }
    }

    useEffect(() => {
        if (props.show) {
            loadData()
            loadApiKeys()
            setMessage("")
        }
    }, [props.providerId, props.apiKeyId, props.show])

    const getSelectedLanguages = () => {
        const arrays = localizations.map(x => x.translations.map(l => l.language))

        var merged: Array<ILanguage> = []
        arrays.forEach(array => {
            merged = merged.concat(array)
        })

        var distincts: Array<ILanguage> = []
        merged.forEach(item => {
            if (!distincts.find(x => x.code === item.code)) {
                distincts.push(item)
            }
        })

        return distincts
    }

    const selectedLanguages = getSelectedLanguages()

    const handleTranslateFormControlChange = (e: ChangeEvent<HTMLInputElement>, localizationId: number | null, languageId: number | null) => {
        if (localizationId && languageId) {
            if (!localizations.find(x => x.id === localizationId)?.translations.find(t => t.languageId === languageId)) {
                localizations.find(x => x.id === localizationId)?.translations.push({
                    id: null,
                    localizationId: localizationId,
                    languageId: languageId,
                    value: e.target.value,
                    language: languages.find(x => x.id === languageId) || {} as ILanguage
                })
            }

            setLocalizations(localizations.map(x => x.id !== localizationId ? x : {
                ...x, translations: x.translations.map(lang => lang.languageId !== languageId ? lang : { ...lang, value: e.target.value })
            }))
        }
    }

    const handleLanguagesChange = async (languageId: number, action: LanguageActionTypes) => {
        if (action === "Add") {
            const newLang = languages.find(x => x.id === languageId)
            if (newLang) {
                if (selectedLanguages.filter(x => x.id === newLang.id).length === 0) {
                    const newLocalizations = [...localizations]

                    newLocalizations.forEach(local => {
                        local.translations.push({
                            id: null,
                            localizationId: local.id,
                            languageId: newLang.id || 0,
                            value: "",
                            language: newLang
                        })
                    })

                    setLocalizations(newLocalizations)
                    setActiveTabKey(newLang.id?.toString())
                }
            }
        } else {
            const newLocalizations = [...localizations]

            newLocalizations.forEach(local => {
                local.translations = local.translations.filter(x => x.languageId !== languageId)
            })

            setLocalizations(newLocalizations)
            setActiveTabKey(selectedLanguages[0]?.id?.toString())
        }
    }

    const [processing, setProcessing] = useState<boolean>(false)
    const handleSave = async (e: SyntheticEvent) => {
        e.preventDefault()

        if (!processing) {
            setProcessing(true)
            if (props.providerId && props.apiKeyId) {
                try {
                    const translations: Array<ILocalizationTranslationUpdate> = []
                    localizations.forEach(local => {
                        local.translations.forEach(x => {
                            translations.push({
                                localizationId: x.localizationId || 0, languageId: x.languageId, value: x.value
                            })
                        })
                    })

                    const res = await LocalizationService.updateByApiKeyId(props.providerId, props.apiKeyId, translations)

                    if (res?.isSuccess) {
                        setMessage(res.message)
                    } else {
                        setMessage(res?.message)
                    }
                } catch (error) {
                    console.log(error)
                }
            }
            setProcessing(false)
        }
    }

    const handleCopy = async (apiKeyId: string, languageId: number | null) => {
        if (apiKeyId && languageId) {
            const res = await LocalizationService.listTranslationByApiKeyId(apiKeyId, languageId)

            if (res?.isSuccess) {
                var data = res.data as ILocalizationTranslation[]

                data.forEach(item => {
                    if (!localizations.find(local => local.id === item.localizationId && local.translations.find(trans => trans.languageId === item.languageId))) {
                        localizations.find(local => local.id === item.localizationId)?.translations.push(item)
                    }
                })

                setLocalizations(localizations.map(local => {
                    return {
                        ...local, translations: local.translations.map(trans => {
                            return trans.languageId === languageId ? { ...trans, value: data.find(x => x.localizationId === local.id && x.languageId === languageId)?.value || "" } : trans
                        })
                    }
                }))
            } else {
                console.log(res?.message)
            }
        }
    }

    return (
        <Modal show={props.show} onHide={props.onClose} backdrop="static" keyboard={false} size="lg">
            <Modal.Header closeButton className="p-2">
                <Modal.Title><i className="fas fa-language"></i> Localizations <span>|</span> {props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <AddOrRemoveLanguages selectedLanguages={selectedLanguages} onChange={handleLanguagesChange}></AddOrRemoveLanguages>
                <Form>
                    <Tabs activeKey={activeTabKey} onSelect={(k) => setActiveTabKey(k || undefined)}>
                        {
                            selectedLanguages.sort((a, b) => { return (a.orderNumber || 0) - (b.orderNumber || 0) }).map(lang => {
                                return (
                                    <Tab
                                        key={lang.id}
                                        eventKey={(lang.id || 0).toString()}
                                        title={lang.name}
                                        className="p-3"
                                    >
                                        <Dropdown className="float-right" align="end">
                                            <Dropdown.Toggle size="sm" variant="default">
                                                <i className="fas fa-copy text-info"></i> Copy from
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                {
                                                    apiKeys.map(x => {
                                                        return (
                                                            <Form.Check
                                                                key={x.id}
                                                                type="radio"
                                                                label={x.name}
                                                                name={`apiKeys-${lang.id}`}
                                                                id={`apiKeys-${lang.id}-${x.id}`}
                                                                value={x.id}
                                                                className="mx-2 text-nowrap"
                                                                onChange={() => handleCopy(x.id, lang.id)}
                                                            />
                                                        )
                                                    })
                                                }
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <Table striped bordered hover className="mb-0">
                                            <thead>
                                                <tr>
                                                    <th style={{ width: "50%" }}>Name</th>
                                                    <th>Value</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {localizations.map(local => {
                                                    const translate = local.translations.find(x => x.languageId === lang.id)

                                                    return (
                                                        <tr key={local.id}>
                                                            <td>
                                                                <h6>{local.name}</h6>
                                                                <p className="text-muted">{local.description}</p>
                                                            </td>
                                                            <td>
                                                                <Form.Group controlId={"value_" + local.id + "_" + lang.id}>
                                                                    {local.inputType === "text" ? <>
                                                                        <Form.Control as="textarea" rows={2} type={local.inputType} name="value" value={translate?.value || ""} onChange={(e) => handleTranslateFormControlChange(e as any, local.id, lang.id)} />
                                                                    </> : <>
                                                                        <Form.Control type={local.inputType} name="value" value={translate?.value || ""} onChange={(e) => handleTranslateFormControlChange(e as any, local.id, lang.id)} />
                                                                    </>}
                                                                </Form.Group>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                    </Tab>
                                )
                            })
                        }
                    </Tabs>
                </Form>
                {message !== "" && <Alert variant="info" onClose={() => setMessage("")} dismissible>{message}</Alert>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={props.onClose}><i className="fas fa-times"></i> Cancel</Button>
                <Button variant="info" onClick={handleSave} disabled={processing}>
                    {processing ? <><Spinner animation="border" size="sm" /> Saving...</> : <><i className="fas fa-save"></i> Save</>}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default LocalizationsModal