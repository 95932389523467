import { ChangeEvent, KeyboardEvent, RefObject, SyntheticEvent, useEffect, useRef, useState } from "react"
import { Button, Modal, Form, Table, InputGroup, Card, Container, Row, Col, Alert } from "react-bootstrap"
import ContentBody from "../../components/ContentBody"
import ContentHeader from "../../components/ContentHeader"
import DeleteConfirmation from "../../components/DeleteConfirmation"
import Pagination from "../../components/Pagination"
import Configs from "../../Configs"
import CredentialService, { ICredential, ICredentialUpdate } from "../../services/CredentialService"
import PlaceService, { IPlace } from "../../services/PlaceService"
import { SelectOptionType } from "../../constants/SelectOptionType"
import { SelectInstance } from "react-select"
import AsyncSelect from "react-select/async"
import ClipboardCopy from "../../components/ClipboardCopy"
import useAuthContext from "../../hooks/useAuthContext"
import Permissions from "../../constants/Permissions"

function Credentials() {
    const { hasPermission } = useAuthContext()

    const [tableData, setTableData] = useState({
        data: new Array<ICredential>(),
        hasNext: false,
        hasPrevious: false,
        pageIndex: 1,
        pageSize: Configs.DEFAULT_PAGE_SIZE,
        totalPages: 0,
        totalRecords: 0
    })

    const initFormData: ICredential = {
        id: "",
        name: "",
        accessKey: "",
        isActive: false,
        places: []
    }

    const [message, setMessage] = useState("")
    const [messageFormModal, setMessageFormModal] = useState("")
    const [validatedFormModal, setValidatedFormModal] = useState(false)
    const [formData, setFormData] = useState(initFormData)
    const [currentPageIndex, setCurrentPageIndex] = useState(1)
    const [selectedItem, setSelectedItem] = useState({
        id: "",
        name: ""
    })

    const keywordRef = useRef<HTMLInputElement>(null)
    const formRef = useRef<HTMLFormElement>(null)

    const FilterFn = async (pageIndex: number) => {
        let pageSize = Configs.DEFAULT_PAGE_SIZE
        let keyword = keywordRef.current?.value || ""

        const res = await CredentialService.filter(pageIndex, pageSize, keyword)

        if (res?.isSuccess) {
            setTableData(res.data)
        } else {
            console.log(res?.message)
        }

        setCurrentPageIndex(pageIndex)
    }

    useEffect(() => {
        keywordRef.current?.focus()

        FilterFn(1)
    }, [])

    const handleSearch = () => {
        FilterFn(1)
    }

    const handleSearchInputKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            FilterFn(1)
        }
    }

    const handlePageChange = (pageNumber: number) => {
        FilterFn(pageNumber)
    }

    const handleFormControlChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleAddNew = () => {
        setFormData(initFormData)

        handleShowModal()
    }

    const handleEdit = async (id: string) => {
        if (id) {
            const res = await CredentialService.get(id)

            if (res?.isSuccess) {
                setFormData(res.data)

                handleShowModal()
            } else {
                console.log(res?.message)
            }
        }
    }

    const handleSave = async (e: SyntheticEvent) => {
        e.preventDefault()

        if (formRef.current?.checkValidity()) {
            const credentialUpdate: ICredentialUpdate = {
                name: formData.name,
                places: formData.places.map(x => { return { placeId: x.id || 0 } })
            }

            if (formData.id) {
                const res = await CredentialService.update(formData.id, credentialUpdate)

                if (res?.isSuccess) {
                    FilterFn(currentPageIndex)
                    handleCloseModal()
                    setMessage(res.message)
                } else {
                    setMessageFormModal(res?.message)
                }
            } else {
                const res = await CredentialService.create(credentialUpdate)

                if (res?.isSuccess) {
                    FilterFn(1)
                    handleCloseModal()
                    setMessage(res.message)
                } else {
                    setMessageFormModal(res?.message)
                }
            }
        } else {
            setValidatedFormModal(true)
        }
    }

    const [showModal, setShowModal] = useState(false)
    const handleCloseModal = () => {
        setShowModal(false)
        setValidatedFormModal(false)
        setMessageFormModal("")
    }
    const handleShowModal = () => {
        setShowModal(true)
        setMessage("")
    }

    const modalTitle = formData.id ? "Edit Credential" : "New Credential"

    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false)

    const hideConfirmationModal = () => {
        setSelectedItem({
            id: "",
            name: ""
        })

        setDisplayConfirmationModal(false)
    }

    const showDeleteConfirmation = (id: string, name: string) => {
        setSelectedItem({
            id: id,
            name: name
        })

        setDisplayConfirmationModal(true)
        setMessage("")
    }

    const handleDelete = async () => {
        if (selectedItem.id) {
            const res = await CredentialService.delete(selectedItem.id)

            if (res?.isSuccess) {
                FilterFn(currentPageIndex)
            }

            hideConfirmationModal()
            setMessage(res.message)
        }
    }

    const handleActiveSwitch = async (id: string) => {
        const res = await CredentialService.changeIsActive(id)

        if (res?.isSuccess) {
            FilterFn(currentPageIndex)
        } else {
            console.log(res?.message)
        }
    }

    //Places Async Select
    const [selectedPlaceOption, setSelectedPlaceOption] = useState(null as SelectOptionType | null)
    const placeAsyncRef = useRef<SelectInstance<SelectOptionType> | null>(null)

    const handlePlaceSelectChange = async (option: SelectOptionType | null) => {
        setSelectedPlaceOption(option)

        if (option?.value) {
            let value = option?.value as number

            if (formData.places.filter(x => x.id === value).length === 0) {
                var res = await PlaceService.getByIdForCredentials(value)

                if (res.isSuccess) {
                    const { places } = formData
                    places.push(res.data)

                    setFormData({ ...formData, places: places })

                    setSelectedPlaceOption(null)
                    placeAsyncRef.current?.focus()
                }
            }
        }
    }

    const filterPlaces = async (inputValue: string) => {
        var options: SelectOptionType[] = []

        var res = await PlaceService.searchForCredentials(inputValue)

        if (res.isSuccess && res.data) {
            options = res.data.map((item: IPlace) => {
                return { value: item.id, label: item.name }
            })
        }

        return options
    }

    const loadPlaceOptions = (inputValue: string) => new Promise<SelectOptionType[]>((resolve) => {
        resolve(filterPlaces(inputValue))
    })

    const handleRemovePlace = (placeId: number | null) => {
        setFormData({ ...formData, places: formData.places.filter(x => x.id !== placeId) })
    }

    return (
        <>
            <ContentHeader title="Credentials" />

            <ContentBody>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <Card.Header>
                                    {hasPermission(Permissions.Credentials.Create) &&
                                        <Button variant="info" size="sm" onClick={handleAddNew}>
                                            <i className="fas fa-plus"></i> Add new
                                        </Button>
                                    }
                                    <div className="card-tools">
                                        <InputGroup size="sm">
                                            <Form.Control
                                                type="search"
                                                placeholder="Search"
                                                ref={keywordRef as RefObject<HTMLInputElement>}
                                                onKeyUp={handleSearchInputKeyPress}
                                            />
                                            <Button variant="info" size="sm" onClick={handleSearch}>
                                                <i className="fas fa-search"></i>
                                            </Button>
                                        </InputGroup>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    {message !== "" && <Alert variant="info" onClose={() => setMessage("")} dismissible>{message}</Alert>}
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th style={{ width: "300px" }}>App Name</th>
                                                <th>Access Key</th>
                                                <th style={{ textAlign: "center", width: "100px" }}>Active</th>
                                                <th style={{ textAlign: "center", width: "80px" }}>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                tableData.data.map(item =>
                                                    <tr key={item.id}>
                                                        <td>
                                                            {item.name}
                                                        </td>
                                                        <td>
                                                            <ClipboardCopy text={item.accessKey}></ClipboardCopy>
                                                        </td>
                                                        {/* <td className="text-truncate rtl">{item.accessKey}</td> */}
                                                        <td style={{ textAlign: "center" }}>
                                                            <Form.Check type="switch" checked={item.isActive} onChange={() => handleActiveSwitch(item.id)} disabled={!hasPermission(Permissions.Credentials.Edit)} />
                                                        </td>
                                                        <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                                                            {hasPermission(Permissions.Credentials.Edit) &&
                                                                <Button variant="warning" className="mr-1" size="sm" onClick={() => handleEdit(item.id)}><i className="fas fa-pencil-alt"></i> Edit</Button>
                                                            }
                                                            {hasPermission(Permissions.Credentials.Delete) &&
                                                                <Button variant="danger" size="sm" onClick={() => showDeleteConfirmation(item.id, item.name)}><i className="fas fa-trash-alt"></i> Delete</Button>
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </Card.Body>
                                <Card.Footer>
                                    <Pagination hasNext={tableData.hasNext} hasPrevious={tableData.hasPrevious} pageIndex={tableData.pageIndex} pageSize={tableData.pageSize} totalPages={tableData.totalPages} totalRecords={tableData.totalRecords} handlePageChange={handlePageChange} />
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </ContentBody>

            <Modal show={showModal} onHide={handleCloseModal} backdrop="static" keyboard={false} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validatedFormModal} ref={formRef as RefObject<HTMLFormElement>}>
                        {formData.id &&
                            <Form.Group className="mb-3" controlId="accessKey">
                                <Form.Label>Access Key</Form.Label>
                                <Form.Control as="textarea" rows={5} readOnly name="accessKey" value={formData.accessKey} />
                            </Form.Group>
                        }
                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>App Name</Form.Label>
                            <Form.Control type="text" name="name" required value={formData.name} onChange={(e) => handleFormControlChange(e as any)} />
                            <Form.Control.Feedback type="invalid">App Name is required.</Form.Control.Feedback>
                        </Form.Group>
                        <fieldset className="mb-3">
                            <legend>Places</legend>
                            <AsyncSelect className="mb-3" isClearable value={selectedPlaceOption} loadOptions={loadPlaceOptions} onChange={handlePlaceSelectChange} ref={placeAsyncRef} placeholder="Search" noOptionsMessage={({ inputValue }) => !inputValue ? "Enter your place to search." : "Can't find your place!"} />
                            <Table hover bordered size="sm" className="mb-0">
                                <thead>
                                    <tr>
                                        <th>Place Name</th>
                                        <th style={{ width: "40px" }}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {formData.places.map((item: IPlace) =>
                                        <tr key={item.id}>
                                            <td>
                                                #{item.id}. {item.name || ""}
                                            </td>
                                            <td>
                                                <Button variant="light" size="sm" onClick={() => handleRemovePlace(item.id)}><i className="fas fa-times"></i></Button>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </fieldset>
                    </Form>
                    {messageFormModal !== "" && <Alert variant="danger" onClose={() => setMessageFormModal("")} dismissible>{messageFormModal}</Alert>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleCloseModal}><i className="fas fa-times"></i> Cancel</Button>
                    <Button variant="info" onClick={handleSave}><i className="fas fa-save"></i> Save</Button>
                </Modal.Footer>
            </Modal>

            <DeleteConfirmation show={displayConfirmationModal} onConfirm={handleDelete} onHide={hideConfirmationModal} id={selectedItem.id} name={selectedItem.name} />
        </>
    )
}

export default Credentials