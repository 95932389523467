import { ChangeEvent, KeyboardEvent, RefObject, SyntheticEvent, useEffect, useRef, useState, useCallback } from "react"
import useAppContext from "../../hooks/useAppContext"
import VoucherService, { IVoucher, IVoucherUpdate, VoucherSortFields } from "../../services/VoucherService"
import Configs from "../../Configs"
import ContentHeader from "../../components/ContentHeader"
import DeleteConfirmation from "../../components/DeleteConfirmation"
import ContentBody from "../../components/ContentBody"
import { Alert, Button, Card, Col, Container, Form, InputGroup, Modal, Row, Table } from "react-bootstrap"
import Utils from "../../utils/Utils"
import Pagination from "../../components/Pagination"
import PlaceSelectForAgency from "../../components/PlaceSelectForAgency"
import HtmlToPrint from "../../components/HtmlToPrint"
import { useReactToPrint } from "react-to-print"
import TicketTemplateService, { ITicketTemplate } from "../../services/TicketTemplateService"
import useAuthContext from "../../hooks/useAuthContext"
import Permissions from "../../constants/Permissions"
import SortBy from "../../components/SortBy"

function AgencyVouchers() {
    const { hasPermission } = useAuthContext()
    const { state } = useAppContext()

    const [tableData, setTableData] = useState({
        data: new Array<IVoucher>(),
        hasNext: false,
        hasPrevious: false,
        pageIndex: 1,
        pageSize: Configs.DEFAULT_PAGE_SIZE,
        totalPages: 0,
        totalRecords: 0
    })

    const initFormData: IVoucherUpdate = {
        id: null,
        code: "",
        placeId: null,
        voucherTypeId: null,
        paymentService: "",
        note: "",
        quantity: 1,
        isSameCode: false,
        isTest: null
    }

    const [message, setMessage] = useState("")
    const [messageFormModal, setMessageFormModal] = useState("")
    const [validatedFormModal, setValidatedFormModal] = useState(false)
    const [formData, setFormData] = useState(initFormData)
    const [currentPageIndex, setCurrentPageIndex] = useState(1)
    const [sortBy, setSortBy] = useState("createdOn")
    const [sortDirection, setSortDirection] = useState<"ASC" | "DESC">("DESC")
    const [deletedItem, setDeletedItem] = useState({
        id: 0,
        name: ""
    })

    const keywordRef = useRef<HTMLInputElement>(null)
    const formRef = useRef<HTMLFormElement>(null)

    const FilterFn = async (pageIndex: number) => {
        if (state.currentAgencyId) {
            let pageSize = Configs.DEFAULT_PAGE_SIZE
            let keyword = keywordRef.current?.value || ""

            const res = await VoucherService.filterByAgency(state.currentAgencyId, pageIndex, pageSize, keyword, sortBy, sortDirection, Configs.Voucher.getHideTestVouchers())

            if (res?.isSuccess) {
                setTableData(res.data)
            } else {
                console.log(res?.message)
            }

            setCurrentPageIndex(pageIndex)
        }
    }

    useEffect(() => {
        keywordRef.current?.focus()

        FilterFn(1)
    }, [state.currentAgencyId, sortBy, sortDirection])

    const handleSearch = () => {
        FilterFn(1)
    }

    const handleSearchInputKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            FilterFn(1)
        }
    }

    const handlePageChange = (pageNumber: number) => {
        FilterFn(pageNumber)
    }

    const handleFormControlChange = (e: ChangeEvent<HTMLInputElement>) => {
        var name = e.target.name
        var value = e.target.value
        
        if (name === "placeId") {
            let values = {
                placeId: value ? parseInt(value) : null,
                voucherTypeId: null
            }
            setFormData({ ...formData, ...values })
        } else if (name === "quantity" || name === "voucherTypeId") {
            setFormData({ ...formData, [name]: value ? parseInt(value) : null })
        } else if (name === "isSameCode") {
            setFormData({ ...formData, [name]: e.target.checked })
        } else {
            setFormData({ ...formData, [name]: value })
        }
    }

    const handleAddNew = () => {
        setFormData(initFormData)

        handleShowModal()
    }

    const handleEdit = async (id: number | null) => {
        if (state.currentAgencyId && id) {
            const res = await VoucherService.getByAgency(state.currentAgencyId, id)

            if (res?.isSuccess) {
                setFormData(res.data)

                handleShowModal()
            } else {
                console.log(res?.message)
            }
        }
    }

    const handleSave = async (e: SyntheticEvent) => {
        e.preventDefault()

        if (state.currentAgencyId) {
            if (formRef.current?.checkValidity()) {
                if (formData.id) {
                    const res = await VoucherService.updateByAgency(state.currentAgencyId, formData.id, formData)

                    if (res?.isSuccess) {
                        FilterFn(currentPageIndex)
                        handleCloseModal()
                        setMessage(res.message)
                    } else {
                        setMessageFormModal(res?.message)
                    }
                } else {
                    const res = await VoucherService.createByAgency(state.currentAgencyId, formData)

                    if (res?.isSuccess) {
                        FilterFn(1)
                        handleCloseModal()
                        setMessage(res.message)

                        //print
                        var ids = res.data.map((x: IVoucher) => x.id)
                        if (ids.length) {
                            if (Configs.PrintMethod.get() === "HTML") {
                                setVoucherIds(ids)
                            } else {
                                VoucherService.generatePDFsByAgency(state.currentAgencyId, ids)
                            }
                        }
                    } else {
                        setMessageFormModal(res?.message)
                    }
                }
            } else {
                setValidatedFormModal(true)
            }
        }
    }

    //ShowModal
    const [showModal, setShowModal] = useState(false)
    const handleCloseModal = () => {
        setShowModal(false)
        setValidatedFormModal(false)
        setMessageFormModal("")
    }
    const handleShowModal = () => {
        setShowModal(true)
        setMessage("")
    }

    const modalTitle = formData.id ? "Edit Voucher" : "Create New Voucher"

    //Delete
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false)

    const hideConfirmationModal = () => {
        setDeletedItem({
            id: 0,
            name: ""
        })

        setDisplayConfirmationModal(false)
    }

    const showDeleteConfirmation = (id: number | null, name: string) => {
        setDeletedItem({
            id: id || 0,
            name: name
        })

        setDisplayConfirmationModal(true)
        setMessage("")
    }

    const handleDelete = async () => {
        if (state.currentAgencyId && deletedItem.id) {
            const res = await VoucherService.deleteByAgency(state.currentAgencyId, deletedItem.id)

            if (res?.isSuccess) {
                FilterFn(currentPageIndex)
            }

            hideConfirmationModal()
            setMessage(res.message)
        }
    }

    //Print
    const [isPrinting, setIsPrinting] = useState(false)
    const printRef = useRef(null)
    const onBeforeGetContentResolve = useRef<(() => void) | null>(null)
    const [voucherIds, setVoucherIds] = useState<number[]>([])
    const [voucherContentToPrint, setVoucherContentToPrint] = useState<ITicketTemplate | null>(null)

    const loadVoucherContent = async () => {
        if (state.currentAgencyId && voucherIds.length) {
            const setting = await TicketTemplateService.getAllAppVoucherHTMLTemplateForAgency(state.currentAgencyId)

            if (setting?.isSuccess && setting.data) {
                const ticketTemplates: ITicketTemplate[] = setting.data

                const res = await VoucherService.getForPrintByAgency(state.currentAgencyId, voucherIds)

                if (res?.isSuccess) {
                    var vouchers: IVoucher[] = res.data
                    var ticketTemplate = ticketTemplates.find(x => x.providerId == vouchers[0].providerId)

                    if (ticketTemplate) {
                        var html = ""
                        vouchers.forEach((voucher: IVoucher, index: number) => {
                            var contentTemplate = ticketTemplate?.htmlContent || ""

                            html += contentTemplate
                                .replaceAll("{code}", voucher.code || "")
                                .replaceAll("{qrcode}", voucher.qrcodeImage)
                                .replaceAll("{price}", Utils.formatNumber(voucher.price))
                                .replaceAll("{currency}", voucher.currency)
                                .replaceAll("{timeLimit}", voucher.timeLimit?.toString() || "")
                                .replaceAll("{placeName}", voucher.placeName || "")

                            if (index > 0) {
                                html += '<div class="page-break" />'
                            }
                        })

                        setVoucherContentToPrint({ ...ticketTemplate, htmlContent: html })
                    }
                } else {
                    setVoucherContentToPrint(null)
                }
            } else {
                setVoucherContentToPrint(null)
            }
        } else {
            setVoucherContentToPrint(null)
        }
    }

    const handleOnBeforeGetContent = useCallback(() => {
        setIsPrinting(true)

        return new Promise<void>((resolve) => {
            onBeforeGetContentResolve.current = resolve
            setIsPrinting(false)
            setVoucherIds([])
            resolve()
        })
    }, [isPrinting, voucherContentToPrint])

    const handlePrintHTML = useReactToPrint({
        content: () => printRef?.current,
        onBeforeGetContent: handleOnBeforeGetContent,
        onAfterPrint: () => {
            onBeforeGetContentResolve.current = null
            setVoucherContentToPrint(null)
        },
        removeAfterPrint: true
    })

    useEffect(() => {
        if (voucherContentToPrint && typeof onBeforeGetContentResolve.current === "function") {
            onBeforeGetContentResolve.current()
        }
    }, [onBeforeGetContentResolve.current, voucherContentToPrint])

    useEffect(() => {
        loadVoucherContent()
    }, [voucherIds])

    useEffect(() => {
        if (voucherContentToPrint) {
            handlePrintHTML()
        }
    }, [voucherContentToPrint])

    const handlePrint = (id: number | null) => {
        if (state.currentAgencyId && id) {
            if (Configs.PrintMethod.get() === "HTML") {
                setVoucherIds([id])
            } else {
                VoucherService.generatePDFByAgency(state.currentAgencyId, id)
            }
        }
    }

    return (
        <>
            <ContentHeader title="Vouchers" />

            <ContentBody>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <Card.Header>
                                    {hasPermission(Permissions.AgencyVouchers.Create) &&
                                        <Button variant="info" size="sm" onClick={handleAddNew}>
                                            <i className="fas fa-plus"></i> Create new voucher
                                        </Button>
                                    }

                                    <div className="card-tools" style={{ display: "flex" }}>
                                        <SortBy fields={VoucherSortFields} sortBy={sortBy} sortDirection={sortDirection} onFieldChange={name => setSortBy(name)} onDirectionChange={name => setSortDirection(name)}></SortBy>

                                        <InputGroup size="sm">
                                            <Form.Control
                                                type="search"
                                                placeholder="Search"
                                                ref={keywordRef as RefObject<HTMLInputElement>}
                                                onKeyUp={handleSearchInputKeyPress}
                                            />
                                            <Button variant="info" size="sm" onClick={handleSearch}>
                                                <i className="fas fa-search"></i>
                                            </Button>
                                        </InputGroup>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    {message !== "" && <Alert variant="info" onClose={() => setMessage("")} dismissible>{message}</Alert>}
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Created On</th>
                                                <th>Code</th>
                                                <th>Place</th>
                                                <th>Price</th>
                                                <th>Note</th>
                                                <th style={{ textAlign: "center", width: "80px" }}>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                tableData.data.map(item =>
                                                    <tr key={item.id}>
                                                        <td>
                                                            {Utils.dateToString(item.createdOn, Configs.DATE_TIME_FORMAT)}
                                                            <p className="text-muted" style={{ fontSize: "11px" }}>
                                                                {item.paymentService}
                                                                {item.isTest && <> (Test)</>}
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <h6>{item.code}</h6>
                                                            <div className="text-muted" style={{ fontSize: "11px" }}>
                                                                {item.secretKey}
                                                            </div>
                                                            <div className="text-muted" style={{ fontSize: "11px" }}>
                                                                Actived on: <span className="text-blue">{Utils.dateToString(item.activedOn, Configs.DATE_TIME_FORMAT)}</span>
                                                            </div>
                                                            <div className="text-muted" style={{ fontSize: "11px" }}>
                                                                Expired on: {Utils.dateToString(item.expiredOn, Configs.DATE_TIME_FORMAT)}
                                                            </div>
                                                        </td>
                                                        <td>{item.place?.name || ""}</td>
                                                        <td>{item.price && <span className="text-danger">{Utils.formatNumber(item.price)} {item.currency}</span>}/{(item.timeLimit || 0) > 0 ? <span className="text-muted">{item.timeLimit} hours</span> : <span className="text-muted">Unlimited</span>}</td>
                                                        <td className="text-truncate rtl">{item.note}</td>
                                                        <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                                                            {hasPermission(Permissions.AgencyVouchers.Print) &&
                                                                <Button variant="secondary" className="mr-1" size="sm" onClick={() => handlePrint(item.id)} disabled={item.activedOn != null}><i className="fas fa-print"></i> Print</Button>
                                                            }
                                                            {hasPermission(Permissions.AgencyVouchers.Edit) &&
                                                                <Button variant="warning" className="mr-1" size="sm" onClick={() => handleEdit(item.id)}><i className="fas fa-pencil-alt"></i> Edit</Button>
                                                            }
                                                            {hasPermission(Permissions.AgencyVouchers.Delete) &&
                                                                <Button variant="danger" size="sm" onClick={() => showDeleteConfirmation(item.id, item.code)} disabled={item.activedOn != null}><i className="fas fa-trash-alt"></i> Delete</Button>
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </Card.Body>
                                <Card.Footer>
                                    <Pagination hasNext={tableData.hasNext} hasPrevious={tableData.hasPrevious} pageIndex={tableData.pageIndex} pageSize={tableData.pageSize} totalPages={tableData.totalPages} totalRecords={tableData.totalRecords} handlePageChange={handlePageChange} />
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </ContentBody>

            <Modal show={showModal} onHide={handleCloseModal} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validatedFormModal} ref={formRef as RefObject<HTMLFormElement>}>
                        {formData.id &&
                            <Form.Group className="mb-3" controlId="code">
                                <Form.Label>Code</Form.Label>
                                <Form.Control type="text" readOnly name="code" value={formData.code} />
                            </Form.Group>
                        }
                        {formData.id === null &&
                            <>
                                <PlaceSelectForAgency required agencyId={state.currentAgencyId} value={formData.placeId} onChange={handleFormControlChange}></PlaceSelectForAgency>
                                <Form.Group className="mb-3" controlId="quantity">
                                    <Form.Label>Quantity</Form.Label>
                                    <Form.Control type="number" name="quantity" value={formData.quantity || ""} onChange={(e) => handleFormControlChange(e as any)} />
                                </Form.Group>
                                {/* <Form.Check type="checkbox" className="mb-3 text-muted" label="Generate the same keycode and concatenate consecutive numbers (e.g. 12345678-1, 12345678-2, ...)" name="isSameCode" onChange={(e) => handleFormControlChange(e as any)} /> */}
                            </>
                        }
                        <Form.Group className="mb-3" controlId="note">
                            <Form.Label>Note</Form.Label>
                            <Form.Control type="text" name="note" value={formData.note} onChange={(e) => handleFormControlChange(e as any)} />
                        </Form.Group>
                    </Form>
                    {messageFormModal !== "" && <Alert variant="danger" onClose={() => setMessageFormModal("")} dismissible>{messageFormModal}</Alert>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleCloseModal}><i className="fas fa-times"></i> Cancel</Button>
                    <Button variant="info" onClick={handleSave}><i className="fas fa-save"></i> Save</Button>
                </Modal.Footer>
            </Modal>

            <DeleteConfirmation show={displayConfirmationModal} onConfirm={handleDelete} onHide={hideConfirmationModal} id={deletedItem.id} name={deletedItem.name} />

            <HtmlToPrint
                htmlContent={voucherContentToPrint?.htmlContent || ""}
                styleSheet={voucherContentToPrint?.styleSheet || ""}
                paperSizeWidth={voucherContentToPrint?.paperSizeWidth || ""}
                paperSizeHeight={voucherContentToPrint?.paperSizeHeight || ""}
                ref={printRef}
            ></HtmlToPrint>
        </>
    )
}

export default AgencyVouchers