import { Alert, Button, Card, Form, InputGroup, Modal, Spinner } from "react-bootstrap"
import useAuthContext from "../../hooks/useAuthContext"
import Permissions from "../../constants/Permissions"
import { ChangeEvent, RefObject, SyntheticEvent, useEffect, useRef, useState } from "react"
import SeatService, { ISeat, ISeatBulkDelete, ISeatCreate, ISeatUpdate } from "../../services/SeatService"
import LetterSelect from "../../components/LetterSelect"
import SeatTypeService, { ISeatType } from "../../services/SeatTypeService"
import DeleteConfirmation from "../../components/DeleteConfirmation"
import Confirmation from "../../components/Confirmation"

interface SeatsModalProps {
    eventId: number | null
    show: boolean
    title: string
    onClose: () => void
}

function SeatsModal(props: SeatsModalProps) {
    const { hasPermission } = useAuthContext()
    const [message, setMessage] = useState("")

    const [seatTypes, setSeatTypes] = useState<Array<ISeatType>>([])
    const GetSeatTypes = async () => {
        if (props.eventId && props.show) {
            const res = await SeatTypeService.listAll(props.eventId)

            if (res?.isSuccess) {
                setSeatTypes(res.data)
            } else {
                setSeatTypes([])
                console.log(res?.message)
            }
        } else {
            setSeatTypes([])
        }
    }

    const [seats, setSeats] = useState<Array<ISeat>>([])
    const GetSeats = async () => {
        if (props.eventId && props.show) {
            const res = await SeatService.listAll(props.eventId)

            if (res?.isSuccess) {
                setSeats(res.data)
            } else {
                setSeats([])
                console.log(res?.message)
            }
        } else {
            setSeats([])
        }
    }

    useEffect(() => {
        GetSeatTypes()
        GetSeats()
    }, [props.eventId, props.show])

    const [seatMatrix, setSeatMatrix] = useState<Array<Array<any>>>([])
    useEffect(() => {
        const letters = seats.map((x) => x.letter).filter((value, index, self) => self.indexOf(value) === index).sort((a, b) => a > b ? 1 : -1)
        const minNumber = Math.min(...seats.map(x => x.number))
        const maxNumber = Math.max(...seats.map(x => x.number))

        const matrix: Array<any> = []
        letters.forEach(letter => {
            const row: Array<any> = []
            for (let num = minNumber; num <= maxNumber; num++) {
                const seat = seats.find(x => x.letter === letter && x.number === num)
                if (seat) {
                    row.push(<li key={seat.id} style={{ backgroundColor: seat.seatType?.color || "#fff" }} onClick={() => handleEdit(seat.id)}>{seat.letter}{seat.number}</li>)
                } else {
                    row.push(<li key={`${letter}${num}`} onClick={() => handleAddNew(letter, num)}></li>)
                }
            }

            matrix.push(<ul key={letter} className="seat-row">{row}</ul>)
        })

        setSeatMatrix(matrix)
    }, [seats])

    const [seatTypeOptions, setSeatTypeOptions] = useState([] as any[])
    useEffect(() => {
        setSeatTypeOptions(seatTypes.map(item => <option key={item.id} value={item.id || 0}>{item.name}</option>))
    }, [seatTypes])

    //Add New Or Update
    const initCreateFormData: ISeatCreate = {
        beginLetter: "",
        endLetter: "",
        beginNumber: null,
        endNumber: null,
        seatTypeId: null
    }

    const [createFormData, setCreateFormData] = useState(initCreateFormData)
    const [validatedCreateForm, setValidatedCreateForm] = useState(false)
    const [messageCreateFormModal, setMessageCreateFormModal] = useState("")
    const formCreateRef = useRef<HTMLFormElement>(null)

    const [showCreateModal, setShowCreateModal] = useState(false)
    const handleCloseCreateModal = () => {
        setShowCreateModal(false)
        setValidatedCreateForm(false)
        setMessageCreateFormModal("")
    }
    const handleShowCreateModal = () => {
        setCreateFormData(initCreateFormData)
        setShowCreateModal(true)
        setMessage("")
    }

    const handleCreateFormControlChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
        if (e.target.name === "beginNumber" || e.target.name === "endNumber" || e.target.name === "seatTypeId") {
            setCreateFormData({ ...createFormData, [e.target.name]: e.target.value ? parseInt(e.target.value) : null })
        } else {
            setCreateFormData({ ...createFormData, [e.target.name]: e.target.value })
        }
    }

    const [processing, setProcessing] = useState<boolean>(false)
    const handleCreateOrUpdate = async (e: SyntheticEvent) => {
        e.preventDefault()

        if (!processing) {
            setProcessing(true)
            if (formCreateRef.current?.checkValidity()) {
                if (props.eventId) {
                    const res = await SeatService.createOrUpdate(props.eventId, createFormData)

                    if (res?.isSuccess) {
                        GetSeats()
                        handleCloseCreateModal()
                        setMessage(res.message)
                    } else {
                        setMessageCreateFormModal(res?.message)
                    }
                }
            } else {
                setValidatedCreateForm(true)
            }
            setProcessing(false)
        }
    }

    //EDIT
    const initFormData: ISeatUpdate = {
        id: null,
        letter: "",
        number: null,
        seatTypeId: null
    }
    const [formData, setFormData] = useState<ISeatUpdate>(initFormData)
    const [validatedForm, setValidatedForm] = useState(false)
    const [messageFormModal, setMessageFormModal] = useState("")
    const formRef = useRef<HTMLFormElement>(null)

    const [showModal, setShowModal] = useState(false)
    const handleCloseModal = () => {
        setShowModal(false)
        setValidatedForm(false)
        setMessageFormModal("")
    }
    const handleShowModal = () => {
        setShowModal(true)
        setMessage("")
    }

    const modalTitle = (formData.id || 0) > 0 ? "Edit Seat" : "New Seat"

    const handleAddNew = (letter: string, number: number) => {
        setFormData({ ...initFormData, letter: letter, number: number })
        handleShowModal()
    }

    const handleEdit = async (id: number | null) => {
        if (props.eventId && id) {
            const res = await SeatService.get(props.eventId, id)

            if (res?.isSuccess) {
                setFormData(res.data)
                handleShowModal()
            } else {
                setFormData(initFormData)
                console.log(res?.message)
            }
        } else {
            setFormData(initFormData)
        }
    }

    const handleFormControlChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
        if (e.target.name === "number" || e.target.name === "seatTypeId") {
            setFormData({ ...formData, [e.target.name]: e.target.value ? parseInt(e.target.value) : null })
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value })
        }
    }
    const handleSave = async (e: SyntheticEvent) => {
        e.preventDefault()

        if (!processing) {
            setProcessing(true)
            if (formRef.current?.checkValidity()) {
                if (props.eventId) {
                    if (formData.id && formData.id > 0) {
                        const res = await SeatService.update(props.eventId, formData.id, formData)

                        if (res?.isSuccess) {
                            GetSeats()
                            handleCloseModal()
                            setMessage(res.message)
                        } else {
                            setMessageFormModal(res?.message)
                        }
                    } else {
                        const res = await SeatService.create(props.eventId, formData)

                        if (res?.isSuccess) {
                            GetSeats()
                            handleCloseModal()
                            setMessage(res.message)
                        } else {
                            setMessageFormModal(res?.message)
                        }
                    }
                }
            } else {
                setValidatedForm(true)
            }
            setProcessing(false)
        }
    }

    //DELETE
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false)

    const hideConfirmationModal = () => {
        setDisplayConfirmationModal(false)
    }

    const showDeleteConfirmation = () => {
        setDisplayConfirmationModal(true)
        setMessage("")
    }

    const handleDelete = async () => {
        if (props.eventId && formData.id) {
            const res = await SeatService.delete(props.eventId, formData.id)

            if (res?.isSuccess) {
                GetSeats()
            }

            handleCloseModal()
            hideConfirmationModal()
            setMessage(res.message)
        }
    }

    //Bulk delete
    const initBulkDeleteFormData: ISeatBulkDelete = {
        beginLetter: "",
        endLetter: "",
        beginNumber: null,
        endNumber: null
    }

    const [bulkDeleteFormData, setBulkDeleteFormData] = useState(initBulkDeleteFormData)
    const [validatedBulkDeleteForm, setValidatedBulkDeleteForm] = useState(false)
    const [messageBulkDeleteFormModal, setMessageBulkDeleteFormModal] = useState("")
    const formBulkDeleteRef = useRef<HTMLFormElement>(null)

    const [showBulkDeleteModal, setShowBulkDeleteModal] = useState(false)
    const handleCloseBulkDeleteModal = () => {
        setShowBulkDeleteModal(false)
        setValidatedBulkDeleteForm(false)
        setMessageBulkDeleteFormModal("")
    }
    const handleShowBulkDeleteModal = () => {
        setBulkDeleteFormData(initBulkDeleteFormData)
        setShowBulkDeleteModal(true)
        setMessage("")
    }

    const handleBulkDeleteFormControlChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
        if (e.target.name === "beginNumber" || e.target.name === "endNumber") {
            setBulkDeleteFormData({ ...bulkDeleteFormData, [e.target.name]: e.target.value ? parseInt(e.target.value) : null })
        } else {
            setBulkDeleteFormData({ ...bulkDeleteFormData, [e.target.name]: e.target.value })
        }
    }

    const [displayBulkDeleteConfirmationModal, setDisplayBulkDeleteConfirmationModal] = useState(false)
    const [messageBulkDeleteConfirmation, setMessageBulkDeleteConfirmation] = useState("")

    const hideBulkDeleteConfirmationModal = () => {
        setDisplayBulkDeleteConfirmationModal(false)
    }

    const showBulkDeleteConfirmation = () => {
        if (formBulkDeleteRef.current?.checkValidity()) {
            setDisplayBulkDeleteConfirmationModal(true)
            setMessageBulkDeleteConfirmation(`Bạn có muốn xóa tất cả các ghế có ký tự từ ${bulkDeleteFormData.beginLetter} đến ${bulkDeleteFormData.endLetter} và có số từ ${bulkDeleteFormData.beginNumber} đến ${bulkDeleteFormData.endNumber}`)
            setMessage("")
        } else {
            setValidatedBulkDeleteForm(true)
        }
    }

    const handleBulkDelete = async () => {
        if (!processing) {
            setProcessing(true)
            if (formBulkDeleteRef.current?.checkValidity()) {
                if (props.eventId) {
                    const res = await SeatService.bulkDelete(props.eventId, bulkDeleteFormData)

                    if (res?.isSuccess) {
                        GetSeats()

                        handleCloseBulkDeleteModal()
                        hideBulkDeleteConfirmationModal()
                        setMessage(res.message)
                    } else {
                        setMessageBulkDeleteFormModal(res?.message)
                    }
                }
            } else {
                setValidatedBulkDeleteForm(true)
            }
            setProcessing(false)
        }
    }

    return (
        <>
            <Modal show={props.show} onHide={props.onClose} backdrop="static" keyboard={false} dialogClassName="modal-90w">
                <Modal.Header closeButton className="p-2">
                    <Modal.Title><i className="fas fa-chair"></i> Seats <span>|</span> {props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0">
                    <Card className="m-0" style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
                        <Card.Header>
                            {hasPermission(Permissions.Seats.Create) &&
                                <Button variant="info" size="sm" onClick={handleShowCreateModal} className="mr-2">
                                    <i className="fas fa-plus"></i> Add new or update
                                </Button>
                            }
                            {hasPermission(Permissions.Seats.Delete) &&
                                <Button variant="danger" size="sm" onClick={handleShowBulkDeleteModal}>
                                    <i className="fas fa-trash-alt"></i> Delete
                                </Button>
                            }
                            <div className="card-tools">
                                <ul style={{ listStyle: "none", display: "flex", margin: 0, padding: 0, alignItems: "center", flexWrap: "nowrap" }}>
                                    {seatTypes.map(item => <li
                                        key={item.id}
                                        style={{ flex: 1, margin: "0 5px", padding: "0", whiteSpace: "nowrap" }}>
                                        <i className="fas fa-square-full" style={{ color: item.color || "#fff" }}></i> {item.name}
                                    </li>)}
                                </ul>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            {message !== "" && <Alert variant="info" onClose={() => setMessage("")} dismissible>{message}</Alert>}
                            <div className="seats">
                                {seatMatrix}
                            </div>
                        </Card.Body>
                    </Card>
                </Modal.Body>
            </Modal>

            <Modal show={showCreateModal} onHide={handleCloseCreateModal} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Add New or Update Seats</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validatedCreateForm} ref={formCreateRef as RefObject<HTMLFormElement>}>
                        <Form.Group className="mb-3">
                            <Form.Label>Rows</Form.Label>
                            <InputGroup>
                                <InputGroup.Text>From</InputGroup.Text>
                                <LetterSelect name="beginLetter" required value={createFormData.beginLetter} onChange={handleCreateFormControlChange}></LetterSelect>
                                <InputGroup.Text>to</InputGroup.Text>
                                <LetterSelect name="endLetter" required value={createFormData.endLetter} onChange={handleCreateFormControlChange}></LetterSelect>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Numbers</Form.Label>
                            <InputGroup>
                                <InputGroup.Text>From</InputGroup.Text>
                                <Form.Control type="number" name="beginNumber" required min={1} value={createFormData.beginNumber || ""} onChange={(e) => handleCreateFormControlChange(e as any)} />
                                <InputGroup.Text>to</InputGroup.Text>
                                <Form.Control type="number" name="endNumber" required min={1} value={createFormData.endNumber || ""} onChange={(e) => handleCreateFormControlChange(e as any)} />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Type</Form.Label>
                            <Form.Select name="seatTypeId" value={createFormData.seatTypeId || ""} onChange={(e) => handleCreateFormControlChange(e as any)}>
                                <option value=""></option>
                                {seatTypeOptions}
                            </Form.Select>
                        </Form.Group>
                    </Form>
                    {messageCreateFormModal !== "" && <Alert variant="danger" onClose={() => setMessageCreateFormModal("")} dismissible>{messageCreateFormModal}</Alert>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleCloseCreateModal}><i className="fas fa-times"></i> Cancel</Button>
                    <Button variant="info" onClick={handleCreateOrUpdate} disabled={processing}>
                        {processing ? <Spinner animation="border" size="sm" /> : <i className="fas fa-save"></i>} Save
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showModal} onHide={handleCloseModal} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validatedForm} ref={formRef as RefObject<HTMLFormElement>}>
                        <Form.Group className="mb-3">
                            <Form.Label>Letters</Form.Label>
                            <LetterSelect name="letter" required value={formData.letter} onChange={handleFormControlChange}></LetterSelect>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Numbers</Form.Label>
                            <Form.Control type="number" name="number" required min={1} value={formData.number || ""} onChange={(e) => handleFormControlChange(e as any)} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Class</Form.Label>
                            <Form.Select name="seatTypeId" value={formData.seatTypeId || ""} onChange={(e) => handleFormControlChange(e as any)}>
                                <option value=""></option>
                                {seatTypeOptions}
                            </Form.Select>
                        </Form.Group>
                    </Form>
                    {messageFormModal !== "" && <Alert variant="danger" onClose={() => setMessageFormModal("")} dismissible>{messageFormModal}</Alert>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleCloseModal}><i className="fas fa-times"></i> Cancel</Button>

                    {(hasPermission(Permissions.Seats.Delete) && formData.id) &&
                        <Button variant="danger" onClick={() => showDeleteConfirmation()} disabled={processing}>
                            {processing ? <Spinner animation="border" size="sm" /> : <i className="fas fa-trash-alt"></i>} Delete
                        </Button>}

                    {((hasPermission(Permissions.Seats.Edit) && formData.id) || (hasPermission(Permissions.Seats.Create) && formData.id === null)) &&
                        <Button variant="info" onClick={handleSave} disabled={processing}>
                            {processing ? <Spinner animation="border" size="sm" /> : <i className="fas fa-save"></i>} Save
                        </Button>}
                </Modal.Footer>
            </Modal>

            <Modal show={showBulkDeleteModal} onHide={handleCloseBulkDeleteModal} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Seats</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validatedBulkDeleteForm} ref={formBulkDeleteRef as RefObject<HTMLFormElement>}>
                        <Form.Group className="mb-3">
                            <Form.Label>Letters</Form.Label>
                            <InputGroup>
                                <InputGroup.Text>From</InputGroup.Text>
                                <LetterSelect name="beginLetter" required value={bulkDeleteFormData.beginLetter} onChange={handleBulkDeleteFormControlChange}></LetterSelect>
                                <InputGroup.Text>to</InputGroup.Text>
                                <LetterSelect name="endLetter" required value={bulkDeleteFormData.endLetter} onChange={handleBulkDeleteFormControlChange}></LetterSelect>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Numbers</Form.Label>
                            <InputGroup>
                                <InputGroup.Text>From</InputGroup.Text>
                                <Form.Control type="number" name="beginNumber" required min={1} value={bulkDeleteFormData.beginNumber || ""} onChange={(e) => handleBulkDeleteFormControlChange(e as any)} />
                                <InputGroup.Text>to</InputGroup.Text>
                                <Form.Control type="number" name="endNumber" required min={1} value={bulkDeleteFormData.endNumber || ""} onChange={(e) => handleBulkDeleteFormControlChange(e as any)} />
                            </InputGroup>
                        </Form.Group>
                    </Form>
                    {messageBulkDeleteFormModal !== "" && <Alert variant="danger" onClose={() => setMessageBulkDeleteFormModal("")} dismissible>{messageBulkDeleteFormModal}</Alert>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleCloseBulkDeleteModal}><i className="fas fa-times"></i> Cancel</Button>
                    <Button variant="danger" onClick={() => showBulkDeleteConfirmation()} disabled={processing}>
                        {processing ? <Spinner animation="border" size="sm" /> : <i className="fas fa-trash-alt"></i>} Delete
                    </Button>
                </Modal.Footer>
            </Modal>

            <Confirmation show={displayBulkDeleteConfirmationModal} message={messageBulkDeleteConfirmation} onConfirm={handleBulkDelete} onHide={hideBulkDeleteConfirmationModal}></Confirmation>
            <DeleteConfirmation show={displayConfirmationModal} onConfirm={handleDelete} onHide={hideConfirmationModal} id={formData.id} name={`${formData.letter}${formData.number}`} />
        </>
    )
}

export default SeatsModal