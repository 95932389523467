import { ChangeEvent, useRef, useState } from "react"
import { Alert, Button, Card, Col, Container, Form, ProgressBar, Row } from "react-bootstrap"
import UploadService from "../services/UploadService"
import Configs from "../Configs"
import ContentHeader from "../components/ContentHeader"
import ContentBody from "../components/ContentBody"

function FileUpload() {
    const inputFileRef = useRef<HTMLInputElement>(null)
    const [selectedFile, setSelectedFile] = useState<any>(null)
    const [status, setStatus] = useState<string>("")
    const [progress, setProgress] = useState<number>(0)
    const [message, setMessage] = useState<string>("")
    const [fileName, setFileName] = useState<string>("")

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files?.length) {
            setSelectedFile(e.target.files[0])
        } else {
            setSelectedFile(null)
        }
    }

    const handleFileUpload = () => {
        if (!selectedFile) {
            alert("Please select a file to upload.")
            return
        }

        const chunkSize: number = Configs.CHUNK_SIZE
        const totalChunks: number = Math.ceil(selectedFile.size / chunkSize)
        const chunkProgress: number = 100 / totalChunks
        let chunkNumber: number = 1
        let start: number = 0
        let end: number = chunkSize

        const uploadNextChunk = async () => {
            if (chunkNumber <= totalChunks) {
                const chunk = selectedFile.slice(start, end)
                const formData = new FormData()
                formData.append("File", chunk)
                formData.append("Number", chunkNumber.toString())
                formData.append("Total", totalChunks.toString())
                formData.append("FileId", selectedFile.name)

                const res = await UploadService.uploadChunk(formData)

                if (res?.isSuccess) {
                    const temp = `Chunk ${chunkNumber}/${totalChunks} (${((chunkNumber / totalChunks) * 100).toFixed(0)}%) uploaded successfully`
                    setStatus(temp)
                    setProgress(Number((chunkNumber) * chunkProgress))
                    setMessage(res.message)
                    setFileName(res.data)

                    chunkNumber++
                    start = end
                    end += chunkSize
                    uploadNextChunk()
                } else {
                    setMessage(`Error uploading chunk: ${res?.message}`)
                }
            } else {
                setProgress(0)
                setSelectedFile(null)
                setStatus("File upload completed")
                setMessage("")

                if (inputFileRef.current) {
                    inputFileRef.current.value = ""
                }
            }
        }

        uploadNextChunk()
    }

    return (
        <>
            <ContentHeader title="Resumable File Upload" />

            <ContentBody>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <Card.Body>
                                    {status != "" && <Alert variant="info">{status}</Alert>}
                                    <Form.Control type="file" ref={inputFileRef} onChange={handleFileChange} />
                                    {progress > 0 && <ProgressBar striped animated variant="success" className="my-1" now={progress} />}
                                    {message != "" && <p>{message}</p>}
                                    {fileName != "" && <p>Results: {fileName}</p>}
                                    <Button onClick={handleFileUpload} className="mt-3">Upload File</Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </ContentBody>
        </>
    )
}

export default FileUpload