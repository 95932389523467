import { useState, useEffect, useRef, SyntheticEvent, RefObject } from "react"
import { Link } from "react-router-dom"
import useAuthContext from "../hooks/useAuthContext"
import AuthService from "../services/AuthService"
import RoutePath from "../constants/RoutePath"
import { Alert, Button, Form, InputGroup, Spinner } from "react-bootstrap"

function Login() {
    const [userName, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [message, setMessage] = useState("")

    const userNameRef = useRef<HTMLInputElement>(null)
    const [validatedForm, setValidatedForm] = useState(false)
    const formRef = useRef<HTMLFormElement>(null)

    useEffect(() => {
        userNameRef.current?.focus()
    }, [])

    const { onLogin } = useAuthContext()

    const [isLoading, setIsLoading] = useState(false)
    const handleLogin = async (e: SyntheticEvent) => {
        e.preventDefault()

        if (!isLoading) {
            setIsLoading(true)
            try {
                if (formRef.current?.checkValidity()) {
                    const res = await AuthService.login(userName, password)

                    if (res?.isSuccess) {
                        onLogin(res.data)
                    } else {
                        setMessage(res?.message)
                    }
                } else {
                    setValidatedForm(true)
                }
            } catch (error) {
                console.log(error)
            }
            setIsLoading(false)
        }
    }

    return (
        <div className="card max-w-400">
            <div className="card-body">
                <p className="login-box-msg">
                    Login to start your session
                </p>
                {message !== "" && <Alert variant="info" onClose={() => setMessage("")} dismissible>{message}</Alert>}
                <Form noValidate validated={validatedForm} ref={formRef as RefObject<HTMLFormElement>} className="mb-4">
                    <InputGroup className="mb-3">
                        <InputGroup.Text><i className="fas fa-user"></i></InputGroup.Text>
                        <Form.Control type="text" required autoComplete="off" placeholder="User Name" ref={userNameRef} value={userName} onChange={(e) => setUsername(e.target.value)} style={{ borderTopRightRadius: ".375rem", borderBottomRightRadius: ".375rem" }} />
                        <Form.Control.Feedback type="invalid">User Name is required.</Form.Control.Feedback>
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <InputGroup.Text><i className="fas fa-key"></i></InputGroup.Text>
                        <Form.Control type="password" required autoComplete="off" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} style={{ borderTopRightRadius: ".375rem", borderBottomRightRadius: ".375rem" }} />
                        <Form.Control.Feedback type="invalid">Password is required.</Form.Control.Feedback>
                    </InputGroup>
                    <Button variant="primary" className="btn-block" onClick={handleLogin}>
                        {isLoading ? <><Spinner animation="border" size="sm" /> Sign In</> : <>Sign In</>}
                    </Button>
                </Form>
                <p className="mb-1">
                    <Link to={RoutePath.AUTH.FORGOT_PASSWORD}>I forgot my password</Link>
                </p>
                <p className="mb-0">
                    <Link to={RoutePath.AUTH.REGISTER} className="text-center">Register a new membership</Link>
                </p>
            </div>
        </div>
    )
}

export default Login