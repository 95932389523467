import { SyntheticEvent } from "react"
import { Link, useNavigate } from "react-router-dom"
import RoutePath from "../constants/RoutePath"
import useAuthContext from "../hooks/useAuthContext"
import AuthService from "../services/AuthService"
import TokenService from "../services/TokenService"

function UserNavbar(props: { showControlSidebarButtonToggle?: boolean }) {
    const { user } = useAuthContext()

    const navigate = useNavigate()

    const handleLogout = async (e: SyntheticEvent) => {
        e.preventDefault()

        await AuthService.logout()
        TokenService.removeUser()
        navigate(RoutePath.AUTH.LOGIN)
    }

    return (
        <ul className="navbar-nav ml-auto">
            {!user ? (
                <>
                    <li className="nav-item">
                        <Link to={RoutePath.AUTH.LOGIN} className="nav-link"><i className="fas fa-sign-in-alt"></i> Login</Link>
                    </li>
                    <li className="nav-item">
                        <Link to={RoutePath.AUTH.REGISTER} className="nav-link"><i className="fas fa-user-edit"></i> Register</Link>
                    </li>
                </>
            ) : (
                <>
                    <li className="nav-item dropdown">
                        <a id="userMenu" href={RoutePath.AUTH.PROFILE} data-toggle="dropdown" aria-expanded="false" className="nav-link dropdown-toggle">
                            <i className="fas fa-user-circle"></i> {user.userInfo.username}
                        </a>
                        <ul aria-labelledby="userMenu" className="dropdown-menu dropdown-menu-right border-0 shadow">
                            <li><Link to={RoutePath.AUTH.PROFILE} className="dropdown-item"><i className="fas fa-user"></i> My Profile</Link></li>
                            <li><Link to={RoutePath.AUTH.CHANGE_PASSWORD} className="dropdown-item"><i className="fas fa-key"></i> Change Password</Link></li>
                            <li className="dropdown-divider" />
                            <li><Link to="/" onClick={handleLogout} className="dropdown-item text-red"><i className="fas fa-sign-out-alt"></i> Logout</Link></li>
                        </ul>
                    </li>
                </>
            )
            }
            {props.showControlSidebarButtonToggle && <li className="nav-item">
                <a className="nav-link" data-widget="control-sidebar" data-controlsidebar-slide="true" href="#" role="button">
                    <i className="fas fa-th-large"></i>
                </a>
            </li>
            }
        </ul>
    )
}

export default UserNavbar