import { Button, Modal } from 'react-bootstrap'

interface DeleteConfirmationProps {
    id: number | string | null
    name: string
    show: boolean
    onHide: () => void
    onConfirm: (id: number | string | null) => void
}

function DeleteConfirmation(props: DeleteConfirmationProps) {
    return (
        <Modal show={props.show} onHide={props.onHide} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Delete Confirmation!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Are you sure you want to delete <b>"{props.name}"</b>?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={props.onHide}>No</Button>
                {props.id && <Button variant="danger" onClick={() => props.onConfirm(props.id)}>Yes, delete it</Button>}
            </Modal.Footer>
        </Modal>
    )
}

export default DeleteConfirmation