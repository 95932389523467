import fileDownload from "js-file-download"
import Utils from "../utils/Utils"
import axiosClient, { ApiResponse, axiosCustomError } from "./axiosClient"
import axiosClientBlob from "./axiosClientBlob"

export interface ITicketTemplate {
    id: number | null
    name: string
    paperSizeWidth: string
    paperSizeHeight: string
    htmlContent: string
    styleSheet: string
    printMethod: string
    type: string
    isUsed: boolean
    providerId?: number
}

export default class TicketTemplateService {
    static async filter(providerId: number, pageIndex: number, pageSize: number, keyword: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/TicketTemplates/${providerId}?keyword=${keyword}&pageSize=${pageSize}&pageIndex=${pageIndex}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async get(providerId: number, id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/TicketTemplates/${providerId}/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async getAppVoucherHTMLTemplate(providerId: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/TicketTemplates/${providerId}/GetAppVoucherHTMLTemplate`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async getAllAppVoucherHTMLTemplateForAgency(agencyId: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/TicketTemplates/${agencyId}/GetAllAppVoucherHTMLTemplateForAgency`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async getEventTicketHTMLTemplate(providerId: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/TicketTemplates/${providerId}/GetEventTicketHTMLTemplate`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async delete(providerId: number, id: number) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/TicketTemplates/${providerId}/${id}`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async create(providerId: number, itemData: ITicketTemplate) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/TicketTemplates/${providerId}/Create`, itemData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async update(providerId: number, id: number, itemData: ITicketTemplate) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/TicketTemplates/${providerId}/${id}`, itemData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async changeIsUsed(providerId: number, id: number) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/TicketTemplates/${providerId}/ChangeIsUsed/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static generatePDF(providerId: number, id: number) {
        try {
            axiosClientBlob.post(`/api/TicketTemplates/${providerId}/GeneratePDF/${id}`).then(response => {
                const contentDisposition = response.headers["content-disposition"]
                var filename = Utils.getFileNameUTF8FromContentDisposition(contentDisposition)
                fileDownload(response.data, filename)
            }).catch(error => {
                console.error(error)
            })
        } catch (error) {
            return axiosCustomError(error)
        }
    }
}