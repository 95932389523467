import { ChangeEvent, KeyboardEvent, RefObject, SyntheticEvent, useEffect, useRef, useState } from "react"
import useGlobalContext from "../../hooks/useGlobalContext"
import PlaceTypeService, { IPlaceType } from "../../services/PlaceTypeService"
import Configs from "../../Configs"
import AddOrRemoveLanguages, { LanguageActionTypes } from "../../components/AddOrRemoveLanguages"
import ContentHeader from "../../components/ContentHeader"
import DeleteConfirmation from "../../components/DeleteConfirmation"
import ContentBody from "../../components/ContentBody"
import { Alert, Button, Card, Col, Container, Form, InputGroup, Modal, Row, Tab, Table, Tabs } from "react-bootstrap"
import Pagination from "../../components/Pagination"
import useAuthContext from "../../hooks/useAuthContext"
import Permissions from "../../constants/Permissions"

function PlaceTypes() {
    const { hasPermission } = useAuthContext()
    const { languages } = useGlobalContext()

    const [tableData, setTableData] = useState({
        data: new Array<IPlaceType>(),
        hasNext: false,
        hasPrevious: false,
        pageIndex: 1,
        pageSize: Configs.DEFAULT_PAGE_SIZE,
        totalPages: 0,
        totalRecords: 0
    })

    const initFormData: IPlaceType = {
        id: null,
        name: "",
        orderNumber: null,
        isUsed: false,
        translations: languages.filter(x => x.code === Configs.DEFAULT_LANGUAGE_CODE).map(x => {
            return {
                id: null,
                placeTypeId: null,
                languageId: x.id || 0,
                name: "",
                language: x
            }
        })
    }

    const [message, setMessage] = useState("")
    const [messageFormModal, setMessageFormModal] = useState("")
    const [formData, setFormData] = useState(initFormData)
    const [currentPageIndex, setCurrentPageIndex] = useState(1)
    const [deletedItem, setDeletedItem] = useState({
        id: 0,
        name: ""
    })

    const keywordRef = useRef<HTMLInputElement>(null)
    const formRef = useRef<HTMLFormElement>(null)
    const [validatedForm, setValidatedForm] = useState(false)
    const [activeTabKey, setActiveTabKey] = useState(languages[0]?.id?.toString())

    const FilterFn = async (pageIndex: number) => {
        let pageSize = Configs.DEFAULT_PAGE_SIZE
        let keyword = keywordRef.current?.value || ""

        const res = await PlaceTypeService.filter(pageIndex, pageSize, keyword)

        if (res?.isSuccess) {
            setTableData(res.data)
        } else {
            console.log(res?.message)
        }

        setCurrentPageIndex(pageIndex)
    }

    useEffect(() => {
        keywordRef.current?.focus()

        FilterFn(1)
    }, [])

    const handleSearch = () => {
        FilterFn(1)
    }

    const handleSearchInputKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            FilterFn(1)
        }
    }

    const handlePageChange = (pageNumber: number) => {
        FilterFn(pageNumber)
    }

    const handleFormControlChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleTranslateFormControlChange = (e: ChangeEvent<HTMLInputElement>, languageId: number | null) => {
        setFormData({ ...formData, translations: formData.translations.map(x => x.languageId === languageId ? { ...x, [e.target.name]: e.target.value } : x) })
    }

    const handleAddNew = () => {
        setFormData(initFormData)

        handleShowModal()
    }

    const handleEdit = async (id: number | null) => {
        if (id) {
            const res = await PlaceTypeService.get(id)

            if (res?.isSuccess) {
                setFormData(res.data)
                setActiveTabKey(res.data.translations[0]?.languageId?.toString())

                handleShowModal()
            } else {
                console.log(res?.message)
            }
        }
    }

    const handleSave = async (e: SyntheticEvent) => {
        e.preventDefault()

        if (formRef.current?.checkValidity()) {
            try {
                if (formData.id && formData.id > 0) {
                    const res = await PlaceTypeService.update(formData.id, formData)

                    if (res?.isSuccess) {
                        FilterFn(currentPageIndex)
                        handleCloseModal()
                        setMessage(res.message)
                    } else {
                        setMessageFormModal(res?.message)
                    }
                } else {
                    const res = await PlaceTypeService.create(formData)

                    if (res?.isSuccess) {
                        FilterFn(1)
                        handleCloseModal()
                        setMessage(res.message)
                    } else {
                        setMessageFormModal(res?.message)
                    }
                }
            } catch (error) {
                console.log(error)
            }
        } else {
            setValidatedForm(true)
        }
    }

    const [showModal, setShowModal] = useState(false)
    const handleCloseModal = () => {
        setShowModal(false)
        setValidatedForm(false)
        setMessageFormModal("")
    }
    const handleShowModal = () => {
        setShowModal(true)
        setMessage("")
    }

    const modalTitle = (formData.id || 0) > 0 ? "Edit Place Type" : "New Place Type"

    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false)

    const hideConfirmationModal = () => {
        setDeletedItem({
            id: 0,
            name: ""
        })

        setDisplayConfirmationModal(false)
    }

    const showDeleteConfirmation = (id: number | null, name: string) => {
        setDeletedItem({
            id: id || 0,
            name: name
        })

        setDisplayConfirmationModal(true)
        setMessage("")
    }

    const handleDelete = async () => {
        if (deletedItem.id > 0) {
            const res = await PlaceTypeService.delete(deletedItem.id)

            if (res?.isSuccess) {
                FilterFn(currentPageIndex)
            }

            hideConfirmationModal()
            setMessage(res.message)
        }
    }

    const handleLanguagesChange = async (languageId: number, action: LanguageActionTypes) => {
        if (action === "Add") {
            const newLang = languages.find(x => x.id === languageId)
            if (newLang) {
                if (formData.translations.filter(x => x.languageId === newLang.id).length === 0) {
                    const newFormData = { ...formData }

                    newFormData.translations.push({
                        id: null,
                        placeTypeId: null,
                        languageId: newLang.id || 0,
                        name: "",
                        language: newLang
                    })

                    setFormData(newFormData)
                    setActiveTabKey(newLang.id?.toString())
                }
            }
        } else {
            const newTranslations = formData.translations.filter(x => x.languageId !== languageId)

            setFormData({ ...formData, translations: newTranslations })
            setActiveTabKey(newTranslations[0]?.languageId.toString())
        }
    }

    const handleIsUsedSwitch = async (id: number | null) => {
        if (id) {
            const res = await PlaceTypeService.changeIsUsed(id);

            if (res?.isSuccess) {
                FilterFn(currentPageIndex);
            } else {
                console.log(res?.message);
            }
        }
    }

    return (
        <>
            <ContentHeader title="Place Types" />

            <ContentBody>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <Card.Header>
                                    {hasPermission(Permissions.PlaceTypes.Create) &&
                                        <Button variant="info" size="sm" onClick={handleAddNew}>
                                            <i className="fas fa-plus"></i> Add new
                                        </Button>
                                    }

                                    <div className="card-tools">
                                        <InputGroup size="sm">
                                            <Form.Control
                                                type="search"
                                                placeholder="Search"
                                                ref={keywordRef as RefObject<HTMLInputElement>}
                                                onKeyUp={handleSearchInputKeyPress}
                                            />
                                            <Button variant="info" size="sm" onClick={handleSearch}>
                                                <i className="fas fa-search"></i>
                                            </Button>
                                        </InputGroup>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    {message !== "" && <Alert variant="info" onClose={() => setMessage("")} dismissible>{message}</Alert>}
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Order Number</th>
                                                <th>Is Used</th>
                                                <th style={{ textAlign: "center", width: "80px" }}>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                tableData.data.map(item =>
                                                    <tr key={item.id}>
                                                        <td>{item.name}</td>
                                                        <td>{item.orderNumber || ""}</td>
                                                        <td style={{ textAlign: "center" }}>
                                                            <Form.Check type="switch" checked={item.isUsed} onChange={(e) => handleIsUsedSwitch(item.id)} disabled={!hasPermission(Permissions.PlaceTypes.Edit)} />
                                                        </td>
                                                        <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                                                            {hasPermission(Permissions.PlaceTypes.Edit) &&
                                                                <Button variant="warning" className="mr-1" size="sm" onClick={() => handleEdit(item.id)}><i className="fas fa-pencil-alt"></i> Edit</Button>
                                                            }
                                                            {hasPermission(Permissions.PlaceTypes.Delete) &&
                                                                <Button variant="danger" size="sm" onClick={() => showDeleteConfirmation(item.id, item.name)}><i className="fas fa-trash-alt"></i> Delete</Button>
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </Card.Body>
                                <Card.Footer>
                                    <Pagination hasNext={tableData.hasNext} hasPrevious={tableData.hasPrevious} pageIndex={tableData.pageIndex} pageSize={tableData.pageSize} totalPages={tableData.totalPages} totalRecords={tableData.totalRecords} handlePageChange={handlePageChange} />
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </ContentBody>

            <Modal show={showModal} onHide={handleCloseModal} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddOrRemoveLanguages selectedLanguages={formData.translations.map(x => x.language)} onChange={handleLanguagesChange}></AddOrRemoveLanguages>
                    <Form noValidate validated={validatedForm} ref={formRef as RefObject<HTMLFormElement>}>
                        <Tabs activeKey={activeTabKey} onSelect={(k) => setActiveTabKey(k || undefined)}>
                            {
                                formData.translations.sort((a, b) => { return (a.language.orderNumber || 0) - (b.language.orderNumber || 0) }).map(translate => {
                                    return (
                                        <Tab
                                            key={translate.languageId}
                                            eventKey={(translate.languageId).toString()}
                                            title={translate.language.name}
                                            className="p-3"
                                        >
                                            <Form.Group className="mb-3" controlId={"value_" + translate.languageId}>
                                                <Form.Label>Name</Form.Label>
                                                <Form.Control name="name" required value={translate?.name || ""} onChange={(e) => handleTranslateFormControlChange(e as any, translate.languageId)} />
                                                <Form.Control.Feedback type="invalid">Name is required.</Form.Control.Feedback>
                                            </Form.Group>
                                        </Tab>
                                    )
                                })
                            }
                        </Tabs>
                        <Form.Group className="mb-3" controlId="orderNumber">
                            <Form.Label>Order Number</Form.Label>
                            <Form.Control type="number" name="orderNumber" value={formData.orderNumber || ""} onChange={(e) => handleFormControlChange(e as any)} />
                        </Form.Group>
                    </Form>
                    {messageFormModal !== "" && <Alert variant="danger" onClose={() => setMessageFormModal("")} dismissible>{messageFormModal}</Alert>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleCloseModal}><i className="fas fa-times"></i> Cancel</Button>
                    <Button variant="info" onClick={handleSave}><i className="fas fa-save"></i> Save</Button>
                </Modal.Footer>
            </Modal>

            <DeleteConfirmation show={displayConfirmationModal} onConfirm={handleDelete} onHide={hideConfirmationModal} id={deletedItem.id} name={deletedItem.name} />
        </>
    )
}

export default PlaceTypes