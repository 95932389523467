import { ChangeEvent, KeyboardEvent, RefObject, SyntheticEvent, useEffect, useRef, useState } from "react"
import Configs from "../../Configs"
import useAuthContext from "../../hooks/useAuthContext"
import AgencyService, { IAgency } from "../../services/AgencyService"
import ContentHeader from "../../components/ContentHeader"
import ContentBody from "../../components/ContentBody"
import { Alert, Button, Card, Col, Container, Form, InputGroup, Modal, Row, Table } from "react-bootstrap"
import Permissions from "../../constants/Permissions"
import Pagination from "../../components/Pagination"
import DeleteConfirmation from "../../components/DeleteConfirmation"
import { SelectInstance } from "react-select"
import AsyncSelect from "react-select/async"
import { SelectOptionType } from "../../constants/SelectOptionType"
import UserService, { IUser } from "../../services/UserService"
import { UserTypes } from "../../constants/UserTypes"

function Agencies() {
    const { hasPermission } = useAuthContext()

    const [tableData, setTableData] = useState({
        data: new Array<IAgency>(),
        hasNext: false,
        hasPrevious: false,
        pageIndex: 1,
        pageSize: Configs.DEFAULT_PAGE_SIZE,
        totalPages: 0,
        totalRecords: 0
    })

    const initFormData: IAgency = {
        id: null,
        name: "",
        address: "",
        email: "",
        tel: "",
        isUsed: false,
        users: []
    }

    const [formData, setFormData] = useState(initFormData)
    const [message, setMessage] = useState("")
    const [messageFormModal, setMessageFormModal] = useState("")
    const [validatedFormModal, setValidatedFormModal] = useState(false)
    const [currentPageIndex, setCurrentPageIndex] = useState(1)
    const [deletedItem, setDeletedItem] = useState({
        id: 0,
        name: ""
    })

    const keywordRef = useRef<HTMLInputElement>(null)
    const formRef = useRef<HTMLFormElement>(null)

    const FilterFn = async (pageIndex: number) => {
        let pageSize = Configs.DEFAULT_PAGE_SIZE
        let keyword = keywordRef.current?.value || ""

        const res = await AgencyService.filter(pageIndex, pageSize, keyword)

        if (res?.isSuccess) {
            setTableData(res.data)
        } else {
            console.log(res?.message)
        }

        setCurrentPageIndex(pageIndex)
    }

    useEffect(() => {
        keywordRef.current?.focus()

        FilterFn(1)
    }, [])

    const handleSearch = () => {
        FilterFn(1)
    }

    const handleSearchInputKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            FilterFn(1)
        }
    }

    const handlePageChange = (pageNumber: number) => {
        FilterFn(pageNumber)
    }

    const handleFormControlChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleIsUsedSwitch = async (id: number | null) => {
        if (id) {
            const res = await AgencyService.changeIsUsed(id)

            if (res?.isSuccess) {
                FilterFn(currentPageIndex)
            } else {
                console.log(res?.message)
            }
        }
    }

    const handleAddNew = () => {
        setFormData(initFormData)
        handleShowModal()
    }

    //Delete
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false)

    const hideConfirmationModal = () => {
        setDeletedItem({
            id: 0,
            name: ""
        })

        setDisplayConfirmationModal(false)
    }

    const showDeleteConfirmation = (id: number | null, name: string) => {
        if (id) {
            setDeletedItem({
                id: id,
                name: name
            })

            setDisplayConfirmationModal(true)
            setMessage("")
        }
    }

    const handleDelete = async () => {
        const res = await AgencyService.delete(deletedItem.id)

        if (res?.isSuccess) {
            FilterFn(currentPageIndex)
        }

        hideConfirmationModal()
        setMessage(res.message)
    }

    //Modal
    const [showModal, setShowModal] = useState(false)
    const handleCloseModal = () => {
        setShowModal(false)
        setValidatedFormModal(false)
        setMessageFormModal("")
    }
    const handleShowModal = () => {
        setMessage("")
        setShowModal(true)
    }

    const modalTitle = formData.id !== null ? "Edit Agency" : "New Agency"

    const handleEdit = async (id: number | null) => {
        if (id) {
            const res = await AgencyService.get(id)

            if (res?.isSuccess) {
                setFormData({
                    ...initFormData,
                    id: res.data.id,
                    name: res.data.name,
                    address: res.data.address,
                    tel: res.data.tel,
                    email: res.data.email,
                    users: res.data.users
                })

                handleShowModal()
            } else {
                console.log(res?.message)
            }
        }
    }

    const handleSave = async (e: SyntheticEvent) => {
        e.preventDefault()

        if (formRef.current?.checkValidity()) {
            if (formData.id) {
                const res = await AgencyService.update(formData.id, formData)

                if (res?.isSuccess) {
                    FilterFn(currentPageIndex)
                    handleCloseModal()
                    setMessage(res.message)
                } else {
                    setMessageFormModal(res?.message)
                }
            } else {
                const res = await AgencyService.create(formData)

                if (res?.isSuccess) {
                    FilterFn(1)
                    handleCloseModal()
                    setMessage(res.message)
                } else {
                    setMessageFormModal(res?.message)
                }
            }
        } else {
            setValidatedFormModal(true)
        }
    }

    //AsyncSelect
    const [selectedOption, setSelectedOption] = useState(null as SelectOptionType | null)
    const asyncRef = useRef<SelectInstance<SelectOptionType> | null>(null)

    const handleSelectChange = async (option: SelectOptionType | null) => {
        setSelectedOption(option)

        if (option?.value) {
            let value = option?.value as number

            if (formData.users.filter(x => x.id === value).length === 0) {
                var res = await UserService.get(value)

                if (res.isSuccess) {
                    formData.users.push(res.data)

                    setFormData(formData)

                    setSelectedOption(null)
                    asyncRef.current?.focus()
                }
            }
        }
    }

    const filterUsers = async (inputValue: string) => {
        var options: SelectOptionType[] = []

        var res = await UserService.search(UserTypes.AGENCY, inputValue)

        if (res.isSuccess && res.data) {
            options = res.data.map((item: IUser) => {
                return { value: item.id, label: item.userName }
            })
        }

        return options
    }

    const loadUserOptions = (inputValue: string) => new Promise<SelectOptionType[]>((resolve) => {
        resolve(filterUsers(inputValue))
    })

    const handleRemoveUser = (userId: number | null) => {
        setFormData({ ...formData, users: formData.users.filter(x => x.id !== userId) })
    }

    return (
        <>
            <ContentHeader title="Agencies" />

            <ContentBody>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <Card.Header>
                                    {hasPermission(Permissions.Agencies.Create) &&
                                        <Button variant="info" size="sm" onClick={handleAddNew}>
                                            <i className="fas fa-plus"></i> Add New
                                        </Button>
                                    }

                                    <div className="card-tools">
                                        <InputGroup size="sm">
                                            <Form.Control
                                                type="search"
                                                placeholder="Search"
                                                ref={keywordRef as RefObject<HTMLInputElement>}
                                                onKeyUp={handleSearchInputKeyPress}
                                            />
                                            <Button variant="info" size="sm" onClick={handleSearch}>
                                                <i className="fas fa-search"></i>
                                            </Button>
                                        </InputGroup>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    {message !== "" && <Alert variant="info" onClose={() => setMessage("")} dismissible>{message}</Alert>}
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Address</th>
                                                <th>Tel</th>
                                                <th>Email</th>
                                                <th style={{ textAlign: "center", width: "100px" }}>Is Used</th>
                                                <th style={{ textAlign: "center", width: "90px" }}>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                tableData.data.map(item =>
                                                    <tr key={item.id}>
                                                        <td>{item.name}</td>
                                                        <td>{item.address}</td>
                                                        <td>{item.tel}</td>
                                                        <td>{item.email}</td>
                                                        <td style={{ textAlign: "center" }}>
                                                            <Form.Check type="switch" checked={item.isUsed} onChange={() => handleIsUsedSwitch(item.id)} disabled={!hasPermission(Permissions.Agencies.Edit)} />
                                                        </td>
                                                        <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                                                            {hasPermission(Permissions.Agencies.Edit) &&
                                                                <Button variant="warning" size="sm" className="mr-1" onClick={() => handleEdit(item.id)}><i className="fas fa-pencil-alt"></i> Edit</Button>
                                                            }
                                                            {hasPermission(Permissions.Agencies.Delete) &&
                                                                <Button variant="danger" size="sm" onClick={() => showDeleteConfirmation(item.id, item.name)}><i className="fas fa-trash-alt"></i> Delete</Button>
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </Card.Body>
                                <Card.Footer>
                                    <Pagination hasNext={tableData.hasNext} hasPrevious={tableData.hasPrevious} pageIndex={tableData.pageIndex} pageSize={tableData.pageSize} totalPages={tableData.totalPages} totalRecords={tableData.totalRecords} handlePageChange={handlePageChange} />
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </ContentBody>

            <DeleteConfirmation show={displayConfirmationModal} onConfirm={handleDelete} onHide={hideConfirmationModal} id={deletedItem.id} name={deletedItem.name} />

            <Modal show={showModal} onHide={handleCloseModal} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validatedFormModal} ref={formRef as RefObject<HTMLFormElement>}>
                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" name="name" required value={formData.name || ""} onChange={(e) => handleFormControlChange(e as any)} />
                            <Form.Control.Feedback type="invalid">Name is required.</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="address">
                            <Form.Label>Address</Form.Label>
                            <Form.Control type="text" name="address" required value={formData.address || ""} onChange={(e) => handleFormControlChange(e as any)} />
                            <Form.Control.Feedback type="invalid">Address is required.</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="tel">
                            <Form.Label>Tel</Form.Label>
                            <Form.Control type="text" name="tel" required value={formData.tel || ""} onChange={(e) => handleFormControlChange(e as any)} />
                            <Form.Control.Feedback type="invalid">Tel is required.</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" name="email" required value={formData.email || ""} onChange={(e) => handleFormControlChange(e as any)} />
                            <Form.Control.Feedback type="invalid">Email is required.</Form.Control.Feedback>
                        </Form.Group>
                        <fieldset>
                            <legend>Agency Users</legend>
                            <AsyncSelect className="mb-3" isClearable value={selectedOption} loadOptions={loadUserOptions} onChange={handleSelectChange} ref={asyncRef} placeholder="Nhập thông tin người dùng để tìm kiếm." noOptionsMessage={({ inputValue }) => !inputValue ? "Nhập thông tin người dùng để tìm kiếm." : "Không tìm thấy người dùng nào!"} />
                            <Table hover bordered size="sm" className="mb-0">
                                <thead>
                                    <tr>
                                        <th>Username</th>
                                        <th>Email</th>
                                        <th style={{ width: "40px" }}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {formData.users.map((user: IUser) =>
                                        <tr key={user.id}>
                                            <td>
                                                {user.userName || ""}
                                            </td>
                                            <td>
                                                {user.email || ""}
                                            </td>
                                            <td>
                                                <Button variant="light" size="sm" onClick={() => handleRemoveUser(user.id)}><i className="fas fa-times"></i></Button>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </fieldset>
                    </Form>
                    {messageFormModal !== "" && <Alert variant="danger" onClose={() => setMessageFormModal("")} dismissible>{messageFormModal}</Alert>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleCloseModal}><i className="fas fa-times"></i> Cancel</Button>
                    <Button variant="info" onClick={handleSave}><i className="fas fa-save"></i> Save</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Agencies