import { ChangeEvent, useEffect, useState } from "react"
import { addDays } from "date-fns/esm"
import ContentHeader from "../../components/ContentHeader"
import useAppContext from "../../hooks/useAppContext"
import VoucherService from "../../services/VoucherService"
import { DatasetChart, LineChart, PieChart } from "../../components/ChartJS"
import Utils from "../../utils/Utils"
import Configs from "../../Configs"
import ContentBody from "../../components/ContentBody"
import { Card, Col, Container, Form, Row, Table } from "react-bootstrap"
import DateRangePicker from "../../components/DateRangePicker"

function AgencyVoucherDashboard() {
    const { state } = useAppContext()
    const [dateReport, setDateReport] = useState<{
        startDate: Date,
        endDate: Date
    }>({
        startDate: addDays(new Date(), -Configs.DEFAULT_LAST_DAYS + 1),
        endDate: new Date()
    })

    const handleDateChange = (startDate?: Date, endDate?: Date) => {
        if (startDate && endDate) {
            setDateReport({ startDate, endDate })
        }
    }

    useEffect(() => {
        if (state.currentAgencyId) {
            GetDailyActiveVouchersReportForAgency(dateReport.startDate, dateReport.endDate)
        }
    }, [state.currentAgencyId, dateReport])

    const [reportData, setReportData] = useState<{
        date: Date,
        quantity: number,
        amount: number,
        providerId: number,
        providerName: string,
        placeId: number,
        placeName: string
    }[] | null>(null)

    const GetDailyActiveVouchersReportForAgency = async (start: Date, end: Date) => {
        if (state.currentAgencyId) {
            const res = await VoucherService.dailyActiveVouchersReportForAgency(state.currentAgencyId, start, end)

            if (res?.isSuccess) {
                setReportData(res.data)
            } else {
                setReportData(null)
                console.log(res.message);
            }
        }
    }

    const [currentGroupBy, setCurrentGroupBy] = useState<string>("Place")

    useEffect(() => {
        if (reportData && reportData.length) {
            generateVoucherQuantityChartData()
            generateVoucherSalesChartData()
        } else {
            setVoucherQuantityChartData({ ...voucherQuantityChartData, labels: [], datasets: [] })
            setVoucherSalesChartData({ ...voucherSalesChartData, labels: [], datasets: [] })
        }
    }, [reportData])

    const handleGroupByChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setCurrentGroupBy(e.target.value)
    }

    useEffect(() => {
        generateVoucherQuantityChartData()
        generateVoucherSalesChartData()
    }, [currentGroupBy])

    const [voucherQuantityChartData, setVoucherQuantityChartData] = useState({
        title: "Number of Vouchers",
        labels: [] as string[],
        datasets: [] as DatasetChart[]
    })

    const generateVoucherQuantityChartData = () => {
        const labels = [] as string[]
        const datasets: DatasetChart[] = []

        if (reportData) {
            //dates
            var dates = reportData.map((x) => x.date).filter((value, index, self) => self.indexOf(value) === index).sort((a, b) => new Date(a).getTime() - new Date(b).getTime())

            dates.forEach(date => {
                //labels
                labels.push(Utils.dateToString(date, Configs.DATE_FORMAT))
            })

            //places
            if (currentGroupBy === "Place") {
                var places = reportData.map((x) => { return { id: x.placeId, name: x.placeName } }).filter((value, index, self) => self.findIndex(x => x.id === value.id) === index)
                places.forEach(place => {
                    const dataset = {
                        label: place.name,
                        data: []
                    } as DatasetChart

                    dates.forEach(date => {
                        var items = reportData.filter(x => x.placeId === place.id && x.date === date)

                        var quantity = 0
                        if (items) {
                            quantity = items.map(x => x.quantity).reduce((a, b) => a + b, 0)
                        }
                        dataset.data.push(quantity)
                    })

                    datasets.push(dataset)
                })
            } else if (currentGroupBy === "Provider") {
                var providers = reportData.map((x) => { return { id: x.providerId, name: x.providerName } }).filter((value, index, self) => self.findIndex(x => x.id === value.id) === index)
                providers.forEach(provider => {
                    const dataset = {
                        label: provider.name,
                        data: []
                    } as DatasetChart

                    dates.forEach(date => {
                        var items = reportData.filter(x => x.providerId === provider.id && x.date === date)

                        var quantity = 0
                        if (items) {
                            quantity = items.map(x => x.quantity).reduce((a, b) => a + b, 0)
                        }
                        dataset.data.push(quantity)
                    })

                    datasets.push(dataset)
                })
            }

            //Total
            if (datasets.length > 1) {
                const dataset = {
                    label: "Total",
                    data: []
                } as DatasetChart

                dates.forEach(date => {
                    var items = reportData.filter(x => x.date === date)

                    var quantity = 0
                    if (items) {
                        quantity = items.map(x => x.quantity).reduce((a, b) => a + b, 0)
                    }
                    dataset.data.push(quantity)
                })

                datasets.push(dataset)
            }

            setVoucherQuantityChartData({ ...voucherQuantityChartData, labels: labels, datasets: datasets })
        } else {
            setVoucherQuantityChartData({ ...voucherQuantityChartData, labels: [], datasets: [] })
        }
    }

    const [voucherSalesChartData, setVoucherSalesChartData] = useState({
        title: "Vouchers Sales",
        labels: [] as string[],
        datasets: [] as DatasetChart[]
    })

    const generateVoucherSalesChartData = () => {
        const labels = [] as string[]
        const datasets: DatasetChart[] = []

        if (reportData) {
            //dates
            var dates = reportData.map((x) => x.date).filter((value, index, self) => self.indexOf(value) === index).sort((a, b) => new Date(a).getTime() - new Date(b).getTime())

            dates.forEach(d => {
                //labels
                labels.push(Utils.dateToString(d, Configs.DATE_FORMAT))
            })

            //places
            if (currentGroupBy === "Place") {
                var places = reportData.map((x) => { return { id: x.placeId, name: x.placeName } }).filter((value, index, self) => self.findIndex(x => x.id === value.id) === index)
                places.forEach(place => {
                    const dataset = {
                        label: place.name,
                        data: []
                    } as DatasetChart

                    dates.forEach(date => {
                        var items = reportData.filter(x => x.placeId === place.id && x.date === date)

                        var amount = 0
                        if (items) {
                            amount = items.map(x => x.amount).reduce((a, b) => a + b, 0)
                        }
                        dataset.data.push(amount)
                    })

                    datasets.push(dataset)
                })
            } else if (currentGroupBy === "Provider") {
                var providers = reportData.map((x) => { return { id: x.providerId, name: x.providerName } }).filter((value, index, self) => self.findIndex(x => x.id === value.id) === index)
                providers.forEach(provider => {
                    const dataset = {
                        label: provider.name,
                        data: []
                    } as DatasetChart

                    dates.forEach(date => {
                        var items = reportData.filter(x => x.providerId === provider.id && x.date === date)

                        var amount = 0
                        if (items) {
                            amount = items.map(x => x.amount).reduce((a, b) => a + b, 0)
                        }
                        dataset.data.push(amount)
                    })

                    datasets.push(dataset)
                })
            }

            //Total
            if (datasets.length > 1) {
                const dataset = {
                    label: "Total",
                    data: []
                } as DatasetChart

                dates.forEach(date => {
                    var items = reportData.filter(x => x.date === date)

                    var amount = 0
                    if (items) {
                        amount = items.map(x => x.amount).reduce((a, b) => a + b, 0)
                    }
                    dataset.data.push(amount)
                })

                datasets.push(dataset)
            }

            setVoucherSalesChartData({ ...voucherSalesChartData, labels: labels, datasets: datasets })
        } else {
            setVoucherSalesChartData({ ...voucherSalesChartData, labels: [], datasets: [] })
        }
    }

    return (
        <>
            <ContentHeader title="Voucher Dashboard" />

            <ContentBody>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <Card.Header>
                                    <DateRangePicker startDate={dateReport.startDate} endDate={dateReport.endDate} onChange={handleDateChange} closeOnSelected={true}></DateRangePicker>
                                    <Form.Select className="ml-3" style={{ width: "auto", display: "inline-block" }} value={currentGroupBy} onChange={handleGroupByChange}>
                                        <option value="Place">Group by Place</option>
                                        <option value="Provider">Group by Provider</option>
                                    </Form.Select>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col lg={6} md={12}>
                                            <LineChart title={voucherSalesChartData.title} labels={voucherSalesChartData.labels} datasets={voucherSalesChartData.datasets}></LineChart>
                                        </Col>
                                        <Col lg={3} md={6}>
                                            <PieChart
                                                title={voucherSalesChartData.title}
                                                labels={voucherSalesChartData.datasets.filter(x => x.label !== "Total").map(ds => ds.label)}
                                                datasets={[{
                                                    label: "Sales",
                                                    data: voucherSalesChartData.datasets.filter(x => x.label !== "Total").map(ds => ds.data.reduce((a, b) => a + b, 0))
                                                }]}
                                            ></PieChart>
                                        </Col>
                                        <Col lg={3} md={6}>
                                            <Table responsive className="mt-2" size="sm">
                                                <thead>
                                                    <tr>
                                                        <th>{currentGroupBy}</th>
                                                        <th style={{ textAlign: "right" }}>Sales</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {voucherSalesChartData.datasets.filter(x => x.label !== "Total").map((ds, index) => {
                                                        return <tr key={index}>
                                                            <td>{ds.label}</td>
                                                            <td style={{ textAlign: "right" }}>{Utils.formatNumber(ds.data.reduce((a, b) => a + b, 0))}</td>
                                                        </tr>
                                                    })}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th>Total</th>
                                                        <th style={{ textAlign: "right" }}>{Utils.formatNumber(voucherSalesChartData.datasets.filter(x => x.label !== "Total").map(ds => ds.data).flat().reduce((a, b) => a + b, 0))}</th>
                                                    </tr>
                                                </tfoot>
                                            </Table>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6} md={12}>
                                            <LineChart title={voucherQuantityChartData.title} labels={voucherQuantityChartData.labels} datasets={voucherQuantityChartData.datasets}></LineChart>
                                        </Col>
                                        <Col lg={3} md={6}>
                                            <PieChart
                                                title={voucherQuantityChartData.title}
                                                labels={voucherQuantityChartData.datasets.filter(x => x.label !== "Total").map(ds => ds.label)}
                                                datasets={[{
                                                    label: "Quantity",
                                                    data: voucherQuantityChartData.datasets.filter(x => x.label !== "Total").map(ds => ds.data.reduce((a, b) => a + b, 0))
                                                }]}
                                            ></PieChart>
                                        </Col>
                                        <Col lg={3} md={6}>
                                            <Table responsive className="mt-2" size="sm">
                                                <thead>
                                                    <tr>
                                                        <th>{currentGroupBy}</th>
                                                        <th style={{ textAlign: "right" }}>Quantity</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {voucherQuantityChartData.datasets.filter(x => x.label !== "Total").map((ds, index) => {
                                                        return <tr key={index}>
                                                            <td>{ds.label}</td>
                                                            <td style={{ textAlign: "right" }}>{Utils.formatNumber(ds.data.reduce((a, b) => a + b, 0))}</td>
                                                        </tr>
                                                    })}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th>Total</th>
                                                        <th style={{ textAlign: "right" }}>{Utils.formatNumber(voucherQuantityChartData.datasets.filter(x => x.label !== "Total").map(ds => ds.data).flat().reduce((a, b) => a + b, 0))}</th>
                                                    </tr>
                                                </tfoot>
                                            </Table>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </ContentBody>
        </>
    )
}

export default AgencyVoucherDashboard