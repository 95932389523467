import useAuthContext from "../hooks/useAuthContext"

function Welcome() {
    const { user } = useAuthContext()

    return (
        <div className="container text-center w3-animate-zoom" style={{ paddingTop: "20%" }}>
            <h1 className="welcome"><i className="far fa-hand-paper"></i> Welcome <b>{user?.userInfo.fullname}</b>!</h1>
            <h5 className="text-white">Congratulations, you have successfully logged into the system.</h5>
        </div>
    )
}

export default Welcome