import { ChangeEvent, RefObject, SyntheticEvent, useEffect, useRef, useState } from "react"
import { Alert, Button, Col, Form, Modal, Row, Spinner, Table } from "react-bootstrap"
import EventTicketService, { IEventTicket, IEventTicketUpdate } from "../../services/EventTicketService"
import Utils from "../../utils/Utils"
import moment from "moment"
import Configs from "../../Configs"
import { PaymentServiceType } from "../../constants/PaymentServiceType"
import { PaymentStatusType } from "../../constants/PaymentStatusType"

interface IEventTicketEditModalProps {
    providerId: number | null
    eventTicketId: number | null
    show: boolean
    onClose: () => void
    onSuccess: () => void
}

function EventTicketEditModal(props: IEventTicketEditModalProps) {
    const [message, setMessage] = useState("")
    const [processing, setProcessing] = useState<boolean>(false)
    const [validatedForm, setValidatedForm] = useState(false)
    const formRef = useRef<HTMLFormElement>(null)

    const [eventTicketUpdate, setEventTicketUpdate] = useState<IEventTicketUpdate>()
    const [eventTicket, setEventTicket] = useState<IEventTicket>()

    const GetEventTicket = async () => {
        if (props.providerId && props.eventTicketId && props.show) {
            const res = await EventTicketService.get(props.providerId, props.eventTicketId)

            if (res?.isSuccess) {
                const data: IEventTicket = res.data

                setEventTicket(data)

                setEventTicketUpdate({
                    note: data.note,
                    paymentService: data.paymentService,
                    paymentStatus: data.paymentStatus,
                    customer: {
                        id: data.customer.id,
                        name: data.customer.name,
                        tel: data.customer.tel,
                        email: data.customer.email,
                        address: data.customer.address,
                        personalId: data.customer.personalId
                    }
                })
            } else {
                console.log(res?.message)
                props.onClose()
            }
        } else {
            props.onClose()
        }
    }

    useEffect(() => {
        setMessage("")
        GetEventTicket()
    }, [props.providerId, props.show])

    const handleCustomerChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (eventTicketUpdate) {
            setEventTicketUpdate({ ...eventTicketUpdate, customer: { ...eventTicketUpdate.customer, [e.target.name]: e.target.value } })
        }
    }

    const handleSave = async (e: SyntheticEvent) => {
        e.preventDefault()

        if (!processing) {
            setProcessing(true)
            if (props.providerId && props.eventTicketId && eventTicketUpdate) {
                if (formRef.current?.checkValidity()) {
                    const res = await EventTicketService.update(props.providerId, props.eventTicketId, eventTicketUpdate)

                    if (res?.isSuccess) {
                        props.onSuccess()
                        props.onClose()
                        setMessage(res.message)
                    } else {
                        setMessage(res?.message)
                    }

                } else {
                    setValidatedForm(true)
                }
            }
            setProcessing(false)
        }
    }

    return (
        <Modal show={props.show} onHide={props.onClose} backdrop="static" keyboard={false} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Edit Event Ticket</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={validatedForm} ref={formRef as RefObject<HTMLFormElement>}>
                    {eventTicket &&
                        <fieldset>
                            <legend>Ticket Details</legend>
                            <dl className="ticket-details">
                                <dt>Event:</dt><dd>{eventTicket?.event?.name || ""}</dd>
                                <dt>Date:</dt><dd>{eventTicket?.eventDate ? moment(eventTicket?.eventDate).format(Configs.DATE_FORMAT) : ""}</dd>
                                <dt>Time:</dt><dd>{eventTicket?.eventSchedule?.startTime.substring(0, 5)} - {eventTicket?.eventSchedule?.endTime.substring(0, 5)}</dd>
                                {/* <dt>Seats:</dt><dd>{eventTicket?.reservedSeats}</dd> */}
                                <dt>Amount:</dt><dd>{Utils.formatNumber(eventTicket?.totalPrice || 0)} {eventTicket?.currency}</dd>
                            </dl>
                            <Table bordered size="sm">
                                <thead>
                                    <tr>
                                        <th>Code</th>
                                        <th>Seat</th>
                                        <th>Price</th>
                                        <th>Arrived On</th>
                                        <th>Watched On</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {eventTicket.details?.map(x => <tr key={x.id}>
                                        <td>{x.code || ""}</td>
                                        <td>{x.seat?.letter || ""}{x.seat?.number || ""}</td>
                                        <td>{Utils.formatNumber(x.price || 0)}</td>
                                        <td className="text-primary">{x.arrivedOn ? moment(x.arrivedOn).format(Configs.DATE_TIME_FORMAT) : ""}</td>

                                        {(x.isWatched && x.watchedOn === null) ?
                                            <td className="text-center"><i className="fas fa-times text-danger"></i></td> :
                                            <td className="text-primary">{x.watchedOn ? moment(x.watchedOn).format(Configs.DATE_TIME_FORMAT) : ""}</td>}
                                    </tr>)}
                                </tbody>
                            </Table>
                        </fieldset>}
                    {eventTicketUpdate && <>
                        <fieldset>
                            <legend>Payment</legend>
                            <Row>
                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="paymentService">
                                        <Form.Label>Type</Form.Label>
                                        <Form.Select name="paymentService" required value={eventTicketUpdate.paymentService || ""} onChange={(e) => setEventTicketUpdate({ ...eventTicketUpdate, paymentService: e.target.value })}>
                                            <option value=""></option>
                                            <option value={PaymentServiceType.CASH}>{PaymentServiceType.CASH}</option>
                                            <option value={PaymentServiceType.BANK}>{PaymentServiceType.BANK}</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">Select a Payment Type.</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="paymentStatus">
                                        <Form.Label>Status</Form.Label>
                                        <Form.Select name="paymentStatus" required value={eventTicketUpdate.paymentStatus || ""} onChange={(e) => setEventTicketUpdate({ ...eventTicketUpdate, paymentStatus: e.target.value })}>
                                            <option value=""></option>
                                            <option value={PaymentStatusType.COMPLETED}>{PaymentStatusType.COMPLETED}</option>
                                            <option value={PaymentStatusType.PENDING}>{PaymentStatusType.PENDING}</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">Select a Payment Type.</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Form.Group controlId="note">
                                <Form.Label className="mb-0">Note</Form.Label>
                                <Form.Control as="textarea" rows={2} type="text" name="note" value={eventTicketUpdate?.note || ""} onChange={(e) => setEventTicketUpdate({ ...eventTicketUpdate, note: e.target.value })} />
                            </Form.Group>
                        </fieldset>
                        <fieldset>
                            <legend>Customer</legend>
                            <Form.Group className="mb-1" controlId="name">
                                <Form.Label className="mb-0">Name</Form.Label>
                                <Form.Control type="text" name="name" required value={eventTicketUpdate?.customer.name || ""} onChange={(e) => handleCustomerChange(e as any)} />
                                <Form.Control.Feedback type="invalid">Name is required.</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-1" controlId="tel">
                                <Form.Label className="mb-0">Tel</Form.Label>
                                <Form.Control type="text" name="tel" required value={eventTicketUpdate?.customer.tel || ""} onChange={(e) => handleCustomerChange(e as any)} />
                                <Form.Control.Feedback type="invalid">Tel is required.</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-1" controlId="email">
                                <Form.Label className="mb-0">Email</Form.Label>
                                <Form.Control type="email" name="email" value={eventTicketUpdate?.customer.email || ""} onChange={(e) => handleCustomerChange(e as any)} />
                            </Form.Group>
                            <Form.Group className="mb-1" controlId="address">
                                <Form.Label className="mb-0">Address</Form.Label>
                                <Form.Control type="text" name="address" value={eventTicketUpdate?.customer.address || ""} onChange={(e) => handleCustomerChange(e as any)} />
                            </Form.Group>
                            <Form.Group className="mb-1" controlId="personalId">
                                <Form.Label className="mb-0">Personal ID</Form.Label>
                                <Form.Control type="text" name="personalId" value={eventTicketUpdate?.customer.personalId || ""} onChange={(e) => handleCustomerChange(e as any)} />
                                <Form.Text>Priority custormer</Form.Text>
                            </Form.Group>
                        </fieldset>
                    </>}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                {message !== "" && <Alert variant="danger" onClose={() => setMessage("")} dismissible style={{ flex: "1" }}>{message}</Alert>}
                <Button variant="light" onClick={props.onClose}><i className="fas fa-times"></i> Cancel</Button>
                <Button variant="info" onClick={handleSave} disabled={processing}>
                    {processing ? <Spinner animation="border" size="sm" /> : <i className="fas fa-save"></i>} Save
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default EventTicketEditModal