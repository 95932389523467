import { Button, Card, Col, Container, Form, Row, Table } from "react-bootstrap"
import ContentBody from "../../components/ContentBody"
import useAppContext from "../../hooks/useAppContext"
import DateRangePicker from "../../components/DateRangePicker"
import { ChangeEvent, useEffect, useState } from "react"
import { addDays } from "date-fns/esm"
import VoucherService from "../../services/VoucherService"
import { DatasetChart, LineChart, PieChart } from "../../components/ChartJS"
import Utils from "../../utils/Utils"
import Configs from "../../Configs"
import DashboardDropdown from "./DashboardDropdown"
import Permissions from "../../constants/Permissions"

function DailyVoucherDashboard() {
    const { state } = useAppContext()
    const [dateReport, setDateReport] = useState<{
        startDate: Date,
        endDate: Date
    }>({
        startDate: addDays(new Date(), -Configs.DEFAULT_LAST_DAYS + 1),
        endDate: new Date()
    })

    const handleDateChange = (startDate?: Date, endDate?: Date) => {
        if (startDate && endDate) {
            setDateReport({ startDate, endDate })
        }
    }

    useEffect(() => {
        if (state.currentProviderId) {
            GetDailyActiveVouchersReport(dateReport.startDate, dateReport.endDate)
        }
    }, [state.currentProviderId, dateReport])

    const [reportData, setReportData] = useState<{
        date: Date,
        quantity: number,
        amount: number,
        paymentService: string,
        placeId: number,
        placeName: string
    }[] | null>(null)

    const GetDailyActiveVouchersReport = async (start: Date, end: Date) => {
        if (state.currentProviderId) {
            const res = await VoucherService.dailyActiveVouchersReport(state.currentProviderId, start, end)

            if (res?.isSuccess) {
                setReportData(res.data)
            } else {
                setReportData(null)
                console.log(res.message);
            }
        }
    }

    const [currentPlaceId, setCurrentPlaceId] = useState<number | null>(null)
    const [places, setPlaces] = useState<{
        id: number,
        name: string
    }[] | null>(null)

    useEffect(() => {
        if (reportData && reportData.length) {
            setCurrentPlaceId(null)
            generateVoucherQuantityChartData(null)
            generateVoucherSalesChartData(null)

            setPlaces(
                reportData
                    .map((x) => { return { id: x.placeId, name: x.placeName } })
                    .filter((value, index, self) => self.findIndex(x => x.id === value.id) === index)
            )
        } else {
            setCurrentPlaceId(null)
            setPlaces(null)
            setVoucherQuantityChartData({ ...voucherQuantityChartData, labels: [], datasets: [] })
            setVoucherSalesChartData({ ...voucherSalesChartData, labels: [], datasets: [] })
        }
    }, [reportData])

    const handlePlaceChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setCurrentPlaceId(e.target.value ? parseInt(e.target.value) : null)
    }

    useEffect(() => {
        generateVoucherQuantityChartData(currentPlaceId)
        generateVoucherSalesChartData(currentPlaceId)
    }, [currentPlaceId])

    const [voucherQuantityChartData, setVoucherQuantityChartData] = useState({
        title: "Number of Vouchers",
        labels: [] as string[],
        datasets: [] as DatasetChart[]
    })

    const generateVoucherQuantityChartData = (placeId: number | null) => {
        const labels = [] as string[]
        const datasets: DatasetChart[] = []

        if (reportData) {
            var data = reportData.filter(x => x.placeId === (placeId ? placeId : x.placeId))

            //dates
            var dates = data.map((x) => x.date).filter((value, index, self) => self.indexOf(value) === index).sort((a, b) => new Date(a).getTime() - new Date(b).getTime())

            dates.forEach(date => {
                //labels
                labels.push(Utils.dateToString(date, Configs.DATE_FORMAT))
            })

            //paymentServices
            var paymentServices = data.map((x) => x.paymentService).filter((value, index, self) => self.indexOf(value) === index)
            paymentServices.forEach(paymentService => {
                const dataset = {
                    label: paymentService,
                    data: []
                } as DatasetChart

                dates.forEach(date => {
                    var items = data.filter(x => x.paymentService === paymentService && x.date === date)

                    var quantity = 0
                    if (items) {
                        quantity = items.map(x => x.quantity).reduce((a, b) => a + b, 0)
                    }
                    dataset.data.push(quantity)
                })

                datasets.push(dataset)
            })

            //Total
            const dataset = {
                label: "Total",
                data: []
            } as DatasetChart

            dates.forEach(date => {
                var items = data.filter(x => x.date === date)

                var quantity = 0
                if (items) {
                    quantity = items.map(x => x.quantity).reduce((a, b) => a + b, 0)
                }
                dataset.data.push(quantity)
            })

            datasets.push(dataset)

            setVoucherQuantityChartData({ ...voucherQuantityChartData, labels: labels, datasets: datasets })
        } else {
            setVoucherQuantityChartData({ ...voucherQuantityChartData, labels: [], datasets: [] })
        }
    }

    const [voucherSalesChartData, setVoucherSalesChartData] = useState({
        title: "Vouchers Sales",
        labels: [] as string[],
        datasets: [] as DatasetChart[]
    })

    const generateVoucherSalesChartData = (placeId: number | null) => {
        const labels = [] as string[]
        const datasets: DatasetChart[] = []

        if (reportData) {
            var data = reportData.filter(x => x.placeId === (placeId ? placeId : x.placeId))

            //dates
            var dates = data.map((x) => x.date).filter((value, index, self) => self.indexOf(value) === index).sort((a, b) => new Date(a).getTime() - new Date(b).getTime())

            dates.forEach(d => {
                //labels
                labels.push(Utils.dateToString(d, Configs.DATE_FORMAT))
            })

            //paymentServices
            var paymentServices = data.map((x) => x.paymentService).filter((value, index, self) => self.indexOf(value) === index)
            paymentServices.forEach(paymentService => {
                const dataset = {
                    label: paymentService,
                    data: []
                } as DatasetChart

                dates.forEach(date => {
                    var items = data.filter(x => x.paymentService === paymentService && x.date === date)

                    var amount = 0
                    if (items) {
                        amount = items.map(x => x.amount).reduce((a, b) => a + b, 0)
                    }
                    dataset.data.push(amount)
                })

                datasets.push(dataset)
            })

            //Total
            const dataset = {
                label: "Total",
                data: []
            } as DatasetChart

            dates.forEach(date => {
                var items = data.filter(x => x.date === date)

                var amount = 0
                if (items) {
                    amount = items.map(x => x.amount).reduce((a, b) => a + b, 0)
                }
                dataset.data.push(amount)
            })

            datasets.push(dataset)

            setVoucherSalesChartData({ ...voucherSalesChartData, labels: labels, datasets: datasets })
        } else {
            setVoucherSalesChartData({ ...voucherSalesChartData, labels: [], datasets: [] })
        }
    }

    const handleExportExcel = () => {
        if (state.currentProviderId) {
            if (dateReport.startDate && dateReport.endDate) {
                VoucherService.exportDailyActiveVouchersReport2Excel(state.currentProviderId, dateReport.startDate, dateReport.endDate)
            }
        }
    }

    return (
        <>
            <section className="content-header">
                <Container fluid>
                    <Row className="mb-2">
                        <Col sm={12} style={{ display: "flex", alignItems: "center" }}>
                            <h1>Daily Voucher Dashboard</h1>
                            <DashboardDropdown reportName={Permissions.AllVouchers.DailyVouchersReport}></DashboardDropdown>
                        </Col>
                    </Row>
                </Container>
            </section>

            <ContentBody>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <Card.Header>
                                    <DateRangePicker startDate={dateReport.startDate} endDate={dateReport.endDate} onChange={handleDateChange} closeOnSelected={true}></DateRangePicker>
                                    <Form.Select className="ml-3" style={{ width: "auto", display: "inline-block" }} value={currentPlaceId || ""} onChange={handlePlaceChange}>
                                        <option value="">All places</option>
                                        {places && places.map(place => {
                                            return <option value={place.id} key={place.id}>{place.name}</option>
                                        })}
                                    </Form.Select>

                                    <div className="card-tools" style={{ display: "flex" }}>
                                        <Button variant="outline-info" size="sm" onClick={handleExportExcel}>
                                            <i className="fas fa-file-excel"></i> Export excel
                                        </Button>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col lg={6} md={12}>
                                            <LineChart title={voucherSalesChartData.title} labels={voucherSalesChartData.labels} datasets={voucherSalesChartData.datasets}></LineChart>
                                        </Col>
                                        <Col lg={3} md={6}>
                                            <PieChart
                                                title={voucherSalesChartData.title}
                                                labels={voucherSalesChartData.datasets.filter(x => x.label !== "Total").map(ds => ds.label)}
                                                datasets={[{
                                                    label: "Sales",
                                                    data: voucherSalesChartData.datasets.filter(x => x.label !== "Total").map(ds => ds.data.reduce((a, b) => a + b, 0))
                                                }]}
                                            ></PieChart>
                                        </Col>
                                        <Col lg={3} md={6}>
                                            <Table responsive className="mt-2" size="sm">
                                                <thead>
                                                    <tr>
                                                        <th>Payment</th>
                                                        <th style={{ textAlign: "right" }}>Sales</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {voucherSalesChartData.datasets.filter(x => x.label !== "Total").map((ds, index) => {
                                                        return <tr key={index}>
                                                            <td>{ds.label}</td>
                                                            <td style={{ textAlign: "right" }}>{Utils.formatNumber(ds.data.reduce((a, b) => a + b, 0))}</td>
                                                        </tr>
                                                    })}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th>Total</th>
                                                        <th style={{ textAlign: "right" }}>{Utils.formatNumber(voucherSalesChartData.datasets.filter(x => x.label !== "Total").map(ds => ds.data).flat().reduce((a, b) => a + b, 0))}</th>
                                                    </tr>
                                                </tfoot>
                                            </Table>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6} md={12}>
                                            <LineChart title={voucherQuantityChartData.title} labels={voucherQuantityChartData.labels} datasets={voucherQuantityChartData.datasets}></LineChart>
                                        </Col>
                                        <Col lg={3} md={6}>
                                            <PieChart
                                                title={voucherQuantityChartData.title}
                                                labels={voucherQuantityChartData.datasets.filter(x => x.label !== "Total").map(ds => ds.label)}
                                                datasets={[{
                                                    label: "Quantity",
                                                    data: voucherQuantityChartData.datasets.filter(x => x.label !== "Total").map(ds => ds.data.reduce((a, b) => a + b, 0))
                                                }]}
                                            ></PieChart>
                                        </Col>
                                        <Col lg={3} md={6}>
                                            <Table responsive className="mt-2" size="sm">
                                                <thead>
                                                    <tr>
                                                        <th>Payment</th>
                                                        <th style={{ textAlign: "right" }}>Quantity</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {voucherQuantityChartData.datasets.filter(x => x.label !== "Total").map((ds, index) => {
                                                        return <tr key={index}>
                                                            <td>{ds.label}</td>
                                                            <td style={{ textAlign: "right" }}>{Utils.formatNumber(ds.data.reduce((a, b) => a + b, 0))}</td>
                                                        </tr>
                                                    })}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th>Total</th>
                                                        <th style={{ textAlign: "right" }}>{Utils.formatNumber(voucherQuantityChartData.datasets.filter(x => x.label !== "Total").map(ds => ds.data).flat().reduce((a, b) => a + b, 0))}</th>
                                                    </tr>
                                                </tfoot>
                                            </Table>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </ContentBody>
        </>
    )
}

export default DailyVoucherDashboard