import { memo, useEffect, useState } from "react"
import { Form } from "react-bootstrap"
import CollectionService, { ICollection } from "../services/CollectionService"

interface ICollectionSelectProps {
    placeId: number | null
    value: number | null
    required?: boolean
    onChange: (e: any) => void
}

function CollectionSelect(props: ICollectionSelectProps) {
    const [placeOptions, setCollectionOptions] = useState([] as any[])

    const CollectionOptions = async (placeId: number) => {
        const res = await CollectionService.listAll(placeId)
        let options: any[] = []

        if (res?.isSuccess) {
            options = res.data.map((item: ICollection) => <option key={item.id} value={item.id || 0}>{item.name}</option>)
        } else {
            console.log(res?.message)
        }

        return options
    }

    useEffect(() => {
        if (props.placeId) {
            CollectionOptions(props.placeId).then(options => {
                setCollectionOptions(options)
            })
        }
    }, [])

    return (
        <Form.Group className="mt-3" controlId="collectionId">
            <Form.Label>Collection</Form.Label>
            <Form.Select name="collectionId" required={props.required} value={props.value || ""} onChange={props.onChange}>
                <option value=""></option>
                {placeOptions}
            </Form.Select>
            {props.required && <Form.Control.Feedback type="invalid">Select a collection.</Form.Control.Feedback>}
        </Form.Group>
    )
}

export default memo(CollectionSelect)