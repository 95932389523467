import { ChangeEvent, KeyboardEvent, RefObject, SyntheticEvent, useEffect, useRef, useState } from "react"
import { Button, Modal, Form, Table, InputGroup, Card, Container, Row, Col, Alert, Tabs, Tab, Spinner } from "react-bootstrap"
import ContentBody from "../../components/ContentBody"
import ContentHeader from "../../components/ContentHeader"
import DeleteConfirmation from "../../components/DeleteConfirmation"
import AddOrRemoveLanguages, { LanguageActionTypes } from "../../components/AddOrRemoveLanguages"
import Pagination from "../../components/Pagination"
import Configs from "../../Configs"
import useAppContext from "../../hooks/useAppContext"
import useGlobalContext from "../../hooks/useGlobalContext"
import PlaceService, { IPlace } from "../../services/PlaceService"
import OpenTimeModal from "./OpenTimeModal"
import TicketModal from "./TicketModal"
import ContactModal from "./ContactModal"
import { ILocation } from "../../constants/Common"
import AudioStoriesModal from "./AudioStoriesModal"
import AreasModal from "./AreasModal"
import CollectionsModal from "./CollectionsModal"
import GoogleMapPickerModal from "../../components/GoogleMapPickerModal"
import Utils from "../../utils/Utils"
import PlacePhotosModal from "./PlacePhotosModal"
import PlaceSettingsModal from "./PlaceSettingsModal"
import PlaceMapModal from "./PlaceMapModal"
import SubscriptionsModal from "./SubscriptionsModal"
import ToursModal from "./ToursModal"
import PlaceTypeSelect from "../../components/PlaceTypeSelect"
import useAuthContext from "../../hooks/useAuthContext"
import Permissions from "../../constants/Permissions"
import { TreeNode } from "react-dropdown-tree-select"
import "react-dropdown-tree-select/dist/styles.css"
import RegionDropdownTreeSelect from "../../components/RegionDropdownTreeSelect"
import VoucherTypesModal from "./VoucherTypesModal"

function Places() {
    const { hasPermission } = useAuthContext()
    const { state } = useAppContext()
    const { languages } = useGlobalContext()

    const [tableData, setTableData] = useState({
        data: new Array<IPlace>(),
        hasNext: false,
        hasPrevious: false,
        pageIndex: 1,
        pageSize: Configs.DEFAULT_PAGE_SIZE,
        totalPages: 0,
        totalRecords: 0
    })

    const initFormData: IPlace = {
        id: null,
        name: "",
        thumbnail: "",
        homeBGImage: "",
        introBGImage: "",
        orderNumber: null,
        price: null,
        currency: "",
        timeLimit: null,
        published: false,
        popular: false,
        useWebPImages: false,
        dynamicLink: "",
        providerId: state.currentProviderId || 0,
        locationId: null,
        lat: null,
        lng: null,
        zoom: null,
        placeTypeId: null,
        placeType: null,
        regionId: null,
        regions: [],
        translations: languages.filter(x => x.code === Configs.DEFAULT_LANGUAGE_CODE).map(x => {
            return {
                placeId: null,
                languageId: x.id || 0,
                name: "",
                description: "",
                audio: "",
                audioType: "",
                duration: null,
                isActive: false,
                language: x
            }
        })
    }

    const [message, setMessage] = useState("")
    const [messageFormModal, setMessageFormModal] = useState("")
    const [formData, setFormData] = useState(initFormData)
    const [currentPageIndex, setCurrentPageIndex] = useState(1)
    const [selectedItem, setSelectedItem] = useState({
        id: 0,
        name: ""
    })

    const keywordRef = useRef<HTMLInputElement>(null)
    const formRef = useRef<HTMLFormElement>(null)
    const [validatedForm, setValidatedForm] = useState(false)
    const [activeTabKey, setActiveTabKey] = useState(languages.find(x => x.code === Configs.DEFAULT_LANGUAGE_CODE)?.id?.toString())

    const FilterFn = async (pageIndex: number) => {
        if (state.currentProviderId) {
            let pageSize = Configs.DEFAULT_PAGE_SIZE
            let keyword = keywordRef.current?.value || ""

            const res = await PlaceService.filter(state.currentProviderId, pageIndex, pageSize, keyword)

            if (res?.isSuccess) {
                setTableData(res.data)
            } else {
                console.log(res?.message)
            }

            setCurrentPageIndex(pageIndex)
        }
    }

    useEffect(() => {
        keywordRef.current?.focus()

        FilterFn(1)
    }, [state.currentProviderId])

    const handleSearch = () => {
        FilterFn(1)
    }

    const handleSearchInputKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            FilterFn(1)
        }
    }

    const handlePageChange = (pageNumber: number) => {
        FilterFn(pageNumber)
    }

    const handleFormControlChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
        if (e.target.name === "orderNumber" || e.target.name === "timeLimit" || e.target.name === "placeTypeId") {
            setFormData({ ...formData, [e.target.name]: e.target.value ? parseInt(e.target.value) : null })
        } if (e.target.name === "price") {
            setFormData({ ...formData, [e.target.name]: e.target.value ? parseFloat(e.target.value) : null })
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value })
        }
    }

    const handleTranslateFormControlChange = (e: ChangeEvent<HTMLInputElement>, languageId: number | null) => {
        setFormData({ ...formData, translations: formData.translations.map(x => x.languageId === languageId ? { ...x, [e.target.name]: e.target.value } : x) })
    }

    const handleAddNew = () => {
        setFormData(initFormData)
        setDefaultRegionValue([])
        handleShowModal()
    }

    const handleEdit = async (id: number | null) => {
        if (state.currentProviderId && id) {
            const res = await PlaceService.get(state.currentProviderId, id)

            if (res?.isSuccess) {
                const regionId = res.data.regions[0]?.id?.toString() || ""
                setDefaultRegionValue(regionId ? [regionId] : [])

                setFormData({ ...res.data, regionId: regionId ? parseInt(regionId) : null })

                setActiveTabKey(res.data.translations[0]?.languageId?.toString())

                handleShowModal()
            } else {
                console.log(res?.message)
            }
        }
    }

    const [processing, setProcessing] = useState<boolean>(false)
    const handleSave = async (e: SyntheticEvent) => {
        e.preventDefault()

        if (!processing) {
            setProcessing(true)
            if (state.currentProviderId) {
                if (formRef.current?.checkValidity()) {
                    var frmData = new FormData()
                    if (thumbnail !== null) {
                        frmData.append("Thumbnail", thumbnail)
                    }
                    if (homeBGImage !== null) {
                        frmData.append("HomeBGImage", homeBGImage)
                    }
                    if (introBGImage !== null) {
                        frmData.append("IntroBGImage", introBGImage)
                    }
                    frmData.append("OrderNumber", formData.orderNumber ? formData.orderNumber.toString() : "")
                    frmData.append("PlaceTypeId", formData.placeTypeId ? formData.placeTypeId.toString() : "")
                    frmData.append("RegionId", formData.regionId ? formData.regionId.toString() : "")
                    frmData.append("Price", formData.price ? formData.price.toString() : "")
                    frmData.append("Currency", formData.currency)
                    frmData.append("TimeLimit", formData.timeLimit ? formData.timeLimit.toString() : "")

                    formData.translations.forEach((item, index) => {
                        if (item.placeId) {
                            frmData.append(`Translations[${index}].PlaceId`, item.placeId.toString())
                        }
                        frmData.append(`Translations[${index}].LanguageId`, item.languageId.toString())
                        frmData.append(`Translations[${index}].Name`, item.name.toString())
                        frmData.append(`Translations[${index}].Description`, item.description?.toString() || "")

                        const audio = audios.find(x => x.languageId === item.languageId)
                        if (audio && audio.file) {
                            frmData.append(`Translations[${index}].Audio`, audio.file)
                            frmData.append(`Translations[${index}].AudioType`, audio.type)
                            frmData.append(`Translations[${index}].Duration`, audio.duration.toString())
                        }
                    })

                    try {
                        if (formData.id && formData.id > 0) {
                            const res = await PlaceService.update(state.currentProviderId, formData.id, frmData)

                            if (res?.isSuccess) {
                                FilterFn(currentPageIndex)
                                handleCloseModal()
                                setMessage(res.message)
                            } else {
                                setMessageFormModal(res?.message)
                            }
                        } else {
                            const res = await PlaceService.create(state.currentProviderId, frmData)

                            if (res?.isSuccess) {
                                FilterFn(1)
                                handleCloseModal()
                                setMessage(res.message)
                            } else {
                                setMessageFormModal(res?.message)
                            }
                        }
                    } catch (error) {
                        console.log(error)
                    }
                } else {
                    setValidatedForm(true)
                }
            }
            setProcessing(false)
        }
    }

    const [showModal, setShowModal] = useState(false)
    const handleCloseModal = () => {
        setShowModal(false)
        setValidatedForm(false)
        setMessageFormModal("")
    }
    const handleShowModal = () => {
        setThumbnail(null)
        setHomeBGImage(null)
        setIntroBGImage(null)
        setAudios([])
        setMessage("")
        setShowModal(true)
    }

    const modalTitle = (formData.id || 0) > 0 ? "Edit Place" : "New Place"

    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false)

    const hideConfirmationModal = () => {
        setSelectedItem({
            id: 0,
            name: ""
        })

        setDisplayConfirmationModal(false)
    }

    const showDeleteConfirmation = (id: number | null, name: string) => {
        setSelectedItem({
            id: id || 0,
            name: name
        })

        setDisplayConfirmationModal(true)
        setMessage("")
    }

    const handleDelete = async () => {
        if (state.currentProviderId && selectedItem.id > 0) {
            const res = await PlaceService.delete(state.currentProviderId, selectedItem.id)

            if (res?.isSuccess) {
                FilterFn(currentPageIndex)
            }

            hideConfirmationModal()
            setMessage(res.message)
        }
    }

    const handlePublishedSwitch = async (id: number | null) => {
        if (state.currentProviderId && id) {
            const res = await PlaceService.changePublished(state.currentProviderId, id)

            if (res?.isSuccess) {
                FilterFn(currentPageIndex)
            } else {
                console.log(res?.message)
            }
        }
    }

    const handlePopularSwitch = async (id: number | null) => {
        if (state.currentProviderId && id) {
            const res = await PlaceService.changePopular(state.currentProviderId, id)

            if (res?.isSuccess) {
                FilterFn(currentPageIndex)
            } else {
                console.log(res?.message)
            }
        }
    }

    const handleLanguagesChange = async (languageId: number, action: LanguageActionTypes) => {
        if (action === "Add") {
            const newLang = languages.find(x => x.id === languageId)
            if (newLang) {
                if (formData.translations.filter(x => x.languageId === newLang.id).length === 0) {
                    const newFormData = { ...formData }

                    newFormData.translations.push({
                        placeId: null,
                        languageId: newLang.id || 0,
                        name: "",
                        description: "",
                        audio: "",
                        audioType: "",
                        duration: null,
                        isActive: false,
                        language: newLang
                    })

                    setFormData(newFormData)
                    setActiveTabKey(newLang.id?.toString())
                }
            }
        } else {
            const newTranslations = formData.translations.filter(x => x.languageId !== languageId)

            setFormData({ ...formData, translations: newTranslations })
            setActiveTabKey(newTranslations[0]?.languageId.toString())
        }
    }

    // thumbnail
    const [thumbnail, setThumbnail] = useState(null as any)
    const [homeBGImage, setHomeBGImage] = useState(null as any)
    const [introBGImage, setIntroBGImage] = useState(null as any)

    const handleThumbnailChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files?.length) {
            setThumbnail(e.target.files[0])
        } else {
            setThumbnail(null)
        }
    }

    const handleHomeBGImageChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files?.length) {
            setHomeBGImage(e.target.files[0])
        } else {
            setHomeBGImage(null)
        }
    }

    const handleIntroBGImageChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files?.length) {
            setIntroBGImage(e.target.files[0])
        } else {
            setIntroBGImage(null)
        }
    }

    const [audios, setAudios] = useState<Array<{
        languageId: number,
        file: File | null,
        type: string,
        duration: number
    }>>([])

    const handleAudioChange = (e: ChangeEvent<HTMLInputElement>, languageId: number) => {
        const list = audios.filter(x => x.languageId !== languageId)
        if (e.target.files?.length) {
            var file = e.target.files[0]
            var audio = new Audio()
            audio.addEventListener("loadedmetadata", () => {
                list.push({ languageId: languageId, file: file, type: file.type, duration: audio.duration })
            })
            audio.src = URL.createObjectURL(e.target.files[0])
        }
        setAudios(list)
    }

    // Location
    const [location, setLocation] = useState<ILocation>({ locationId: null, zoom: null, lat: null, lng: null })
    const [showLocationModal, setShowLocationModal] = useState(false)
    const handleCloseLocationModal = () => {
        setShowLocationModal(false)
    }
    const handleShowLocationModal = () => {
        setShowLocationModal(true)
    }

    const handleShowLocationPicker = async (id: number | null, name: string) => {
        if (state.currentProviderId && id) {
            const res = await PlaceService.get(state.currentProviderId, id)

            if (res?.isSuccess) {
                setLocation({ ...location, locationId: res.data.id, lat: res.data.lat, lng: res.data.lng, zoom: res.data.zoom })
                setSelectedItem({
                    id: id || 0,
                    name: name
                })
                handleShowLocationModal()
            } else {
                console.log(res?.message)
            }
        }
    }

    const handleZoomChanged = (zoom: number | null) => {
        setLocation({ ...location, zoom: zoom })
    }

    const handleLocationChanged = (lat: number | null, lng: number | null) => {
        setLocation({ ...location, lat: lat, lng: lng })
    }

    const handleLatLngChange = (e: ChangeEvent<HTMLInputElement>) => {
        setLocation({ ...location, [e.target.name]: e.target.value ? parseFloat(e.target.value) : null })
    }

    const handleLocationSave = async (e: SyntheticEvent) => {
        e.preventDefault()

        if (state.currentProviderId && location.locationId && location.locationId > 0) {
            try {
                const res = await PlaceService.setLocation(state.currentProviderId, location.locationId, location)

                if (res?.isSuccess) {
                    FilterFn(currentPageIndex)
                    handleCloseLocationModal()
                } else {
                    console.log(res?.message)
                }
            } catch (error) {
                console.log(error)
            }
        }
    }

    // OpenTimeModal
    const [showOpenTimeModal, setShowOpenTimeModal] = useState(false)
    const handleCloseOpenTimeModal = () => {
        setShowOpenTimeModal(false)
        setSelectedItem({
            id: 0,
            name: ""
        })
    }

    const handleShowOpenTimeModal = (id: number | null, name: string) => {
        if (id) {
            setSelectedItem({
                id: id || 0,
                name: name
            })
            setShowOpenTimeModal(true)
        }
    }

    // TicketModal
    const [showTicketModal, setShowTicketModal] = useState(false)
    const handleCloseTicketModal = () => {
        setShowTicketModal(false)
        setSelectedItem({
            id: 0,
            name: ""
        })
    }

    const handleShowTicketModal = (id: number | null, name: string) => {
        if (id) {
            setSelectedItem({
                id: id || 0,
                name: name
            })
            setShowTicketModal(true)
        }
    }

    // ContactModal
    const [showContactModal, setShowContactModal] = useState(false)
    const handleCloseContactModal = () => {
        setShowContactModal(false)
        setSelectedItem({
            id: 0,
            name: ""
        })
    }

    const handleShowContactModal = (id: number | null, name: string) => {
        if (id) {
            setSelectedItem({
                id: id || 0,
                name: name
            })
            setShowContactModal(true)
        }
    }

    // AudioStoriesModal
    const [showAudioStoriesModal, setShowAudioStoriesModal] = useState(false)
    const handleCloseAudioStoriesModal = () => {
        setShowAudioStoriesModal(false)
        setSelectedItem({
            id: 0,
            name: ""
        })
    }

    const handleShowAudioStoriesModal = (id: number | null, name: string) => {
        if (id) {
            setSelectedItem({
                id: id || 0,
                name: name
            })
            setShowAudioStoriesModal(true)
        }
    }

    // CollectionsModal
    const [showCollectionsModal, setShowCollectionsModal] = useState(false)
    const handleCloseCollectionsModal = () => {
        setShowCollectionsModal(false)
        setSelectedItem({
            id: 0,
            name: ""
        })
    }

    const handleShowCollectionsModal = (id: number | null, name: string) => {
        if (id) {
            setSelectedItem({
                id: id || 0,
                name: name
            })
            setShowCollectionsModal(true)
        }
    }

    // AreasModal
    const [showAreasModal, setShowAreasModal] = useState(false)
    const handleCloseAreasModal = () => {
        setShowAreasModal(false)
        setSelectedItem({
            id: 0,
            name: ""
        })
    }

    const handleShowAreasModal = (id: number | null, name: string) => {
        if (id) {
            setSelectedItem({
                id: id || 0,
                name: name
            })
            setShowAreasModal(true)
        }
    }

    // PlacePhotos
    const [showPlacePhotosModal, setShowPlacePhotosModal] = useState(false)
    const handleClosePlacePhotosModal = () => {
        setShowPlacePhotosModal(false)
        setSelectedItem({
            id: 0,
            name: ""
        })
    }

    const handleShowPlacePhotosModal = (id: number | null, name: string) => {
        if (id) {
            setSelectedItem({
                id: id || 0,
                name: name
            })
            setShowPlacePhotosModal(true)
        }
    }

    //Settings
    const [showSettingsModal, setShowSettingsModal] = useState(false)
    const handleCloseSettingsModal = () => {
        setShowSettingsModal(false)
        setSelectedItem({
            id: 0,
            name: ""
        })
    }

    const handleShowSettingsModal = (id: number | null, name: string) => {
        if (id) {
            setSelectedItem({
                id: id || 0,
                name: name
            })
            setShowSettingsModal(true)
        }
    }

    //PlaceMap
    const [showPlaceMapModal, setShowPlaceMapModal] = useState(false)
    const handleClosePlaceMapModal = () => {
        setShowPlaceMapModal(false)
        setSelectedItem({
            id: 0,
            name: ""
        })
    }

    const handleShowPlaceMapModal = (id: number | null, name: string) => {
        if (id) {
            setSelectedItem({
                id: id || 0,
                name: name
            })
            setShowPlaceMapModal(true)
        }
    }

    //Subscriptions
    const [showSubscriptionsModal, setShowSubscriptionsModal] = useState(false)
    const handleCloseSubscriptionsModal = () => {
        setShowSubscriptionsModal(false)
        setSelectedItem({
            id: 0,
            name: ""
        })
    }

    const handleShowSubscriptionsModal = (id: number | null, name: string) => {
        if (id) {
            setSelectedItem({
                id: id || 0,
                name: name
            })
            setShowSubscriptionsModal(true)
        }
    }

    //VoucherTypes
    const [showVoucherTypesModal, setShowVoucherTypesModal] = useState(false)
    const handleCloseVoucherTypesModal = () => {
        setShowVoucherTypesModal(false)
        setSelectedItem({
            id: 0,
            name: ""
        })
    }

    const handleShowVoucherTypesModal = (id: number | null, name: string) => {
        if (id) {
            setSelectedItem({
                id: id || 0,
                name: name
            })
            setShowVoucherTypesModal(true)
        }
    }

    //Tours
    const [showToursModal, setShowToursModal] = useState(false)
    const handleCloseToursModal = () => {
        setShowToursModal(false)
        setSelectedItem({
            id: 0,
            name: ""
        })
    }

    const handleShowToursModal = (id: number | null, name: string) => {
        if (id) {
            setSelectedItem({
                id: id || 0,
                name: name
            })
            setShowToursModal(true)
        }
    }

    //Regions
    const [defaultRegionValue, setDefaultRegionValue] = useState<Array<string>>([])

    const handleRegionChange = (currentNode: TreeNode, selectedNodes: TreeNode[]) => {
        const selectedValue = selectedNodes.map(x => x.value)[0] || ""
        setDefaultRegionValue(selectedValue ? [selectedValue] : [])
        setFormData({ ...formData, regionId: selectedValue ? parseInt(selectedValue) : null })
    }

    return (
        <>
            <ContentHeader title="Places" />

            <ContentBody>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <Card.Header>
                                    {hasPermission(Permissions.Places.Create) &&
                                        <Button variant="info" size="sm" onClick={handleAddNew}>
                                            <i className="fas fa-plus"></i> Add new
                                        </Button>
                                    }
                                    <div className="card-tools">
                                        <InputGroup size="sm">
                                            <Form.Control
                                                type="search"
                                                placeholder="Search"
                                                ref={keywordRef as RefObject<HTMLInputElement>}
                                                onKeyUp={handleSearchInputKeyPress}
                                            />
                                            <Button variant="info" size="sm" onClick={handleSearch}>
                                                <i className="fas fa-search"></i>
                                            </Button>
                                        </InputGroup>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    {message !== "" && <Alert variant="info" onClose={() => setMessage("")} dismissible>{message}</Alert>}
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th style={{ textAlign: "center", width: "160px" }}>Thumbnail</th>
                                                <th>Name</th>
                                                <th>Type</th>
                                                <th>Order Number</th>
                                                <th style={{ textAlign: "center", width: "100px" }}>Status</th>
                                                <th style={{ textAlign: "center", width: "80px" }}>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                tableData.data.map(item =>
                                                    <tr key={item.id}>
                                                        <td>{item.thumbnail && <img src={`${Configs.API_BASE_URL}${item.thumbnail}`} style={{ maxWidth: "100%" }} alt={item.name} />}</td>
                                                        <td>
                                                            <h6>#{item.id}. {item.name} {item.price && <span className="text-danger">{Utils.formatNumber(item.price)} {item.currency}</span>}{item.price && ((item.timeLimit || 0) > 0) ? <span className="text-muted">/{item.timeLimit} hours</span> : <span className="text-muted">/Unlimited</span>}</h6>
                                                            {item.regions.length > 0 && <p className="text-muted" style={{ fontSize: "11px", marginBottom: "0.5rem" }}>
                                                                <i className="fas fa-map-marker-alt"></i> {item.regions.map(x => x.name).join(", ")}
                                                            </p>}
                                                            {hasPermission(Permissions.Places.Edit) && <>
                                                                <Button variant="default" size="sm" onClick={() => handleShowLocationPicker(item.id, item.name)}>
                                                                    <i className="fas fa-map-marked-alt text-info"></i> <span className="text-muted" style={{ fontSize: "11px" }}>{item.lat && item.lng ? <>{item.lat}, {item.lng}</> : <>Location</>}</span>
                                                                </Button>
                                                                <hr />
                                                            </>
                                                            }
                                                            {hasPermission(Permissions.OpenTimes.View) &&
                                                                <Button variant="default" size="sm" onClick={() => handleShowOpenTimeModal(item.id, item.name)}>
                                                                    <i className="far fa-clock text-info"></i> <span className="text-muted" style={{ fontSize: "11px" }}>Open time</span>
                                                                </Button>
                                                            }
                                                            {hasPermission(Permissions.Tickets.View) &&
                                                                <Button variant="default" size="sm" onClick={() => handleShowTicketModal(item.id, item.name)}>
                                                                    <i className="fas fa-ticket-alt text-info"></i> <span className="text-muted" style={{ fontSize: "11px" }}>Ticket</span>
                                                                </Button>
                                                            }
                                                            {hasPermission(Permissions.Contacts.View) &&
                                                                <Button variant="default" size="sm" onClick={() => handleShowContactModal(item.id, item.name)}>
                                                                    <i className="far fa-address-card text-info"></i> <span className="text-muted" style={{ fontSize: "11px" }}>Contact</span>
                                                                </Button>
                                                            }
                                                            {hasPermission(Permissions.Areas.View) &&
                                                                <Button variant="default" size="sm" onClick={() => handleShowAreasModal(item.id, item.name)}>
                                                                    <i className="fas fa-kaaba text-info"></i> <span className="text-muted" style={{ fontSize: "11px" }}>Areas</span>
                                                                </Button>
                                                            }
                                                            {hasPermission(Permissions.Collections.View) &&
                                                                <Button variant="default" size="sm" onClick={() => handleShowCollectionsModal(item.id, item.name)}>
                                                                    <i className="fas fa-tags text-info"></i> <span className="text-muted" style={{ fontSize: "11px" }}>Collections</span>
                                                                </Button>
                                                            }
                                                            {hasPermission(Permissions.Tours.View) &&
                                                                <Button variant="default" size="sm" onClick={() => handleShowToursModal(item.id, item.name)}>
                                                                    <i className="far fa-play-circle text-info"></i> <span className="text-muted" style={{ fontSize: "11px" }}>Tours</span>
                                                                </Button>
                                                            }
                                                            {hasPermission(Permissions.AudioStories.View) &&
                                                                <Button variant="default" size="sm" onClick={() => handleShowAudioStoriesModal(item.id, item.name)}>
                                                                    <i className="fas fa-headphones-alt text-info"></i> <span className="text-muted" style={{ fontSize: "11px" }}>Audio Stories</span>
                                                                </Button>
                                                            }
                                                            {hasPermission(Permissions.PlacePhotos.View) &&
                                                                <Button variant="default" size="sm" onClick={() => handleShowPlacePhotosModal(item.id, item.name)}>
                                                                    <i className="far fa-images text-info"></i> <span className="text-muted" style={{ fontSize: "11px" }}>Photos</span>
                                                                </Button>
                                                            }
                                                            {hasPermission(Permissions.PlaceMaps.View) &&
                                                                <Button variant="default" size="sm" onClick={() => handleShowPlaceMapModal(item.id, item.name)}>
                                                                    <i className="fas fa-map text-info"></i> <span className="text-muted" style={{ fontSize: "11px" }}>Map</span>
                                                                </Button>
                                                            }
                                                            {hasPermission(Permissions.Subscriptions.View) &&
                                                                <Button variant="default" size="sm" onClick={() => handleShowSubscriptionsModal(item.id, item.name)}>
                                                                    <i className="fas fa-shopping-cart text-info"></i> <span className="text-muted" style={{ fontSize: "11px" }}>Subscriptions</span>
                                                                </Button>
                                                            }
                                                            {hasPermission(Permissions.VoucherTypes.View) &&
                                                                <Button variant="default" size="sm" onClick={() => handleShowVoucherTypesModal(item.id, item.name)}>
                                                                    <i className="fas fa-ticket-alt text-info"></i> <span className="text-muted" style={{ fontSize: "11px" }}>Voucher Types</span>
                                                                </Button>
                                                            }
                                                            {hasPermission(Permissions.Places.Custom) &&
                                                                <Button variant="default" size="sm" onClick={() => handleShowSettingsModal(item.id, item.name)}>
                                                                    <i className="fas fa-cog text-info"></i> <span className="text-muted" style={{ fontSize: "11px" }}>Settings</span>
                                                                </Button>
                                                            }
                                                        </td>
                                                        <td>{item.placeType?.name}</td>
                                                        <td>{item.orderNumber}</td>
                                                        <td>
                                                            <Form.Check type="switch" id={"published_" + item.id} checked={item.published} onChange={() => handlePublishedSwitch(item.id)} label="Published" disabled={!hasPermission(Permissions.Places.Edit)} />
                                                            <Form.Check type="switch" id={"popular_" + item.id} checked={item.popular} onChange={() => handlePopularSwitch(item.id)} label="Popular" disabled={!hasPermission(Permissions.Places.Edit)} />
                                                        </td>
                                                        <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                                                            {hasPermission(Permissions.Places.Edit) &&
                                                                <Button variant="warning" size="sm" className="mr-1" onClick={() => handleEdit(item.id)}><i className="fas fa-pencil-alt"></i> Edit</Button>
                                                            }
                                                            {hasPermission(Permissions.Places.Delete) &&
                                                                <Button variant="danger" size="sm" onClick={() => showDeleteConfirmation(item.id, item.name)}><i className="fas fa-trash-alt"></i> Delete</Button>
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </Card.Body>
                                <Card.Footer>
                                    <Pagination hasNext={tableData.hasNext} hasPrevious={tableData.hasPrevious} pageIndex={tableData.pageIndex} pageSize={tableData.pageSize} totalPages={tableData.totalPages} totalRecords={tableData.totalRecords} handlePageChange={handlePageChange} />
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </ContentBody>

            <Modal show={showModal} onHide={handleCloseModal} backdrop="static" keyboard={false} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddOrRemoveLanguages selectedLanguages={formData.translations.map(x => x.language)} onChange={handleLanguagesChange}></AddOrRemoveLanguages>
                    <Form noValidate validated={validatedForm} ref={formRef as RefObject<HTMLFormElement>}>
                        <Tabs defaultActiveKey="thumbnail">
                            <Tab eventKey="thumbnail" title="Thumbnail">
                                <Form.Group controlId="thumbnailFile">
                                    <Card className="m-0 border-0 shadow-0">
                                        {formData.thumbnail && <Card.Img variant="top" src={`${Configs.API_BASE_URL}${formData.thumbnail}`} style={{ width: "inherit", maxWidth: "300px", margin: "auto" }} />}
                                        <Card.Body className="p-2">
                                            <Form.Control type="file" onChange={handleThumbnailChange} accept={Configs.IMAGE_EXTENSION} />
                                        </Card.Body>
                                    </Card>
                                </Form.Group>
                            </Tab>
                            <Tab eventKey="homeBGImage" title="Home Image">
                                <Form.Group controlId="homeBGImageFile">
                                    <Card className="m-0 border-0 shadow-0">
                                        {formData.homeBGImage && <Card.Img variant="top" src={`${Configs.API_BASE_URL}${formData.homeBGImage}`} style={{ width: "inherit", maxHeight: "300px", margin: "auto" }} />}
                                        <Card.Body className="p-2">
                                            <Form.Control type="file" onChange={handleHomeBGImageChange} accept={Configs.IMAGE_EXTENSION} />
                                        </Card.Body>
                                    </Card>
                                </Form.Group>
                            </Tab>
                            <Tab eventKey="introBGImage" title="Intro Image">
                                <Form.Group controlId="introBGImage">
                                    <Card className="m-0 border-0 shadow-0">
                                        {formData.introBGImage && <Card.Img variant="top" src={`${Configs.API_BASE_URL}${formData.introBGImage}`} style={{ width: "inherit", maxHeight: "300px", margin: "auto" }} />}
                                        <Card.Body className="p-2">
                                            <Form.Control type="file" onChange={handleIntroBGImageChange} accept={Configs.IMAGE_EXTENSION} />
                                        </Card.Body>
                                    </Card>
                                </Form.Group>
                            </Tab>
                        </Tabs>
                        <Tabs activeKey={activeTabKey} onSelect={(k) => setActiveTabKey(k || undefined)}>
                            {
                                formData.translations.sort((a, b) => { return (a.language.orderNumber || 0) - (b.language.orderNumber || 0) }).map(translate => {
                                    return (
                                        <Tab
                                            key={translate.languageId}
                                            eventKey={(translate.languageId).toString()}
                                            title={translate.language.name}
                                            className="p-3"
                                        >
                                            <Form.Group className="mb-3" controlId={"name_" + translate.languageId}>
                                                <Form.Label>Name</Form.Label>
                                                <Form.Control as="textarea" rows={2} name="name" required value={translate?.name || ""} onChange={(e) => handleTranslateFormControlChange(e as any, translate.languageId)} />
                                                <Form.Control.Feedback type="invalid">Name is required.</Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group controlId={"description_" + translate.languageId}>
                                                <Form.Label>Description</Form.Label>
                                                <Form.Control as="textarea" rows={7} type="text" name="description" value={translate?.description || ""} onChange={(e) => handleTranslateFormControlChange(e as any, translate.languageId)} />
                                            </Form.Group>
                                            <Form.Group controlId="audioFile" className="mt-3">
                                                <Form.Label>Audio</Form.Label>
                                                <Card className="shadow-0">
                                                    {translate.audio && <audio controls style={{ margin: "auto" }}>
                                                        <source src={`${Configs.API_BASE_URL}${translate.audio}`} type={translate.audioType} />
                                                        Your browser does not support the audio tag.
                                                    </audio>}
                                                    <Card.Body className="p-2">
                                                        <Form.Control type="file" accept={Configs.AUDIO_EXTENSION} onChange={(e: ChangeEvent<HTMLInputElement>) => handleAudioChange(e, translate.languageId)} />
                                                    </Card.Body>
                                                </Card>
                                            </Form.Group>
                                        </Tab>
                                    )
                                })
                            }
                        </Tabs>
                        <RegionDropdownTreeSelect value={defaultRegionValue} onChange={handleRegionChange}></RegionDropdownTreeSelect>
                        <PlaceTypeSelect value={formData.placeTypeId} onChange={handleFormControlChange}></PlaceTypeSelect>
                        <InputGroup className="mb-3 mt-4">
                            <InputGroup.Text>Price</InputGroup.Text>
                            <Form.Control type="number" min={0} name="price" value={formData.price || ""} onChange={(e) => handleFormControlChange(e as any)} />
                            <InputGroup.Text>Currency</InputGroup.Text>
                            <Form.Select name="currency" value={formData.currency || ""} onChange={(e) => handleFormControlChange(e as any)}>
                                <option value=""></option>
                                <option value="VND">VND</option>
                                <option value="USD">USD</option>
                            </Form.Select>
                            <InputGroup.Text>Time Limit</InputGroup.Text>
                            <Form.Control type="number" min={0} name="timeLimit" value={formData.timeLimit || ""} onChange={(e) => handleFormControlChange(e as any)} />
                            <InputGroup.Text>Hour(s)</InputGroup.Text>
                        </InputGroup>
                        <Form.Group className="mt-3" controlId="orderNumber">
                            <Form.Label>Order Number</Form.Label>
                            <Form.Control type="number" name="orderNumber" value={formData.orderNumber || ""} onChange={(e) => handleFormControlChange(e as any)} />
                        </Form.Group>
                    </Form>
                    {messageFormModal !== "" && <Alert variant="danger" onClose={() => setMessageFormModal("")} dismissible>{messageFormModal}</Alert>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleCloseModal}><i className="fas fa-times"></i> Cancel</Button>
                    <Button variant="info" onClick={handleSave} disabled={processing}>
                        {processing ? <><Spinner animation="border" size="sm" /> Saving...</> : <><i className="fas fa-save"></i> Save</>}
                    </Button>
                </Modal.Footer>
            </Modal>

            <DeleteConfirmation show={displayConfirmationModal} onConfirm={handleDelete} onHide={hideConfirmationModal} id={selectedItem.id} name={selectedItem.name} />

            <PlacePhotosModal placeId={selectedItem.id} title={selectedItem.name} show={showPlacePhotosModal} onClose={handleClosePlacePhotosModal}></PlacePhotosModal>
            <PlaceSettingsModal placeId={selectedItem.id} title={selectedItem.name} show={showSettingsModal} onClose={handleCloseSettingsModal}></PlaceSettingsModal>
            <OpenTimeModal placeId={selectedItem.id} title={selectedItem.name} show={showOpenTimeModal} onClose={handleCloseOpenTimeModal}></OpenTimeModal>
            <TicketModal placeId={selectedItem.id} title={selectedItem.name} show={showTicketModal} onClose={handleCloseTicketModal}></TicketModal>
            <ContactModal placeId={selectedItem.id} title={selectedItem.name} show={showContactModal} onClose={handleCloseContactModal}></ContactModal>
            <AudioStoriesModal placeId={selectedItem.id} title={selectedItem.name} show={showAudioStoriesModal} onClose={handleCloseAudioStoriesModal}></AudioStoriesModal>
            <AreasModal placeId={selectedItem.id} title={selectedItem.name} show={showAreasModal} onClose={handleCloseAreasModal}></AreasModal>
            <CollectionsModal placeId={selectedItem.id} title={selectedItem.name} show={showCollectionsModal} onClose={handleCloseCollectionsModal}></CollectionsModal>
            <GoogleMapPickerModal defaultZoom={location.zoom} defaultCenter={{ lat: location.lat, lng: location.lng }} title={selectedItem.name} show={showLocationModal} onClose={handleCloseLocationModal} onSave={handleLocationSave} onLocationChanged={handleLocationChanged} onLatLngChange={handleLatLngChange} onZoomChanged={handleZoomChanged}></GoogleMapPickerModal>
            <PlaceMapModal placeId={selectedItem.id} title={selectedItem.name} show={showPlaceMapModal} onClose={handleClosePlaceMapModal}></PlaceMapModal>
            <SubscriptionsModal placeId={selectedItem.id} title={selectedItem.name} show={showSubscriptionsModal} onClose={handleCloseSubscriptionsModal}></SubscriptionsModal>
            <VoucherTypesModal placeId={selectedItem.id} title={selectedItem.name} show={showVoucherTypesModal} onClose={handleCloseVoucherTypesModal}></VoucherTypesModal>
            <ToursModal placeId={selectedItem.id} title={selectedItem.name} show={showToursModal} onClose={handleCloseToursModal}></ToursModal>
        </>
    )
}

export default Places