import { addDays } from "date-fns/esm";
import { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap"
import DateRangePicker from "../../components/DateRangePicker";
import VoucherService from "../../services/VoucherService";
import useAppContext from "../../hooks/useAppContext";
import { DatasetChart, LineChart } from "../../components/ChartJS";
import Utils from "../../utils/Utils";
import Configs from "../../Configs";

function MyVoucherDashboardModal(props: {
    show: boolean,
    onClose: () => void
}) {
    const { state } = useAppContext()
    const [dateReport, setDateReport] = useState<{
        startDate: Date,
        endDate: Date
    }>({
        startDate: addDays(new Date(), -Configs.DEFAULT_LAST_DAYS + 1),
        endDate: new Date()
    })

    const handleDateChange = (startDate?: Date, endDate?: Date) => {
        if (startDate && endDate) {
            setDateReport({ startDate, endDate })
        }
    }

    useEffect(() => {
        if (props.show) {
            DailyActiveMyVouchersReport(dateReport.startDate, dateReport.endDate)
        }
    }, [props.show, dateReport])

    const DailyActiveMyVouchersReport = async (start: Date, end: Date) => {
        if (state.currentProviderId) {
            const res = await VoucherService.dailyActiveMyVouchersReport(state.currentProviderId, start, end)

            if (res?.isSuccess) {
                generateVoucherQuantityChartData(res.data)
                generateVoucherSalesChartData(res.data)
            } else {
                console.log(res.message);
            }
        }
    }

    const [voucherQuantityChartData, setVoucherQuantityChartData] = useState({
        title: "Number of Vouchers",
        labels: [] as string[],
        datasets: [] as DatasetChart[]
    })

    const generateVoucherQuantityChartData = (source: any) => {
        const data = source as {
            date: Date,
            quantity: number,
            amount: number
        }[]

        const labels = [] as string[]
        const dataset = {
            label: "Quantity",
            data: []
        } as DatasetChart

        data.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()).forEach(item => {
            labels.push(Utils.dateToString(item.date, Configs.DATE_FORMAT))
            dataset.data.push(item.quantity)
        })

        var sum = dataset.data.reduce((a, b) => a + b, 0)
        var title = `Number of Vouchers (Total: ${Utils.formatNumber(sum)})`

        setVoucherQuantityChartData({ ...voucherQuantityChartData, title: title, labels: labels, datasets: [dataset] })
    }

    const [voucherSalesChartData, setVoucherSalesChartData] = useState({
        title: "Vouchers Sales",
        labels: [] as string[],
        datasets: [] as DatasetChart[]
    })

    const generateVoucherSalesChartData = (source: any) => {
        const data = source as {
            date: Date,
            quantity: number,
            amount: number
        }[]

        const labels = [] as string[]
        const dataset = {
            label: "Amount",
            data: []
        } as DatasetChart

        data.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()).forEach(item => {
            labels.push(Utils.dateToString(item.date, Configs.DATE_FORMAT))
            dataset.data.push(item.amount)
        })

        var sum = dataset.data.reduce((a, b) => a + b, 0)
        var title = `Vouchers Sales (Total: ${Utils.formatNumber(sum)})`

        setVoucherSalesChartData({ ...voucherSalesChartData, title: title, labels: labels, datasets: [dataset] })
    }

    return (
        <Modal show={props.show} onHide={props.onClose} backdrop="static" keyboard={false} size="xl">
            <Modal.Header closeButton className="p-2">
                <Modal.Title><i className="fas fa-tachometer-alt"></i> My Voucher Dashboard</Modal.Title>
            </Modal.Header>
            <Modal.Header className="p-2">
                <DateRangePicker startDate={dateReport.startDate} endDate={dateReport.endDate} onChange={handleDateChange} closeOnSelected={true}></DateRangePicker>
            </Modal.Header>
            <Modal.Body className="p-2">
                <Row>
                    <Col lg={12}>
                        <LineChart title={voucherSalesChartData.title} labels={voucherSalesChartData.labels} datasets={voucherSalesChartData.datasets}></LineChart>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <LineChart title={voucherQuantityChartData.title} labels={voucherQuantityChartData.labels} datasets={voucherQuantityChartData.datasets}></LineChart>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default MyVoucherDashboardModal