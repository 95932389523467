import fileDownload from "js-file-download"
import { ISortField } from "../components/SortBy"
import Utils from "../utils/Utils"
import { IEvent, IEventSchedule } from "./EventService"
import { ISeat } from "./SeatService"
import axiosClient, { ApiResponse, axiosCustomError } from "./axiosClient"
import axiosClientBlob from "./axiosClientBlob"
import { format } from "date-fns/esm"

export const EventTicketSortFields: ISortField[] = [
    {
        name: "createdOn",
        description: "Created On"
    },
    {
        name: "eventDate",
        description: "Event Date"
    },
    {
        name: "paymentService",
        description: "Payment Service"
    },
    {
        name: "paymentStatus",
        description: "Payment Status"
    }
]

export interface IEventTicket {
    id: number | null
    eventId: number | null
    event: IEvent | null
    eventDate: string | null
    eventScheduleId: number | null
    eventSchedule: IEventSchedule | null
    note: string
    paymentService: string
    paymentStatus: string
    customer: ICustomer
    quantity: number | null
    seatIds: number[]
    details: IEventTicketDetails[] | null
    createdOn: Date | null
    reservedSeats: string
    totalPrice: number | null
    currency: string
}

export interface IEventTicketDetails {
    id: number | null
    code: string
    seatId: number | null
    seat: ISeat | null
    price: number | null
    currency: string
    isArrived: boolean
    arrivedOn: Date | null
    isWatched: boolean
    watchedOn: Date | null
}

export interface ICustomer {
    id: number | null
    name: string
    tel: string
    email: string
    address: string
    personalId: string
}

export interface IEventTicketCreate {
    eventId: number | null
    eventDate: string | null
    eventScheduleId: number | null
    note: string
    paymentService: string
    paymentStatus: string
    customer: ICustomer
    seatIds: number[]
    quantity: number | null
}

export interface IEventTicketUpdate {
    note: string
    paymentService: string
    paymentStatus: string
    customer: ICustomer
}

export interface IReservedSeatFilter {
    eventId: number
    eventDate: string
    eventScheduleId: number
}

export interface IEventTicketPrint {
    eventTicketId: number
    createdOn: Date | null
    eventName: string
    eventDate: string
    eventStartTime: string
    eventEndTime: string
    thumbnail: string
    tel: string
    email: string
    website: string
    address: string
    price: number | null
    currency: string
    seat: string
    code: string
    qrcodeImage: string
}

export default class EventTicketService {
    //Provider
    static async filter(providerId: number, pageIndex: number, pageSize: number, keyword: string, sortBy: string, sortDirection: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/EventTickets/${providerId}?keyword=${keyword}&pageSize=${pageSize}&pageIndex=${pageIndex}&sortBy=${sortBy}&sortDirection=${sortDirection}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async get(providerId: number, id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/EventTickets/${providerId}/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async delete(providerId: number, id: number) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/EventTickets/${providerId}/${id}`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async create(providerId: number, itemData: IEventTicketCreate) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/EventTickets/${providerId}/Create`, itemData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async update(providerId: number, id: number, itemData: IEventTicketUpdate) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/EventTickets/${providerId}/${id}`, itemData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async getReservedSeats(providerId: number, filter: IReservedSeatFilter) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/EventTickets/${providerId}/GetReservedSeats`, filter)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static generatePDF(providerId: number, id: number) {
        try {
            axiosClientBlob.post(`/api/EventTickets/${providerId}/GeneratePDF/${id}`).then(response => {
                const contentDisposition = response.headers["content-disposition"]
                var filename = Utils.getFileNameUTF8FromContentDisposition(contentDisposition)
                fileDownload(response.data, filename)
            }).catch(error => {
                console.error(error)
            })
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async getForPrint(providerId: number, id: number) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/EventTickets/${providerId}/GetForPrint/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async sendEmail(providerId: number, id: number) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/EventTickets/${providerId}/SendEmail/${id}`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    //Users
    static async filterByUser(providerId: number, pageIndex: number, pageSize: number, keyword: string, sortBy: string, sortDirection: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/EventTickets/${providerId}/ListByUser?keyword=${keyword}&pageSize=${pageSize}&pageIndex=${pageIndex}&sortBy=${sortBy}&sortDirection=${sortDirection}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async getByUser(providerId: number, id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/EventTickets/${providerId}/GetByIdForUser/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async deleteByUser(providerId: number, id: number) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/EventTickets/${providerId}/DeleteByUser/${id}`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async createByUser(providerId: number, itemData: IEventTicketCreate) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/EventTickets/${providerId}/CreateByUser`, itemData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async updateByUser(providerId: number, id: number, itemData: IEventTicketUpdate) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/EventTickets/${providerId}/UpdateByUser/${id}`, itemData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static generatePDFByUser(providerId: number, id: number) {
        try {
            axiosClientBlob.post(`/api/EventTickets/${providerId}/GeneratePDFByUser/${id}`).then(response => {
                const contentDisposition = response.headers["content-disposition"]
                var filename = Utils.getFileNameUTF8FromContentDisposition(contentDisposition)
                fileDownload(response.data, filename)
            }).catch(error => {
                console.error(error)
            })
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async getForPrintByUser(providerId: number, id: number) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/EventTickets/${providerId}/GetForPrintByUser/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async sendEmailByUser(providerId: number, id: number) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/EventTickets/${providerId}/SendEmailByUser/${id}`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    //Report
    static async dailyMyEventTicketsReport(providerId: number, startDate: Date, endDate: Date) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/EventTickets/${providerId}/DailyMyEventTicketsReport`, {
                startDate: format(startDate, "yyyy-MM-dd"),
                endDate: format(endDate, "yyyy-MM-dd")
            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async dailyEventTicketsReport(providerId: number, startDate: Date, endDate: Date) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/EventTickets/${providerId}/DailyEventTicketsReport`, {
                startDate: format(startDate, "yyyy-MM-dd"),
                endDate: format(endDate, "yyyy-MM-dd")
            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async monthlyEventTicketsReport(providerId: number, startYear: number, startMonth: number, endYear: number, endMonth: number) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/EventTickets/${providerId}/MonthlyEventTicketsReport`, {
                startYear: startYear,
                startMonth: startMonth,
                endYear: endYear,
                endMonth: endMonth
            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }
}