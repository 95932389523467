import axiosClient, { ApiResponse, axiosCustomError } from "./axiosClient"

export interface IPlaceMap {
    id: number | null
    map: string
    centerLat: number | null
    centerLng: number | null
    zoom: number | null
    minZoom: number | null
    maxZoom: number | null
    swPanBoundaryLat: number | null
    swPanBoundaryLng: number | null
    nePanBoundaryLat: number | null
    nePanBoundaryLng: number | null
    placeId: number | null
}

export default class PlaceMapService {
    static async get(placeId: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/PlaceMaps/${placeId}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async update(placeId: number, formData: FormData) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/PlaceMaps/${placeId}`, formData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }
}