import { RefObject, SyntheticEvent, useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"
import AccountService from "../services/AccountService"
import RoutePath from "../constants/RoutePath"
import { Alert, Button, Form, InputGroup, Spinner } from "react-bootstrap"

function ForgotPassword() {
    const [message, setMessage] = useState("")
    const [email, setEmail] = useState("")

    const emailRef = useRef<HTMLInputElement>(null)
    const formRef = useRef<HTMLFormElement>(null)
    const [validatedForm, setValidatedForm] = useState(false)

    useEffect(() => {
        emailRef.current?.focus()
    }, [])

    const [isLoading, setIsLoading] = useState(false)
    const [isHideForm, setIsHideForm] = useState(false)
    const handleSubmit = async (e: SyntheticEvent) => {
        e.preventDefault()
        if (!isLoading) {
            setIsLoading(true)
            try {
                if (formRef.current?.checkValidity()) {
                    const res = await AccountService.forgotPassword(email)

                    if (res?.isSuccess) {
                        setMessage(res.message)
                        setIsHideForm(true)
                    } else {
                        setMessage(res?.message)
                    }
                } else {
                    setValidatedForm(true)
                }
            } catch (error) {
                console.log(error)
            }
            setIsLoading(false)
        }
    }

    return (
        <div className="card max-w-400">
            <div className="card-body">
                <p className="login-box-msg">
                    Please set me up with a new password
                    <small style={{ display: "block", marginTop: "20px" }}>
                        To reset your password, enter your email address and we'll send you an email with instructions.
                    </small>
                </p>
                {message !== "" && <Alert variant="info" onClose={() => setMessage("")} dismissible>{message}</Alert>}
                {!isHideForm &&
                    <Form noValidate validated={validatedForm} ref={formRef as RefObject<HTMLFormElement>}>
                        <InputGroup className="mb-3">
                            <InputGroup.Text><i className="fas fa-envelope"></i></InputGroup.Text>
                            <Form.Control type="email" required autoComplete="off" placeholder="Email" ref={emailRef} value={email} onChange={(e) => setEmail(e.target.value)} style={{ borderTopRightRadius: ".375rem", borderBottomRightRadius: ".375rem" }} />
                            <Form.Control.Feedback type="invalid">Email is required.</Form.Control.Feedback>
                        </InputGroup>
                        <div className="text-center">
                            <Button variant="primary" onClick={handleSubmit}>
                                {isLoading ? <><Spinner animation="border" size="sm" /> Sending...</> : <>Request new password</>}
                            </Button>
                        </div>
                    </Form>
                }
                <p className="mt-3 mb-1">
                    <Link to={RoutePath.AUTH.LOGIN}>Login</Link>
                </p>
                <p className="mb-0">
                    <Link to={RoutePath.AUTH.REGISTER} className="text-center">Register a new membership</Link>
                </p>
            </div>
        </div>
    )
}

export default ForgotPassword