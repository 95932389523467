import { Card, Col, Container, Form, Row } from "react-bootstrap"
import ContentBody from "../../components/ContentBody"
import useAppContext from "../../hooks/useAppContext"
import DateRangePicker from "../../components/DateRangePicker"
import { ChangeEvent, useEffect, useState } from "react"
import { addDays } from "date-fns/esm"
import VoucherService from "../../services/VoucherService"
import { BarChart, DatasetChart } from "../../components/ChartJS"
import Configs from "../../Configs"
import DashboardDropdown from "./DashboardDropdown"
import Permissions from "../../constants/Permissions"
import { DayNames } from "../../components/DaysOfWeekCheckboxes"

function VoucherOverTimeDashboard() {
    const { state } = useAppContext()
    const [dateReport, setDateReport] = useState<{
        startDate: Date,
        endDate: Date
    }>({
        startDate: addDays(new Date(), -Configs.DEFAULT_LAST_DAYS + 1),
        endDate: new Date()
    })

    const handleDateChange = (startDate?: Date, endDate?: Date) => {
        if (startDate && endDate) {
            setDateReport({ startDate, endDate })
        }
    }

    useEffect(() => {
        if (state.currentProviderId) {
            GetActiveVouchersPerHourReport(dateReport.startDate, dateReport.endDate)
            GetActiveVouchersByDayOfWeekReport(dateReport.startDate, dateReport.endDate)
        }
    }, [state.currentProviderId, dateReport])

    const [hourReportData, setHourReportData] = useState<{
        hour: number,
        quantity: number,
        placeId: number,
        placeName: string
    }[] | null>(null)

    const [dayOfWeekReportData, setDayOfWeekReportData] = useState<{
        dayOfWeek: string,
        quantity: number,
        placeId: number,
        placeName: string
    }[] | null>(null)

    const GetActiveVouchersPerHourReport = async (start: Date, end: Date) => {
        if (state.currentProviderId) {
            const res = await VoucherService.activeVouchersPerHourReport(state.currentProviderId, start, end)

            if (res?.isSuccess) {
                setHourReportData(res.data)
            } else {
                setHourReportData(null)
                console.log(res.message)
            }
        }
    }

    const GetActiveVouchersByDayOfWeekReport = async (start: Date, end: Date) => {
        if (state.currentProviderId) {
            const res = await VoucherService.activeVouchersByDayOfWeekReport(state.currentProviderId, start, end)

            if (res?.isSuccess) {
                setDayOfWeekReportData(res.data)
            } else {
                setDayOfWeekReportData(null)
                console.log(res.message)
            }
        }
    }

    const [currentPlaceId, setCurrentPlaceId] = useState<number | null>(null)
    const [places, setPlaces] = useState<{
        id: number,
        name: string
    }[] | null>(null)

    useEffect(() => {
        if (hourReportData && hourReportData.length) {
            setCurrentPlaceId(null)
            generateVoucherPerHourChartData(null)

            setPlaces(
                hourReportData
                    .map((x) => { return { id: x.placeId, name: x.placeName } })
                    .filter((value, index, self) => self.findIndex(x => x.id === value.id) === index)
            )
        } else {
            setCurrentPlaceId(null)
            setPlaces(null)
            setVoucherPerHourChartData({ ...voucherPerHourChartData, labels: [], datasets: [] })
        }
    }, [hourReportData])

    useEffect(() => {
        if (dayOfWeekReportData && dayOfWeekReportData.length) {
            generateVoucherByDayOfWeekChartData(null)
        } else {
            setVoucherByDayOfWeekChartData({ ...voucherByDayOfWeekChartData, labels: [], datasets: [] })
        }
    }, [dayOfWeekReportData])

    const handlePlaceChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setCurrentPlaceId(e.target.value ? parseInt(e.target.value) : null)
    }

    useEffect(() => {
        generateVoucherPerHourChartData(currentPlaceId)
        generateVoucherByDayOfWeekChartData(currentPlaceId)
    }, [currentPlaceId])

    const [voucherPerHourChartData, setVoucherPerHourChartData] = useState({
        title: "Vouchers Per Hour",
        labels: [] as string[],
        datasets: [] as DatasetChart[]
    })

    const [voucherByDayOfWeekChartData, setVoucherByDayOfWeekChartData] = useState({
        title: "Vouchers By Day Of Week",
        labels: [] as string[],
        datasets: [] as DatasetChart[]
    })

    const generateVoucherPerHourChartData = (placeId: number | null) => {
        const labels = [] as string[]
        const datasets: DatasetChart[] = []

        if (hourReportData) {
            var data = hourReportData.filter(x => x.placeId === (placeId ? placeId : x.placeId))

            //hours
            var hours = data.map((x) => x.hour).filter((value, index, self) => self.indexOf(value) === index).sort((a, b) => a - b)

            hours.forEach(hour => {
                //labels
                labels.push(`${hour}h`)
            })

            //dataset
            const dataset = {
                label: 'Vouchers',
                data: []
            } as DatasetChart

            hours.forEach(hour => {
                var items = data.filter(x => x.hour === hour)

                var quantity = 0
                if (items) {
                    quantity = items.map(x => x.quantity).reduce((a, b) => a + b, 0)
                }
                dataset.data.push(quantity)
            })

            datasets.push(dataset)

            setVoucherPerHourChartData({ ...voucherPerHourChartData, labels: labels, datasets: datasets })
        } else {
            setVoucherPerHourChartData({ ...voucherPerHourChartData, labels: [], datasets: [] })
        }
    }

    const generateVoucherByDayOfWeekChartData = (placeId: number | null) => {
        const labels = [] as string[]
        const datasets: DatasetChart[] = []

        if (dayOfWeekReportData) {
            var data = dayOfWeekReportData.filter(x => x.placeId === (placeId ? placeId : x.placeId))

            //dataset
            const dataset = {
                label: 'Vouchers',
                data: []
            } as DatasetChart

            //dayOfWeek
            DayNames.forEach(dayName => {
                //labels
                labels.push(dayName)

                var items = data.filter(x => x.dayOfWeek === dayName)

                var quantity = 0
                if (items) {
                    quantity = items.map(x => x.quantity).reduce((a, b) => a + b, 0)
                }
                dataset.data.push(quantity)
            })

            datasets.push(dataset)

            setVoucherByDayOfWeekChartData({ ...voucherByDayOfWeekChartData, labels: labels, datasets: datasets })
        } else {
            setVoucherByDayOfWeekChartData({ ...voucherByDayOfWeekChartData, labels: [], datasets: [] })
        }
    }

    return (
        <>
            <section className="content-header">
                <Container fluid>
                    <Row className="mb-2">
                        <Col sm={12} style={{ display: "flex", alignItems: "center" }}>
                            <h1>Voucher Over Time Dashboard</h1>
                            <DashboardDropdown reportName={Permissions.AllVouchers.VoucherOverTimeReport}></DashboardDropdown>
                        </Col>
                    </Row>
                </Container>
            </section>

            <ContentBody>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <Card.Header>
                                    <DateRangePicker startDate={dateReport.startDate} endDate={dateReport.endDate} onChange={handleDateChange} closeOnSelected={true}></DateRangePicker>
                                    <Form.Select className="ml-3" style={{ width: "auto", display: "inline-block" }} value={currentPlaceId || ""} onChange={handlePlaceChange}>
                                        <option value="">All places</option>
                                        {places && places.map(place => {
                                            return <option value={place.id} key={place.id}>{place.name}</option>
                                        })}
                                    </Form.Select>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col col={12}>
                                            <BarChart title={voucherPerHourChartData.title} labels={voucherPerHourChartData.labels} datasets={voucherPerHourChartData.datasets}></BarChart>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col col={12}>
                                            <BarChart title={voucherByDayOfWeekChartData.title} labels={voucherByDayOfWeekChartData.labels} datasets={voucherByDayOfWeekChartData.datasets}></BarChart>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </ContentBody>
        </>
    )
}

export default VoucherOverTimeDashboard