import { Dropdown } from "react-bootstrap"

const SortDirections: Array<{
    name: "ASC" | "DESC",
    description: string
}> = [
        {
            name: "ASC",
            description: "Ascending"
        },
        {
            name: "DESC",
            description: "Descending"
        }
    ]

export interface ISortField {
    name: string,
    description: string
}

function SortBy(props: {
    fields: ISortField[],
    sortBy: string,
    sortDirection: "ASC" | "DESC",
    onFieldChange: (name: string) => void,
    onDirectionChange: (direction: "ASC" | "DESC") => void
}) {
    return (
        <Dropdown className="sort-by">
            <Dropdown.Toggle variant="default" size="sm">
                <i className="fas fa-sort-amount-up"></i> Sort by
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {
                    props.fields.map(x => <Dropdown.Item key={x.name} className={x.name === props.sortBy ? "active" : ""} onClick={() => props.onFieldChange(x.name)}><i className="fas fa-circle"></i> {x.description}</Dropdown.Item>)
                }
                <Dropdown.Divider />
                {
                    SortDirections.map(x => <Dropdown.Item key={x.name} className={x.name === props.sortDirection ? "active" : ""} onClick={() => props.onDirectionChange(x.name)}><i className="fas fa-circle"></i> {x.description}</Dropdown.Item>)
                }
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default SortBy