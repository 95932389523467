import { ChangeEvent, RefObject, SyntheticEvent, useRef, useState } from "react"
import AccountService from "../services/AccountService"
import { Alert, Button, Form } from "react-bootstrap"

function ChangePassword() {
    const [formData, setFormData] = useState({
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: ""
    })

    const [message, setMessage] = useState("")
    const formRef = useRef<HTMLFormElement>(null)
    const [validatedForm, setValidatedForm] = useState(false)
    const [isHideForm, setIsHideForm] = useState(false)

    const handleSave = async (e: SyntheticEvent) => {
        e.preventDefault()

        try {
            if (formRef.current?.checkValidity()) {
                const res = await AccountService.changePassword(formData.currentPassword, formData.newPassword, formData.confirmNewPassword)

                if (res?.isSuccess) {
                    setFormData({
                        currentPassword: "",
                        newPassword: "",
                        confirmNewPassword: ""
                    })
                    setValidatedForm(false)
                    setIsHideForm(true)

                    setMessage(res.message)
                } else {
                    setMessage(res?.message)
                }
            } else {
                setValidatedForm(true)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleFormControlChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    return (
        <div className="card max-w-400">
            <div className="card-body">
                <p className="login-box-msg">Change password</p>
                {message !== "" && <Alert variant="info" onClose={() => setMessage("")} dismissible>{message}</Alert>}
                {!isHideForm &&
                    <Form noValidate validated={validatedForm} ref={formRef as RefObject<HTMLFormElement>}>
                        <Form.Group className="mb-3" controlId="currentPassword">
                            <Form.Label>Current password</Form.Label>
                            <Form.Control type="password" name="currentPassword" autoComplete="off" required value={formData.currentPassword || ""} onChange={(e) => handleFormControlChange(e as any)} />
                            <Form.Control.Feedback type="invalid">Current password is required.</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="newPassword">
                            <Form.Label>New password</Form.Label>
                            <Form.Control type="password" name="newPassword" autoComplete="off" required value={formData.newPassword || ""} onChange={(e) => handleFormControlChange(e as any)} />
                            <Form.Control.Feedback type="invalid">New password is required.</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="confirmNewPassword">
                            <Form.Label>Confirm new password</Form.Label>
                            <Form.Control type="password" name="confirmNewPassword" autoComplete="off" required value={formData.confirmNewPassword || ""} onChange={(e) => handleFormControlChange(e as any)} />
                            <Form.Control.Feedback type="invalid">Confirm new password is required.</Form.Control.Feedback>
                        </Form.Group>
                        <div className="text-center">
                            <Button variant="primary" onClick={handleSave}><i className="fas fa-save"></i> Change password</Button>
                        </div>
                    </Form>
                }
            </div>
        </div>
    )
}

export default ChangePassword