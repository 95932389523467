import { Alert, Button, Card, Col, Container, Form, InputGroup, Row, Table } from "react-bootstrap"
import ContentBody from "../../components/ContentBody"
import ContentHeader from "../../components/ContentHeader"
import Permissions from "../../constants/Permissions"
import useAppContext from "../../hooks/useAppContext"
import useAuthContext from "../../hooks/useAuthContext"
import { KeyboardEvent, RefObject, useCallback, useEffect, useRef, useState } from "react"
import EventTicketCreateModal from "./EventTicketCreateModal"
import Configs from "../../Configs"
import EventTicketService, { IEventTicket, IEventTicketPrint, EventTicketSortFields } from "../../services/EventTicketService"
import SortBy from "../../components/SortBy"
import Utils from "../../utils/Utils"
import Pagination from "../../components/Pagination"
import DeleteConfirmation from "../../components/DeleteConfirmation"
import EventTicketEditModal from "./EventTicketEditModal"
import TicketTemplateService, { ITicketTemplate } from "../../services/TicketTemplateService"
import HtmlToPrint from "../../components/HtmlToPrint"
import { useReactToPrint } from "react-to-print"
import Confirmation from "../../components/Confirmation"

function EventTickets() {
    const { hasPermission } = useAuthContext()
    const { state } = useAppContext()

    const [processing, setProcessing] = useState<boolean>(false)
    const [tableData, setTableData] = useState({
        data: new Array<IEventTicket>(),
        hasNext: false,
        hasPrevious: false,
        pageIndex: 1,
        pageSize: Configs.DEFAULT_PAGE_SIZE,
        totalPages: 0,
        totalRecords: 0
    })
    const [message, setMessage] = useState("")
    const [currentPageIndex, setCurrentPageIndex] = useState(1)
    const [sortBy, setSortBy] = useState("createdOn")
    const [sortDirection, setSortDirection] = useState<"ASC" | "DESC">("DESC")
    const [selectedItem, setSelectedItem] = useState({
        id: 0,
        name: ""
    })

    const keywordRef = useRef<HTMLInputElement>(null)

    const FilterFn = async (pageIndex: number) => {
        if (state.currentProviderId) {
            let pageSize = Configs.DEFAULT_PAGE_SIZE
            let keyword = keywordRef.current?.value || ""

            const res = await EventTicketService.filter(state.currentProviderId, pageIndex, pageSize, keyword, sortBy, sortDirection)

            if (res?.isSuccess) {
                setTableData(res.data)
            } else {
                console.log(res?.message)
            }

            setCurrentPageIndex(pageIndex)
        }
    }

    useEffect(() => {
        keywordRef.current?.focus()

        FilterFn(1)
    }, [state.currentProviderId, sortBy, sortDirection])

    const handleSearch = () => {
        FilterFn(1)
    }

    const handleSearchInputKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            FilterFn(1)
        }
    }

    const handlePageChange = (pageNumber: number) => {
        FilterFn(pageNumber)
    }

    //AddNew
    const [showCreateModal, setShowCreateModal] = useState(false)

    const handleCreateSuccess = (id: number) => {
        handlePrint(id)
        FilterFn(1)
    }

    //Delete
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false)

    const hideConfirmationModal = () => {
        setSelectedItem({
            id: 0,
            name: ""
        })

        setDisplayConfirmationModal(false)
    }

    const showDeleteConfirmation = (id: number | null, name: string) => {
        setSelectedItem({
            id: id || 0,
            name: name
        })

        setDisplayConfirmationModal(true)
        setMessage("")
    }

    const handleDelete = async () => {
        if (state.currentProviderId && selectedItem.id) {
            const res = await EventTicketService.delete(state.currentProviderId, selectedItem.id)

            if (res?.isSuccess) {
                FilterFn(currentPageIndex)
            }

            hideConfirmationModal()
            setMessage(res.message)
        }
    }

    //Edit
    const [showEditModal, setShowEditModal] = useState(false)

    const handleShowEditModal = (id: number | null) => {
        if (id) {
            setSelectedItem({
                id: id,
                name: ""
            })

            setShowEditModal(true)
        }
    }

    const handleCloseEditModal = () => {
        setSelectedItem({
            id: 0,
            name: ""
        })

        setShowEditModal(false)
    }

    const handleEditSuccess = () => {
        FilterFn(1)
    }

    //Print
    const [isPrinting, setIsPrinting] = useState(false)
    const printRef = useRef(null)
    const onBeforeGetContentResolve = useRef<(() => void) | null>(null)
    const [eventTicketId, setEventTicketId] = useState<number | null>(null)
    const [ticketContentToPrint, setTicketContentToPrint] = useState<ITicketTemplate | null>(null)

    const handlePrint = (id: number | null) => {
        if (state.currentProviderId && id) {
            if (Configs.PrintMethod.get() === "HTML") {
                setEventTicketId(id)
            } else {
                EventTicketService.generatePDF(state.currentProviderId, id)
            }
        }
    }

    useEffect(() => {
        loadTicketContent()
    }, [eventTicketId])

    const loadTicketContent = async () => {
        if (state.currentProviderId && eventTicketId) {
            const template = await TicketTemplateService.getEventTicketHTMLTemplate(state.currentProviderId)

            if (template?.isSuccess && template.data) {
                const ticketTemplate: ITicketTemplate = template.data

                const res = await EventTicketService.getForPrint(state.currentProviderId, eventTicketId)

                if (res?.isSuccess) {
                    var html = ""
                    res.data.forEach((ticket: IEventTicketPrint, index: number) => {
                        var contentTemplate = ticketTemplate.htmlContent

                        if (index > 0) {
                            html += '<div class="page-break" />'
                        }

                        html += contentTemplate
                            .replaceAll("{eventName}", ticket.eventName || "")
                            .replaceAll("{thumbnail}", ticket.thumbnail || "")
                            .replaceAll("{eventDate}", Utils.dateToString(ticket.eventDate, Configs.DATE_FORMAT))
                            .replaceAll("{eventStartTime}", ticket.eventStartTime?.substring(0, 5) || "")
                            .replaceAll("{eventEndTime}", ticket.eventEndTime?.substring(0, 5) || "")
                            .replaceAll("{tel}", ticket.tel || "")
                            .replaceAll("{email}", ticket.email || "")
                            .replaceAll("{website}", ticket.website || "")
                            .replaceAll("{address}", ticket.address || "")
                            .replaceAll("{code}", ticket.code || "")
                            .replaceAll("{qrcode}", ticket.qrcodeImage)
                            .replaceAll("{price}", Utils.formatNumber(ticket.price))
                            .replaceAll("{currency}", ticket.currency)
                            .replaceAll("{seat}", ticket.seat)
                            .replaceAll("{eventTicketId}", ticket.eventTicketId.toString())
                            .replaceAll("{createdOn}", Utils.dateToString(ticket.createdOn, `${Configs.TIME_FORMAT} ${Configs.DATE_FORMAT}`))
                    })

                    setTicketContentToPrint({ ...ticketTemplate, htmlContent: html })
                } else {
                    setTicketContentToPrint(null)
                }
            } else {
                setTicketContentToPrint(null)
            }
        } else {
            setTicketContentToPrint(null)
        }
    }

    const handleOnBeforeGetContent = useCallback(() => {
        setIsPrinting(true)

        return new Promise<void>((resolve) => {
            onBeforeGetContentResolve.current = resolve
            setIsPrinting(false)
            setEventTicketId(null)
            resolve()
        })
    }, [isPrinting, ticketContentToPrint])

    const handlePrintHTML = useReactToPrint({
        content: () => printRef?.current,
        onBeforeGetContent: handleOnBeforeGetContent,
        onAfterPrint: () => {
            onBeforeGetContentResolve.current = null
            setTicketContentToPrint(null)
        },
        removeAfterPrint: true
    })

    useEffect(() => {
        if (ticketContentToPrint) {
            handlePrintHTML()
        }
    }, [ticketContentToPrint])

    useEffect(() => {
        if (ticketContentToPrint && typeof onBeforeGetContentResolve.current === "function") {
            onBeforeGetContentResolve.current()
        }
    }, [onBeforeGetContentResolve.current, ticketContentToPrint])

    //Email
    const [showEmailConfirmationModal, setShowEmailConfirmationModal] = useState(false)
    const [emailConfirmationMessage, setEmailConfirmationMessage] = useState("")

    const handleShowEmailConfirmationModal = (id: number | null, email: string) => {
        if (state.currentProviderId && id && email) {
            setSelectedItem({ id: id, name: email })
            setEmailConfirmationMessage(`Are you sure you want to send ticket information to email address "${email}"?`)
            setShowEmailConfirmationModal(true)
        }
    }

    const handleSendEmail = async () => {
        if (state.currentProviderId && selectedItem.id && !processing) {
            setProcessing(true)
            setEmailConfirmationMessage("Sending email, please wait...")

            const res = await EventTicketService.sendEmail(state.currentProviderId, selectedItem.id)

            setShowEmailConfirmationModal(false)
            setMessage(res.message)
            setProcessing(false)
        }
    }

    return (
        <>
            <ContentHeader title="All Event Tickets" />

            <ContentBody>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <Card.Header>
                                    {hasPermission(Permissions.AllEventTickets.Create) &&
                                        <Button variant="info" size="sm" onClick={() => setShowCreateModal(true)}>
                                            <i className="fas fa-plus"></i> Create new event ticket
                                        </Button>
                                    }

                                    <div className="card-tools" style={{ display: "flex" }}>
                                        <SortBy fields={EventTicketSortFields} sortBy={sortBy} sortDirection={sortDirection} onFieldChange={name => setSortBy(name)} onDirectionChange={name => setSortDirection(name)}></SortBy>

                                        <InputGroup size="sm">
                                            <Form.Control
                                                type="search"
                                                placeholder="Search"
                                                ref={keywordRef as RefObject<HTMLInputElement>}
                                                onKeyUp={handleSearchInputKeyPress}
                                            />
                                            <Button variant="info" size="sm" onClick={handleSearch}>
                                                <i className="fas fa-search"></i>
                                            </Button>
                                        </InputGroup>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    {message !== "" && <Alert variant="info" onClose={() => setMessage("")} dismissible>{message}</Alert>}
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Ticket</th>
                                                <th>Customer</th>
                                                <th>Quantity</th>
                                                <th>Amount</th>
                                                <th>Status</th>
                                                <th>Note</th>
                                                <th style={{ textAlign: "center", width: "80px" }}>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                tableData.data.map(item =>
                                                    <tr key={item.id}>
                                                        <td>
                                                            <ul className="list-in-table">
                                                                <li><i className="fas fa-ticket-alt"></i> {item.event?.name}</li>
                                                                <li><i className="fas fa-calendar-day"></i> {Utils.dateToString(item.eventDate, Configs.DATE_FORMAT)}</li>
                                                                <li><i className="fas fa-clock"></i> {item.eventSchedule && <>{item.eventSchedule.startTime.substring(0, 5)} - {item.eventSchedule.endTime.substring(0, 5)}</>}</li>
                                                                {item.event?.timeZoneInfo?.displayName && <li style={{ fontSize: "10px" }}><i className="fas fa-globe"></i> {item.event?.timeZoneInfo?.displayName}</li>}
                                                            </ul>
                                                        </td>
                                                        <td>
                                                            <ul className="list-in-table">
                                                                <li><i className="fas fa-user"></i> {item.customer.name}</li>
                                                                <li><i className="fas fa-phone"></i> {item.customer.tel}</li>
                                                                <li><i className="fas fa-envelope"></i> <a onClick={() => handleShowEmailConfirmationModal(item.id, item.customer.email)} style={{ cursor: "pointer" }} className="text-primary">{item.customer.email}</a></li>
                                                            </ul>
                                                        </td>
                                                        <td>{item.quantity} {item.reservedSeats && <>{item.reservedSeats.split(",").length} ({item.reservedSeats})</>}</td>
                                                        <td><span className="text-danger">{Utils.formatNumber(item.totalPrice)}</span> {item.currency}</td>
                                                        <td>
                                                            <ul className="list-in-table">
                                                                <li><span>Created on:</span> {Utils.dateToString(item.createdOn, Configs.DATE_TIME_FORMAT)}</li>
                                                                <li><span>Payment service:</span> {item.paymentService}</li>
                                                                <li><span>Payment status:</span> {item.paymentStatus}</li>
                                                            </ul>
                                                        </td>
                                                        <td style={{ whiteSpace: "pre" }}>{item.note}</td>
                                                        <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                                                            {hasPermission(Permissions.AllEventTickets.Print) &&
                                                                <Button variant="secondary" className="mr-1" size="sm" onClick={() => handlePrint(item.id)}><i className="fas fa-print"></i> Print</Button>
                                                            }
                                                            {hasPermission(Permissions.AllEventTickets.Edit) &&
                                                                <Button variant="warning" className="mr-1" size="sm" onClick={() => handleShowEditModal(item.id)}><i className="fas fa-pencil-alt"></i> Edit</Button>
                                                            }
                                                            {hasPermission(Permissions.AllEventTickets.Delete) &&
                                                                <Button variant="danger" size="sm" onClick={() => showDeleteConfirmation(item.id, item.customer.name)}><i className="fas fa-trash-alt"></i> Delete</Button>
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </Card.Body>
                                <Card.Footer>
                                    <Pagination hasNext={tableData.hasNext} hasPrevious={tableData.hasPrevious} pageIndex={tableData.pageIndex} pageSize={tableData.pageSize} totalPages={tableData.totalPages} totalRecords={tableData.totalRecords} handlePageChange={handlePageChange} />
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </ContentBody>

            <EventTicketCreateModal show={showCreateModal} providerId={state.currentProviderId} onClose={() => setShowCreateModal(false)} onSuccess={handleCreateSuccess} />

            <EventTicketEditModal show={showEditModal} providerId={state.currentProviderId} eventTicketId={selectedItem.id} onClose={handleCloseEditModal} onSuccess={handleEditSuccess} />

            <DeleteConfirmation show={displayConfirmationModal} onConfirm={handleDelete} onHide={hideConfirmationModal} id={selectedItem.id} name={selectedItem.name} />
            <Confirmation show={showEmailConfirmationModal} message={emailConfirmationMessage} onHide={() => setShowEmailConfirmationModal(false)} onConfirm={handleSendEmail} />

            <HtmlToPrint
                htmlContent={ticketContentToPrint?.htmlContent || ""}
                styleSheet={ticketContentToPrint?.styleSheet || ""}
                paperSizeWidth={ticketContentToPrint?.paperSizeWidth || ""}
                paperSizeHeight={ticketContentToPrint?.paperSizeHeight || ""}
                ref={printRef}
            />
        </>
    )
}

export default EventTickets