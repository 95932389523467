import { KeyboardEvent, RefObject, useEffect, useRef, useState } from "react"
import ContentHeader from "../../components/ContentHeader"
import useAppContext from "../../hooks/useAppContext"
import useAuthContext from "../../hooks/useAuthContext"
import Configs from "../../Configs"
import ProviderPartnerService, { IProviderPartner } from "../../services/ProviderPartnerService"
import ContentBody from "../../components/ContentBody"
import { Alert, Button, Card, Col, Container, Form, InputGroup, Modal, Row, Table } from "react-bootstrap"
import Permissions from "../../constants/Permissions"
import Utils from "../../utils/Utils"
import Pagination from "../../components/Pagination"
import { SelectOptionType } from "../../constants/SelectOptionType"
import { SelectInstance } from "react-select"
import AsyncSelect from "react-select/async"
import ProviderService, { IProvider } from "../../services/ProviderService"
import { PartnerStatus } from "../../constants/PartnerStatus"
import Confirmation from "../../components/Confirmation"

function ProviderPartners() {
    const { hasPermission } = useAuthContext()
    const { state } = useAppContext()

    const [tableData, setTableData] = useState({
        data: new Array<IProviderPartner>(),
        hasNext: false,
        hasPrevious: false,
        pageIndex: 1,
        pageSize: Configs.DEFAULT_PAGE_SIZE,
        totalPages: 0,
        totalRecords: 0
    })

    const [providerPartner, setProviderPartner] = useState<IProviderPartner | null>(null)

    const [message, setMessage] = useState("")
    const [currentPageIndex, setCurrentPageIndex] = useState(1)
    const [selectedItem, setSelectedItem] = useState({
        id: 0,
        name: "",
        action: ""
    })

    const keywordRef = useRef<HTMLInputElement>(null)

    const FilterFn = async (pageIndex: number) => {
        if (state.currentAgencyId) {
            let pageSize = Configs.DEFAULT_PAGE_SIZE
            let keyword = keywordRef.current?.value || ""

            const res = await ProviderPartnerService.filter(state.currentAgencyId, pageIndex, pageSize, keyword)

            if (res?.isSuccess) {
                setTableData(res.data)
            } else {
                console.log(res?.message)
            }

            setCurrentPageIndex(pageIndex)
        }
    }

    useEffect(() => {
        keywordRef.current?.focus()

        FilterFn(1)
    }, [state.currentAgencyId])

    const handleSearch = () => {
        FilterFn(1)
    }

    const handleSearchInputKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            FilterFn(1)
        }
    }

    const handlePageChange = (pageNumber: number) => {
        FilterFn(pageNumber)
    }

    //Find a Provider
    const [messageFormModal, setMessageFormModal] = useState("")
    const [showModal, setShowModal] = useState(false)
    const handleCloseModal = () => {
        setShowModal(false)
        setProviderPartner(null)
        setMessageFormModal("")
    }
    const handleShowModal = () => {
        setShowModal(true)
        setMessage("")
    }

    const handleFindProvider = () => {
        handleShowModal()
    }

    //AsyncSelect
    const [selectedOption, setSelectedOption] = useState(null as SelectOptionType | null)
    const asyncRef = useRef<SelectInstance<SelectOptionType> | null>(null)

    const handleSelectChange = async (option: SelectOptionType | null) => {
        setSelectedOption(option)

        if (option?.value) {
            let providerId = option?.value as number

            if (state.currentAgencyId && providerId) {
                var res = await ProviderPartnerService.get(state.currentAgencyId, providerId)

                if (res.isSuccess) {
                    setProviderPartner(res.data)

                    setSelectedOption(null)
                    asyncRef.current?.focus()
                } else {
                    setProviderPartner(null)
                }
            }
        }
    }

    const filterProviders = async (inputValue: string) => {
        var options: SelectOptionType[] = []

        if (state.currentAgencyId) {
            var res = await ProviderService.search(inputValue)

            if (res.isSuccess && res.data) {
                options = res.data.map((item: IProvider) => {
                    return { value: item.id, label: item.name }
                })
            }
        }

        return options
    }

    const loadPlaceOptions = (inputValue: string) => new Promise<SelectOptionType[]>((resolve) => {
        resolve(filterProviders(inputValue))
    })

    //Add
    const handleAdd = async (providerId: number | null) => {
        if (state.currentAgencyId && providerId) {
            var res = await ProviderPartnerService.add(state.currentAgencyId, providerId)

            if (res.isSuccess) {
                FilterFn(currentPageIndex)
                handleCloseModal()
            } else {
                setMessageFormModal(res.message)
            }
        }
    }

    //Cancel
    const handleCancel = async (providerId: number | null) => {
        if (state.currentAgencyId && providerId) {
            var res = await ProviderPartnerService.cancel(state.currentAgencyId, providerId)

            if (res.isSuccess) {
                FilterFn(currentPageIndex)
            }

            setMessage(res.message)
        }
    }

    //Remove
    const handleRemove = async (providerId: number | null) => {
        if (state.currentAgencyId && providerId) {
            var res = await ProviderPartnerService.remove(state.currentAgencyId, providerId)

            if (res.isSuccess) {
                FilterFn(currentPageIndex)
            }

            setMessage(res.message)
        }
    }

    //Accept
    const handleAccept = async (providerId: number | null) => {
        if (state.currentAgencyId && providerId) {
            var res = await ProviderPartnerService.accept(state.currentAgencyId, providerId)

            if (res.isSuccess) {
                FilterFn(currentPageIndex)
            }

            setMessage(res.message)
        }
    }

    //Reject
    const handleReject = async (providerId: number | null) => {
        if (state.currentAgencyId && providerId) {
            var res = await ProviderPartnerService.reject(state.currentAgencyId, providerId)

            if (res.isSuccess) {
                FilterFn(currentPageIndex)
            }

            setMessage(res.message)
        }
    }

    //Confirmation
    const [messageConfirmation, setMessageConfirmation] = useState("")
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false)

    const hideConfirmationModal = () => {
        setDisplayConfirmationModal(false)
        setSelectedItem({
            id: 0,
            name: "",
            action: ""
        })
        setMessageConfirmation("")
    }

    const showConfirmation = (id: number, name: string, action: string) => {
        setSelectedItem({
            id: id,
            name: name,
            action: action
        })
        setDisplayConfirmationModal(true)

        setMessageConfirmation(() => {
            switch (action) {
                case "Cancel":
                    return `Are you sure you want to CANCEL the partner request with ${name}?`
                case "Remove":
                    return `Are you sure you want to REMOVE the partner request with ${name}?`
                case "Reject":
                    return `Are you sure you want to REJECT the partner request from ${name}?`
                default:
                    return ""
            }
        })
    }

    const handleConfirm = async () => {
        if (selectedItem.action === "Cancel") {
            handleCancel(selectedItem.id)
        } else if (selectedItem.action === "Remove") {
            handleRemove(selectedItem.id)
        } else if (selectedItem.action === "Reject") {
            handleReject(selectedItem.id)
        }

        hideConfirmationModal()
    }

    return (
        <>
            <ContentHeader title="Providers" />

            <ContentBody>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <Card.Header>
                                    {hasPermission(Permissions.ProviderPartners.Add) &&
                                        <Button variant="info" size="sm" onClick={handleFindProvider}>
                                            <i className="fas fa-search"></i> Find a provider
                                        </Button>
                                    }

                                    <div className="card-tools">
                                        <InputGroup size="sm">
                                            <Form.Control
                                                type="search"
                                                placeholder="Search"
                                                ref={keywordRef as RefObject<HTMLInputElement>}
                                                onKeyUp={handleSearchInputKeyPress}
                                            />
                                            <Button variant="info" size="sm" onClick={handleSearch}>
                                                <i className="fas fa-search"></i>
                                            </Button>
                                        </InputGroup>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    {message !== "" && <Alert variant="info" onClose={() => setMessage("")} dismissible>{message}</Alert>}
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Requested Time</th>
                                                <th>Name</th>
                                                <th>Address</th>
                                                <th>Email</th>
                                                <th style={{ textAlign: "center", width: "80px" }}>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                tableData.data.map(item =>
                                                    <tr key={item.provider.id}>
                                                        <td>{Utils.dateToString(item.requestedTime, Configs.DATE_TIME_FORMAT)}</td>
                                                        <td>{item.provider.name || ""}</td>
                                                        <td>{item.provider.address || ""}</td>
                                                        <td>{item.provider.email || ""}</td>
                                                        <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                                                            {item.status === PartnerStatus.AGENCY_REQUESTED
                                                                && hasPermission(Permissions.ProviderPartners.Cancel)
                                                                && <Button variant="secondary" className="mr-1" size="sm" onClick={() => showConfirmation(item.providerId, item.provider.name, "Cancel")}><i className="fas fa-times"></i> Cancel</Button>}
                                                            {item.status === PartnerStatus.ACCEPTED
                                                                && hasPermission(Permissions.ProviderPartners.Remove)
                                                                && <Button variant="danger" className="mr-1" size="sm" onClick={() => showConfirmation(item.providerId, item.provider.name, "Remove")}><i className="fas fa-times"></i> Remove</Button>}
                                                            {item.status === PartnerStatus.PROVIDER_REQUESTED
                                                                && hasPermission(Permissions.ProviderPartners.Accept)
                                                                && <Button variant="success" className="mr-1" size="sm" onClick={() => handleAccept(item.providerId)}><i className="fas fa-check"></i> Accept</Button>}
                                                            {item.status === PartnerStatus.PROVIDER_REQUESTED
                                                                && hasPermission(Permissions.ProviderPartners.Reject)
                                                                && <Button variant="default" className="mr-1" size="sm" onClick={() => showConfirmation(item.providerId, item.provider.name, "Reject")}><i className="fas fa-times"></i> Reject</Button>}
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </Card.Body>
                                <Card.Footer>
                                    <Pagination hasNext={tableData.hasNext} hasPrevious={tableData.hasPrevious} pageIndex={tableData.pageIndex} pageSize={tableData.pageSize} totalPages={tableData.totalPages} totalRecords={tableData.totalRecords} handlePageChange={handlePageChange} />
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </ContentBody>

            <Modal show={showModal} onHide={handleCloseModal} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Find a provider</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AsyncSelect isClearable value={selectedOption} loadOptions={loadPlaceOptions} onChange={handleSelectChange} ref={asyncRef} placeholder="Search provider" noOptionsMessage={({ inputValue }) => !inputValue ? "Enter provider to search." : "Can't find your provider!"} />
                    {messageFormModal !== "" && <Alert variant="danger" onClose={() => setMessageFormModal("")} dismissible>{messageFormModal}</Alert>}
                    {providerPartner && <fieldset className="mt-3 pt-3">
                        <dl className="row mb-0">
                            <dt className="col-sm-3">Name</dt><dd className="col-sm-9">{providerPartner.provider.name}</dd>
                            <dt className="col-sm-3">Adrress</dt><dd className="col-sm-9">{providerPartner.provider.address}</dd>
                            <dt className="col-sm-3">Email</dt><dd className="col-sm-9">{providerPartner.provider.email}</dd>
                        </dl>
                        {!providerPartner.providerId && <>
                            <Button variant="info" size="sm" className="mt-3" onClick={() => handleAdd(providerPartner.provider.id)}>
                                <i className="far fa-handshake"></i> Add provider
                            </Button>
                        </>}
                    </fieldset>}
                </Modal.Body>
            </Modal>

            <Confirmation show={displayConfirmationModal} message={messageConfirmation} onHide={hideConfirmationModal} onConfirm={handleConfirm}></Confirmation>
        </>
    )
}

export default ProviderPartners