import { Card, Col, Container, Form, Row, Table } from "react-bootstrap"
import DashboardDropdown from "./DashboardDropdown"
import Permissions from "../../constants/Permissions"
import useAppContext from "../../hooks/useAppContext"
import { ChangeEvent, useEffect, useState } from "react"
import { addMonths } from "date-fns/esm"
import Configs from "../../Configs"
import EventTicketService from "../../services/EventTicketService"
import { DatasetChart, LineChart } from "../../components/ChartJS"
import Utils from "../../utils/Utils"
import ContentBody from "../../components/ContentBody"
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

function MonthlyEventTicketDashboard() {
    const { state } = useAppContext()
    const [dateReport, setDateReport] = useState<{
        startDate: Date | null,
        endDate: Date | null
    }>({
        startDate: addMonths(new Date(), -Configs.DEFAULT_LAST_MONTHS - 1),
        endDate: new Date()
    })

    const GetMonthlyEventTicketsReport = async (startYear: number, startMonth: number, endYear: number, endMonth: number) => {
        if (state.currentProviderId) {
            const res = await EventTicketService.monthlyEventTicketsReport(state.currentProviderId, startYear, startMonth, endYear, endMonth)

            if (res?.isSuccess) {
                setReportData(res.data)
            } else {
                setReportData(null)
                console.log(res.message);
            }
        }
    }

    useEffect(() => {
        if (state.currentProviderId) {
            if (dateReport.startDate && dateReport.endDate) {
                var startYear = dateReport.startDate.getFullYear()
                var startMonth = dateReport.startDate.getMonth() + 1
                var endYear = dateReport.endDate.getFullYear()
                var endMonth = dateReport.endDate.getMonth() + 1

                GetMonthlyEventTicketsReport(startYear, startMonth, endYear, endMonth)
            }
        }
    }, [state.currentProviderId, dateReport])

    const [reportData, setReportData] = useState<{
        date: Date,
        quantity: number,
        amount: number,
        paymentService: string,
        eventId: number,
        eventName: string
    }[] | null>(null)

    const [currentEventId, setCurrentEventId] = useState<number | null>(null)
    const [events, setEvents] = useState<{
        id: number,
        name: string
    }[] | null>(null)

    useEffect(() => {
        if (reportData && reportData.length) {
            setCurrentEventId(null)
            generateEventTicketQuantityChartData(null)
            generateEventTicketSalesChartData(null)

            setEvents(
                reportData
                    .map((x) => { return { id: x.eventId, name: x.eventName } })
                    .filter((value, index, self) => self.findIndex(x => x.id === value.id) === index)
            )
        } else {
            setCurrentEventId(null)
            setEvents(null)
            setEventTicketQuantityChartData({ ...voucherQuantityChartData, labels: [], datasets: [] })
            setEventTicketSalesChartData({ ...voucherSalesChartData, labels: [], datasets: [] })
        }
    }, [reportData])

    const handleEventChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setCurrentEventId(e.target.value ? parseInt(e.target.value) : null)
    }

    useEffect(() => {
        generateEventTicketQuantityChartData(currentEventId)
        generateEventTicketSalesChartData(currentEventId)
    }, [currentEventId])

    const [voucherQuantityChartData, setEventTicketQuantityChartData] = useState({
        title: "Number of Event Tickets",
        labels: [] as string[],
        datasets: [] as DatasetChart[]
    })

    const generateEventTicketQuantityChartData = (eventId: number | null) => {
        const labels = [] as string[]
        const datasets: DatasetChart[] = []

        if (reportData) {
            var data = reportData.filter(x => x.eventId === (eventId ? eventId : x.eventId))

            //dates
            var dates = data.map((x) => x.date).filter((value, index, self) => self.indexOf(value) === index).sort((a, b) => new Date(a).getTime() - new Date(b).getTime())

            dates.forEach(date => {
                //labels
                labels.push(Utils.dateToString(date, Configs.MONTH_FORMAT))
            })

            //paymentServices
            var paymentServices = data.map((x) => x.paymentService).filter((value, index, self) => self.indexOf(value) === index)
            paymentServices.forEach(paymentService => {
                const dataset = {
                    label: paymentService,
                    data: []
                } as DatasetChart

                dates.forEach(date => {
                    var items = data.filter(x => x.paymentService === paymentService && x.date === date)

                    var quantity = 0
                    if (items) {
                        quantity = items.map(x => x.quantity).reduce((a, b) => a + b, 0)
                    }
                    dataset.data.push(quantity)
                })

                datasets.push(dataset)
            })

            //Total
            const dataset = {
                label: "Total",
                data: []
            } as DatasetChart

            dates.forEach(date => {
                var items = data.filter(x => x.date === date)

                var quantity = 0
                if (items) {
                    quantity = items.map(x => x.quantity).reduce((a, b) => a + b, 0)
                }
                dataset.data.push(quantity)
            })

            datasets.push(dataset)

            setEventTicketQuantityChartData({ ...voucherQuantityChartData, labels: labels, datasets: datasets })
        } else {
            setEventTicketQuantityChartData({ ...voucherQuantityChartData, labels: [], datasets: [] })
        }
    }

    const [voucherSalesChartData, setEventTicketSalesChartData] = useState({
        title: "Event Tickets Sales",
        labels: [] as string[],
        datasets: [] as DatasetChart[]
    })

    const generateEventTicketSalesChartData = (eventId: number | null) => {
        const labels = [] as string[]
        const datasets: DatasetChart[] = []

        if (reportData) {
            var data = reportData.filter(x => x.eventId === (eventId ? eventId : x.eventId))

            //dates
            var dates = data.map((x) => x.date).filter((value, index, self) => self.indexOf(value) === index).sort((a, b) => new Date(a).getTime() - new Date(b).getTime())

            dates.forEach(d => {
                //labels
                labels.push(Utils.dateToString(d, Configs.MONTH_FORMAT))
            })

            //paymentServices
            var paymentServices = data.map((x) => x.paymentService).filter((value, index, self) => self.indexOf(value) === index)
            paymentServices.forEach(paymentService => {
                const dataset = {
                    label: paymentService,
                    data: []
                } as DatasetChart

                dates.forEach(date => {
                    var items = data.filter(x => x.paymentService === paymentService && x.date === date)

                    var amount = 0
                    if (items) {
                        amount = items.map(x => x.amount).reduce((a, b) => a + b, 0)
                    }
                    dataset.data.push(amount)
                })

                datasets.push(dataset)
            })

            //Total
            const dataset = {
                label: "Total",
                data: []
            } as DatasetChart

            dates.forEach(date => {
                var items = data.filter(x => x.date === date)

                var amount = 0
                if (items) {
                    amount = items.map(x => x.amount).reduce((a, b) => a + b, 0)
                }
                dataset.data.push(amount)
            })

            datasets.push(dataset)

            setEventTicketSalesChartData({ ...voucherSalesChartData, labels: labels, datasets: datasets })
        } else {
            setEventTicketSalesChartData({ ...voucherSalesChartData, labels: [], datasets: [] })
        }
    }

    const handleChange = ([startDate, endDate]: [Date | null, Date | null]) => {
        console.log('startDate: ', startDate, 'endDate: ', endDate)
        setDateReport({
            startDate: startDate,
            endDate: endDate
        })
    }

    return (
        <>
            <section className="content-header">
                <Container fluid>
                    <Row className="mb-2">
                        <Col sm={12} style={{ display: "flex", alignItems: "center" }}>
                            <h1>Monthly Event Ticket Dashboard</h1>
                            <DashboardDropdown reportName={Permissions.AllEventTickets.MonthlyEventTicketsReport}></DashboardDropdown>
                        </Col>
                    </Row>
                </Container>
            </section>

            <ContentBody>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <Card.Header>

                                    <DatePicker
                                        onChange={handleChange}
                                        selectsRange
                                        startDate={dateReport.startDate}
                                        endDate={dateReport.endDate}
                                        dateFormat="MM/yyyy"
                                        showMonthYearPicker
                                        className="form-control"
                                    />
                                    <Form.Select className="ml-3" style={{ width: "auto", display: "inline-block" }} value={currentEventId || ""} onChange={handleEventChange}>
                                        <option value="">All events</option>
                                        {events && events.map(event => {
                                            return <option value={event.id} key={event.id}>{event.name}</option>
                                        })}
                                    </Form.Select>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col lg={9}>
                                            <LineChart title={voucherSalesChartData.title} labels={voucherSalesChartData.labels} datasets={voucherSalesChartData.datasets}></LineChart>
                                        </Col>
                                        <Col lg={3}>
                                            <Table responsive className="mt-2" size="sm">
                                                <thead>
                                                    <tr>
                                                        <th>Payment</th>
                                                        <th style={{ textAlign: "right" }}>Sales</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {voucherSalesChartData.datasets.filter(x => x.label !== "Total").map((ds, index) => {
                                                        return <tr key={index}>
                                                            <td>{ds.label}</td>
                                                            <td style={{ textAlign: "right" }}>{Utils.formatNumber(ds.data.reduce((a, b) => a + b, 0))}</td>
                                                        </tr>
                                                    })}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th>Total</th>
                                                        <th style={{ textAlign: "right" }}>{Utils.formatNumber(voucherSalesChartData.datasets.filter(x => x.label !== "Total").map(ds => ds.data).flat().reduce((a, b) => a + b, 0))}</th>
                                                    </tr>
                                                </tfoot>
                                            </Table>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={9}>
                                            <LineChart title={voucherQuantityChartData.title} labels={voucherQuantityChartData.labels} datasets={voucherQuantityChartData.datasets}></LineChart>
                                        </Col>
                                        <Col lg={3}>
                                            <Table responsive className="mt-2" size="sm">
                                                <thead>
                                                    <tr>
                                                        <th>Payment</th>
                                                        <th style={{ textAlign: "right" }}>Quantity</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {voucherQuantityChartData.datasets.filter(x => x.label !== "Total").map((ds, index) => {
                                                        return <tr key={index}>
                                                            <td>{ds.label}</td>
                                                            <td style={{ textAlign: "right" }}>{Utils.formatNumber(ds.data.reduce((a, b) => a + b, 0))}</td>
                                                        </tr>
                                                    })}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th>Total</th>
                                                        <th style={{ textAlign: "right" }}>{Utils.formatNumber(voucherQuantityChartData.datasets.filter(x => x.label !== "Total").map(ds => ds.data).flat().reduce((a, b) => a + b, 0))}</th>
                                                    </tr>
                                                </tfoot>
                                            </Table>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </ContentBody>
        </>
    )
}

export default MonthlyEventTicketDashboard