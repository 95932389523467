import axiosClient, { ApiResponse, axiosCustomError } from "./axiosClient"
import { ILanguage } from "./LanguageService"

export interface ISeatTypeTranslation {
    ticketId: number | null
    languageId: number
    name: string
    description: string
    language: ILanguage
}

export interface ISeatType {
    id: number | null
    name: string
    color: string
    price: number | null
    currency: string
    orderNumber: number | null
    isUsed: boolean
    eventId: number
    translations: Array<ISeatTypeTranslation>
}

export default class SeatTypeService {
    static async listAll(eventId: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/SeatTypes/${eventId}/All`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async filter(eventId: number, pageIndex: number, pageSize: number, keyword: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/SeatTypes/${eventId}?keyword=${keyword}&pageSize=${pageSize}&pageIndex=${pageIndex}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async get(eventId: number, id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/SeatTypes/${eventId}/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async delete(eventId: number, id: number) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/SeatTypes/${eventId}/${id}`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async update(eventId: number, id: number, itemData: ISeatType) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/SeatTypes/${eventId}/${id}`, itemData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async create(eventId: number, itemData: ISeatType) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/SeatTypes/${eventId}/Create`, itemData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async changeIsUsed(eventId: number, id: number) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/SeatTypes/${eventId}/ChangeIsUsed/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }
}