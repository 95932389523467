import { Dropdown, DropdownButton } from "react-bootstrap"
import Permissions from "../../constants/Permissions"
import useAuthContext from "../../hooks/useAuthContext"
import RoutePath from "../../constants/RoutePath"
import { Link } from "react-router-dom"

interface IDashboardDropdownItem {
    name: string,
    text: string,
    link: string
}

export function DashboardDropdownItems() {
    const { hasPermission } = useAuthContext()
    const items: IDashboardDropdownItem[] = [
        {
            name: Permissions.AllVouchers.DailyVouchersReport,
            text: "Daily Voucher Dashboard",
            link: RoutePath.PROVIDER.DAILY_VOUCHER_DASHBOARD
        },
        {
            name: Permissions.AllVouchers.MonthlyVouchersReport,
            text: "Monthly Voucher Dashboard",
            link: RoutePath.PROVIDER.MONTHLY_VOUCHER_DASHBOARD
        },
        {
            name: Permissions.AllVouchers.VoucherOverTimeReport,
            text: "Voucher Over Time Dashboard",
            link: RoutePath.PROVIDER.VOUCHER_OVER_TIME_DASHBOARD
        },
        {
            name: Permissions.AgencyPartners.Report,
            text: "Agency Dashboard",
            link: RoutePath.PROVIDER.AGENCY_DASHBOARD
        },
        {
            name: Permissions.AllEventTickets.DailyEventTicketsReport,
            text: "Daily Event Ticket Dashboard",
            link: RoutePath.PROVIDER.DAILY_EVENT_TICKET_DASHBOARD
        },
        {
            name: Permissions.AllEventTickets.MonthlyEventTicketsReport,
            text: "Monthly Event Ticket Dashboard",
            link: RoutePath.PROVIDER.MONTHLY_EVENT_TICKET_DASHBOARD
        }
    ]

    return items.filter(x => hasPermission(x.name))
}

function DashboardDropdown(props: {
    reportName: string
}) {
    const dropdownItems = DashboardDropdownItems()

    return (
        <>
            {dropdownItems.length > 1 &&
                <DropdownButton title={""} variant="default" size="sm">
                    {dropdownItems.map((x, i) => <Dropdown.Item as={Link} to={x.link} key={i}>
                        <i className={`fas fa-tachometer-alt ${props.reportName === x.name ? "text-blue" : "text-muted"}`}></i> {x.text}
                    </Dropdown.Item>)
                    }
                </DropdownButton>}
        </>
    )
}

export default DashboardDropdown 