namespace Permissions {
    export const Users = {
        View: "Permissions.Users.View",
        Create: "Permissions.Users.Create",
        Edit: "Permissions.Users.Edit",
        Delete: "Permissions.Users.Delete",
        Search: "Permissions.Users.Search"
    }

    export const Roles = {
        View: "Permissions.Roles.View",
        Create: "Permissions.Roles.Create",
        Edit: "Permissions.Roles.Edit",
        Delete: "Permissions.Roles.Delete",
        Initialize: "Permissions.Roles.Initialize"
    }

    export const Permissions = {
        View: "Permissions.Permissions.View",
        Initialize: "Permissions.Permissions.Initialize"
    }

    export const ProviderUsers = {
        View: "Permissions.ProviderUsers.View",
        Create: "Permissions.ProviderUsers.Create",
        Edit: "Permissions.ProviderUsers.Edit",
        Delete: "Permissions.ProviderUsers.Delete"
    }

    export const ProviderRoles = {
        View: "Permissions.ProviderRoles.View",
        Create: "Permissions.ProviderRoles.Create",
        Edit: "Permissions.ProviderRoles.Edit",
        Delete: "Permissions.ProviderRoles.Delete"
    }

    export const ProviderPermissions = {
        View: "Permissions.ProviderPermissions.View"
    }

    export const Regions = {
        View: "Permissions.Regions.View",
        Create: "Permissions.Regions.Create",
        Edit: "Permissions.Regions.Edit",
        Delete: "Permissions.Regions.Delete"
    }

    export const Languages = {
        View: "Permissions.Languages.View",
        Create: "Permissions.Languages.Create",
        Edit: "Permissions.Languages.Edit",
        Delete: "Permissions.Languages.Delete"
    }

    export const Providers = {
        View: "Permissions.Providers.View",
        Create: "Permissions.Providers.Create",
        Edit: "Permissions.Providers.Edit",
        Delete: "Permissions.Providers.Delete",
        ViewProfile: "Permissions.Providers.ViewProfile",
        EditProfile: "Permissions.Providers.EditProfile"
    }

    export const Agencies = {
        View: "Permissions.Agencies.View",
        Create: "Permissions.Agencies.Create",
        Edit: "Permissions.Agencies.Edit",
        Delete: "Permissions.Agencies.Delete",
        ViewProfile: "Permissions.Agencies.ViewProfile",
        EditProfile: "Permissions.Agencies.EditProfile"
    }

    export const PlaceTypes = {
        View: "Permissions.PlaceTypes.View",
        Create: "Permissions.PlaceTypes.Create",
        Edit: "Permissions.PlaceTypes.Edit",
        Delete: "Permissions.PlaceTypes.Delete"
    }

    export const Collections = {
        View: "Permissions.Collections.View",
        Create: "Permissions.Collections.Create",
        Edit: "Permissions.Collections.Edit",
        Delete: "Permissions.Collections.Delete"
    }

    export const Tours = {
        View: "Permissions.Tours.View",
        Create: "Permissions.Tours.Create",
        Edit: "Permissions.Tours.Edit",
        Delete: "Permissions.Tours.Delete"
    }

    export const Areas = {
        View: "Permissions.Areas.View",
        Create: "Permissions.Areas.Create",
        Edit: "Permissions.Areas.Edit",
        Delete: "Permissions.Areas.Delete"
    }

    export const OpenTimes = {
        View: "Permissions.OpenTimes.View",
        Create: "Permissions.OpenTimes.Create",
        Edit: "Permissions.OpenTimes.Edit",
        Delete: "Permissions.OpenTimes.Delete"
    }

    export const Tickets = {
        View: "Permissions.Tickets.View",
        Create: "Permissions.Tickets.Create",
        Edit: "Permissions.Tickets.Edit",
        Delete: "Permissions.Tickets.Delete"
    }

    export const Contacts = {
        View: "Permissions.Contacts.View",
        Create: "Permissions.Contacts.Create",
        Edit: "Permissions.Contacts.Edit",
        Delete: "Permissions.Contacts.Delete"
    }

    export const AudioStories = {
        View: "Permissions.AudioStories.View",
        Create: "Permissions.AudioStories.Create",
        Edit: "Permissions.AudioStories.Edit",
        Delete: "Permissions.AudioStories.Delete",
        Search: "Permissions.AudioStories.Search"
    }

    export const AudioStoryPhotos = {
        View: "Permissions.AudioStoryPhotos.View",
        Create: "Permissions.AudioStoryPhotos.Create",
        Edit: "Permissions.AudioStoryPhotos.Edit",
        Delete: "Permissions.AudioStoryPhotos.Delete"
    }

    export const Places = {
        View: "Permissions.Places.View",
        Create: "Permissions.Places.Create",
        Edit: "Permissions.Places.Edit",
        Delete: "Permissions.Places.Delete",
        Search: "Permissions.Places.Search",
        Custom: "Permissions.Places.Custom"
    }

    export const PlaceMaps = {
        View: "Permissions.PlaceMaps.View",
        Edit: "Permissions.PlaceMaps.Edit"
    }

    export const PlacePhotos = {
        View: "Permissions.PlacePhotos.View",
        Create: "Permissions.PlacePhotos.Create",
        Edit: "Permissions.PlacePhotos.Edit",
        Delete: "Permissions.PlacePhotos.Delete"
    }

    export const ApiKeys = {
        View: "Permissions.ApiKeys.View",
        Create: "Permissions.ApiKeys.Create",
        Edit: "Permissions.ApiKeys.Edit",
        Delete: "Permissions.ApiKeys.Delete"
    }

    export const Credentials = {
        View: "Permissions.Credentials.View",
        Create: "Permissions.Credentials.Create",
        Edit: "Permissions.Credentials.Edit",
        Delete: "Permissions.Credentials.Delete"
    }

    export const AllVouchers = {
        View: "Permissions.AllVouchers.View",
        Create: "Permissions.AllVouchers.Create",
        Edit: "Permissions.AllVouchers.Edit",
        Delete: "Permissions.AllVouchers.Delete",
        Print: "Permissions.AllVouchers.Print",
        DailyVouchersReport: "Permissions.AllVouchers.DailyVouchersReport",
        MonthlyVouchersReport: "Permissions.AllVouchers.MonthlyVouchersReport",
        VoucherOverTimeReport: "Permissions.AllVouchers.VoucherOverTimeReport"
    }

    export const Combos = {
        View: "Permissions.Combos.View",
        Create: "Permissions.Combos.Create",
        Edit: "Permissions.Combos.Edit",
        Delete: "Permissions.Combos.Delete",
        Sale: "Permissions.Combos.Sale"
    }

    export const MyVouchers = {
        View: "Permissions.MyVouchers.View",
        Create: "Permissions.MyVouchers.Create",
        Edit: "Permissions.MyVouchers.Edit",
        Delete: "Permissions.MyVouchers.Delete",
        Report: "Permissions.MyVouchers.Report",
        Print: "Permissions.MyVouchers.Print"
    }

    export const AgencyVouchers = {
        View: "Permissions.AgencyVouchers.View",
        Create: "Permissions.AgencyVouchers.Create",
        Edit: "Permissions.AgencyVouchers.Edit",
        Delete: "Permissions.AgencyVouchers.Delete",
        Report: "Permissions.AgencyVouchers.Report",
        Print: "Permissions.AgencyVouchers.Print"
    }

    export const Localizations = {
        View: "Permissions.Localizations.View",
        Create: "Permissions.Localizations.Create",
        Edit: "Permissions.Localizations.Edit",
        Delete: "Permissions.Localizations.Delete",
        Custom: "Permissions.Localizations.Custom"
    }

    export const Apps = {
        View: "Permissions.Apps.View",
        Edit: "Permissions.Apps.Edit"
    }

    export const ErrorLogs = {
        View: "Permissions.ErrorLogs.View",
        Delete: "Permissions.ErrorLogs.Delete",
        Clear: "Permissions.ErrorLogs.Clear"
    }

    export const Subscriptions = {
        View: "Permissions.Subscriptions.View",
        Create: "Permissions.Subscriptions.Create",
        Edit: "Permissions.Subscriptions.Edit",
        Delete: "Permissions.Subscriptions.Delete"
    }

    export const VoucherTypes = {
        View: "Permissions.VoucherTypes.View",
        Create: "Permissions.VoucherTypes.Create",
        Edit: "Permissions.VoucherTypes.Edit",
        Delete: "Permissions.VoucherTypes.Delete"
    }

    export const TicketTemplates = {
        View: "Permissions.TicketTemplates.View",
        Create: "Permissions.TicketTemplates.Create",
        Edit: "Permissions.TicketTemplates.Edit",
        Delete: "Permissions.TicketTemplates.Delete"
    }

    export const ApiLogs = {
        View: "Permissions.ApiLogs.View",
        Delete: "Permissions.ApiLogs.Delete",
        Clear: "Permissions.ApiLogs.Clear"
    }

    export const AppLogs = {
        View: "Permissions.AppLogs.View",
        Delete: "Permissions.AppLogs.Delete",
        Clear: "Permissions.AppLogs.Clear"
    }

    export const AuditLogs = {
        View: "Permissions.AuditLogs.View",
        Delete: "Permissions.AuditLogs.Delete",
        Clear: "Permissions.AuditLogs.Clear"
    }

    export const AgencyPartners = {
        View: "Permissions.AgencyPartners.View",
        Add: "Permissions.AgencyPartners.Add",
        Cancel: "Permissions.AgencyPartners.Cancel",
        Remove: "Permissions.AgencyPartners.Remove",
        Accept: "Permissions.AgencyPartners.Accept",
        Reject: "Permissions.AgencyPartners.Reject",
        Report: "Permissions.AgencyPartners.Report"
    }

    export const ProviderPartners = {
        View: "Permissions.ProviderPartners.View",
        Add: "Permissions.ProviderPartners.Add",
        Cancel: "Permissions.ProviderPartners.Cancel",
        Remove: "Permissions.ProviderPartners.Remove",
        Accept: "Permissions.ProviderPartners.Accept",
        Reject: "Permissions.ProviderPartners.Reject"
    }

    export const Events = {
        View: "Permissions.Events.View",
        Create: "Permissions.Events.Create",
        Edit: "Permissions.Events.Edit",
        Delete: "Permissions.Events.Delete",
        ConfirmationEmailTemplate: "Permissions.Events.ConfirmationEmailTemplate"
    }

    export const SeatTypes = {
        View: "Permissions.SeatTypes.View",
        Create: "Permissions.SeatTypes.Create",
        Edit: "Permissions.SeatTypes.Edit",
        Delete: "Permissions.SeatTypes.Delete"
    }

    export const Seats = {
        View: "Permissions.Seats.View",
        Create: "Permissions.Seats.Create",
        Edit: "Permissions.Seats.Edit",
        Delete: "Permissions.Seats.Delete"
    }

    export const AllEventTickets = {
        View: "Permissions.AllEventTickets.View",
        Create: "Permissions.AllEventTickets.Create",
        Edit: "Permissions.AllEventTickets.Edit",
        Delete: "Permissions.AllEventTickets.Delete",
        Print: "Permissions.AllEventTickets.Print",
        DailyEventTicketsReport: "Permissions.AllEventTickets.DailyEventTicketsReport",
        MonthlyEventTicketsReport: "Permissions.AllEventTickets.MonthlyEventTicketsReport"
    }

    export const MyEventTickets = {
        View: "Permissions.MyEventTickets.View",
        Create: "Permissions.MyEventTickets.Create",
        Edit: "Permissions.MyEventTickets.Edit",
        Delete: "Permissions.MyEventTickets.Delete",
        Report: "Permissions.MyEventTickets.Report",
        Print: "Permissions.MyEventTickets.Print"
    }

    export const ProviderPhotos = {
        View: "Permissions.ProviderPhotos.View",
        Create: "Permissions.ProviderPhotos.Create",
        Edit: "Permissions.ProviderPhotos.Edit",
        Delete: "Permissions.ProviderPhotos.Delete"
    }
}

export default Permissions