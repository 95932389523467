import { ChangeEvent, KeyboardEvent, RefObject, SyntheticEvent, useEffect, useRef, useState } from "react"
import ContentHeader from "../../components/ContentHeader"
import useAppContext from "../../hooks/useAppContext"
import useAuthContext from "../../hooks/useAuthContext"
import useGlobalContext from "../../hooks/useGlobalContext"
import EventService, { IConfirmationEmailTemplate, IEvent } from "../../services/EventService"
import Configs from "../../Configs"
import AddOrRemoveLanguages, { LanguageActionTypes } from "../../components/AddOrRemoveLanguages"
import ContentBody from "../../components/ContentBody"
import { Alert, Button, Card, Col, Container, Form, InputGroup, Modal, Row, Spinner, Tab, Table, Tabs } from "react-bootstrap"
import Permissions from "../../constants/Permissions"
import Pagination from "../../components/Pagination"
import DeleteConfirmation from "../../components/DeleteConfirmation"
import DatePicker from "../../components/DatePicker"
import DaysOfWeekCheckboxes from "../../components/DaysOfWeekCheckboxes"
import SeatTypesModal from "./SeatTypesModal"
import SeatsModal from "./SeatsModal"
import Utils from "../../utils/Utils"
import TimeZoneSelect from "../../components/TimeZoneSelect"
import TextEditor from "../../components/TextEditor"

function Events() {
    const { hasPermission } = useAuthContext()
    const { state } = useAppContext()
    const { languages } = useGlobalContext()

    const [tableData, setTableData] = useState({
        data: new Array<IEvent>(),
        hasNext: false,
        hasPrevious: false,
        pageIndex: 1,
        pageSize: Configs.DEFAULT_PAGE_SIZE,
        totalPages: 0,
        totalRecords: 0
    })

    const initFormData: IEvent = {
        id: null,
        name: "",
        thumbnail: "",
        tel: "",
        email: "",
        website: "",
        orderNumber: null,
        ticketQuantity: null,
        ticketPrice: null,
        timeZoneId: "",
        currency: "",
        startDate: null,
        endDate: null,
        openingTime: null,
        closingTime: null,
        published: false,
        timeZoneInfo: null,
        translations: languages.filter(x => x.code === Configs.DEFAULT_LANGUAGE_CODE).map(x => {
            return {
                eventId: null,
                languageId: x.id || 0,
                name: "",
                description: "",
                venue: "",
                address: "",
                isActive: false,
                language: x
            }
        }),
        schedules: [
            {
                id: null,
                startTime: "",
                endTime: "",
                byDays: []
            }
        ],
        seats: []
    }

    const [message, setMessage] = useState("")
    const [messageFormModal, setMessageFormModal] = useState("")
    const [formData, setFormData] = useState(initFormData)
    const [currentPageIndex, setCurrentPageIndex] = useState(1)
    const [selectedItem, setSelectedItem] = useState({
        id: 0,
        name: ""
    })

    const keywordRef = useRef<HTMLInputElement>(null)
    const formRef = useRef<HTMLFormElement>(null)
    const [validatedForm, setValidatedForm] = useState(false)
    const [activeTabKey, setActiveTabKey] = useState(languages.find(x => x.code === Configs.DEFAULT_LANGUAGE_CODE)?.id?.toString())
    const [thumbnail, setThumbnail] = useState(null as any)

    const FilterFn = async (pageIndex: number) => {
        if (state.currentProviderId) {
            let pageSize = Configs.DEFAULT_PAGE_SIZE
            let keyword = keywordRef.current?.value || ""

            const res = await EventService.filter(state.currentProviderId, pageIndex, pageSize, keyword)

            if (res?.isSuccess) {
                setTableData(res.data)
            } else {
                console.log(res?.message)
            }

            setCurrentPageIndex(pageIndex)
        }
    }

    useEffect(() => {
        keywordRef.current?.focus()

        FilterFn(1)
    }, [state.currentProviderId])

    const handleSearch = () => {
        FilterFn(1)
    }

    const handleSearchInputKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            FilterFn(1)
        }
    }

    const handlePageChange = (pageNumber: number) => {
        FilterFn(pageNumber)
    }

    const handleFormControlChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
        if (e.target.name === "orderNumber" || e.target.name === "ticketQuantity" || e.target.name === "openingTime" || e.target.name === "closingTime") {
            setFormData({ ...formData, [e.target.name]: e.target.value ? parseInt(e.target.value) : null })
        } if (e.target.name === "ticketPrice") {
            setFormData({ ...formData, [e.target.name]: e.target.value ? parseFloat(e.target.value) : null })
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value })
        }
    }

    const handleTranslateFormControlChange = (e: ChangeEvent<HTMLInputElement>, languageId: number | null) => {
        if (e.target.name === "isActive") {
            setFormData({ ...formData, translations: formData.translations.map(x => x.languageId === languageId ? { ...x, [e.target.name]: e.target.checked } : x) })
        } else {
            setFormData({ ...formData, translations: formData.translations.map(x => x.languageId === languageId ? { ...x, [e.target.name]: e.target.value } : x) })
        }
    }

    const handleScheduleFormControlChange = (e: ChangeEvent<HTMLInputElement>, currentIndex: number) => {
        if (e.target.name === "startTime" || e.target.name === "endTime") {
            setFormData({ ...formData, schedules: formData.schedules.map((x, index) => index === currentIndex ? { ...x, [e.target.name]: e.target.value } : x) })
        } else {
        }
    }

    const handleRemoveSchedule = (currentIndex: number) => {
        setFormData({ ...formData, schedules: formData.schedules.filter((x, index) => index !== currentIndex) })
    }

    const handleAddNewSchedule = () => {
        let newSchedules = formData.schedules
        newSchedules.push({
            id: null,
            startTime: "",
            endTime: "",
            byDays: []
        })

        setFormData({ ...formData, schedules: newSchedules })
    }

    const handleDaysChange = (e: ChangeEvent<HTMLInputElement>, currentIndex: number) => {
        const checked = e.target.checked
        const value = e.target.value

        let newByDays = formData.schedules[currentIndex]?.byDays || []
        if (checked) {
            newByDays.push(value)
        } else {
            newByDays = newByDays.filter(x => x !== value)
        }

        setFormData({ ...formData, schedules: formData.schedules.map((x, index) => index === currentIndex ? { ...x, byDays: newByDays } : x) })
    }

    const handleAddNew = () => {
        setFormData(initFormData)
        handleShowModal()
    }

    const handleEdit = async (id: number | null) => {
        if (state.currentProviderId && id) {
            const res = await EventService.get(state.currentProviderId, id)

            if (res?.isSuccess) {
                setFormData(res.data)
                setActiveTabKey(res.data.translations[0]?.languageId?.toString())

                handleShowModal()
            } else {
                console.log(res?.message)
            }
        }
    }

    const [processing, setProcessing] = useState<boolean>(false)
    const handleSave = async (e: SyntheticEvent) => {
        e.preventDefault()

        setMessageFormModal("")
        if (!formData.translations?.length) {
            setMessageFormModal("Bạn phải nhập ít nhất 1 ngôn ngữ.")
            return
        }

        if (!formData.schedules?.length) {
            setMessageFormModal("Schedule is required.")
            return
        }

        if (!processing) {
            setProcessing(true)
            if (state.currentProviderId) {
                if (formRef.current?.checkValidity()) {
                    var frmData = new FormData()
                    if (thumbnail !== null) {
                        frmData.append("Thumbnail", thumbnail)
                    }
                    frmData.append("Tel", formData.tel || "")
                    frmData.append("Email", formData.email || "")
                    frmData.append("Website", formData.website || "")
                    frmData.append("OrderNumber", formData.orderNumber ? formData.orderNumber.toString() : "")
                    frmData.append("TicketQuantity", formData.ticketQuantity ? formData.ticketQuantity.toString() : "")
                    frmData.append("TicketPrice", formData.ticketPrice ? formData.ticketPrice.toString() : "")
                    frmData.append("TimeZoneId", formData.timeZoneId || "")
                    frmData.append("Currency", formData.currency || "")
                    frmData.append("StartDate", formData.startDate ? formData.startDate.toString() : "")
                    frmData.append("EndDate", formData.endDate ? formData.endDate.toString() : "")
                    frmData.append("OpeningTime", formData.openingTime ? formData.openingTime.toString() : "")
                    frmData.append("ClosingTime", formData.closingTime ? formData.closingTime.toString() : "")

                    formData.translations.forEach((item, index) => {
                        if (item.eventId) {
                            frmData.append(`Translations[${index}].EventId`, item.eventId.toString())
                        }
                        frmData.append(`Translations[${index}].LanguageId`, item.languageId.toString())
                        frmData.append(`Translations[${index}].Name`, item.name.toString() || "")
                        frmData.append(`Translations[${index}].Description`, item.description?.toString() || "")
                        frmData.append(`Translations[${index}].Venue`, item.venue?.toString() || "")
                        frmData.append(`Translations[${index}].Address`, item.address?.toString() || "")
                        frmData.append(`Translations[${index}].IsActive`, item.isActive ? "True" : "False")
                    })

                    formData.schedules.forEach((item, index) => {
                        if (item.id) {
                            frmData.append(`Schedules[${index}].Id`, item.id.toString())
                        }
                        frmData.append(`Schedules[${index}].StartTime`, item.startTime?.toString() || "")
                        frmData.append(`Schedules[${index}].EndTime`, item.endTime?.toString() || "")
                        item.byDays.forEach((day, subIndex) => {
                            frmData.append(`Schedules[${index}].ByDays[${subIndex}]`, day?.toString() || "")
                        })
                    })

                    try {
                        if (formData.id && formData.id > 0) {
                            const res = await EventService.update(state.currentProviderId, formData.id, frmData)

                            if (res?.isSuccess) {
                                FilterFn(currentPageIndex)
                                handleCloseModal()
                                setMessage(res.message)
                            } else {
                                setMessageFormModal(res?.message)
                            }
                        } else {
                            const res = await EventService.create(state.currentProviderId, frmData)

                            if (res?.isSuccess) {
                                FilterFn(1)
                                handleCloseModal()
                                setMessage(res.message)
                            } else {
                                setMessageFormModal(res?.message)
                            }
                        }
                    } catch (error) {
                        console.log(error)
                    }
                } else {
                    setValidatedForm(true)
                }
            }
            setProcessing(false)
        }
    }

    const [showModal, setShowModal] = useState(false)
    const handleCloseModal = () => {
        setShowModal(false)
        setValidatedForm(false)
        setMessageFormModal("")
    }
    const handleShowModal = () => {
        setThumbnail(null)
        setMessage("")
        setShowModal(true)
    }

    const modalTitle = (formData.id || 0) > 0 ? "Edit Event" : "New Event"


    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false)

    const hideConfirmationModal = () => {
        setSelectedItem({
            id: 0,
            name: ""
        })

        setDisplayConfirmationModal(false)
    }

    const showDeleteConfirmation = (id: number | null, name: string) => {
        setSelectedItem({
            id: id || 0,
            name: name
        })

        setDisplayConfirmationModal(true)
        setMessage("")
    }

    const handleDelete = async () => {
        if (state.currentProviderId && selectedItem.id > 0) {
            const res = await EventService.delete(state.currentProviderId, selectedItem.id)

            if (res?.isSuccess) {
                FilterFn(currentPageIndex)
            }

            hideConfirmationModal()
            setMessage(res.message)
        }
    }

    const handlePublishedSwitch = async (id: number | null) => {
        if (state.currentProviderId && id) {
            const res = await EventService.changePublished(state.currentProviderId, id)

            if (res?.isSuccess) {
                FilterFn(currentPageIndex)
            } else {
                console.log(res?.message)
            }
        }
    }

    const handleThumbnailChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files?.length) {
            setThumbnail(e.target.files[0])
        } else {
            setThumbnail(null)
        }
    }

    const handleLanguagesChange = async (languageId: number, action: LanguageActionTypes) => {
        if (action === "Add") {
            const newLang = languages.find(x => x.id === languageId)
            if (newLang) {
                if (formData.translations.filter(x => x.languageId === newLang.id).length === 0) {
                    const newFormData = { ...formData }

                    newFormData.translations.push({
                        eventId: null,
                        languageId: newLang.id || 0,
                        name: "",
                        description: "",
                        venue: "",
                        address: "",
                        isActive: false,
                        language: newLang
                    })

                    setFormData(newFormData)
                    setActiveTabKey(newLang.id?.toString())
                }
            }
        } else {
            const newTranslations = formData.translations.filter(x => x.languageId !== languageId)

            setFormData({ ...formData, translations: newTranslations })
            setActiveTabKey(newTranslations[0]?.languageId.toString())
        }
    }

    // SeatTypesModal
    const [showSeatTypesModal, setShowSeatTypesModal] = useState(false)
    const handleCloseSeatTypesModal = () => {
        setShowSeatTypesModal(false)
        setSelectedItem({
            id: 0,
            name: ""
        })
    }

    const handleShowSeatTypesModal = (id: number | null, name: string) => {
        if (id) {
            setSelectedItem({
                id: id || 0,
                name: name
            })
            setShowSeatTypesModal(true)
        }
    }

    // SeatsModal
    const [showSeatsModal, setShowSeatsModal] = useState(false)
    const handleCloseSeatsModal = () => {
        setShowSeatsModal(false)
        setSelectedItem({
            id: 0,
            name: ""
        })
    }

    const handleShowSeatsModal = (id: number | null, name: string) => {
        if (id) {
            setSelectedItem({
                id: id || 0,
                name: name
            })
            setShowSeatsModal(true)
        }
    }

    //ConfirmationEmailTemplate
    const formConfirmationEmailTemplateRef = useRef<HTMLFormElement>(null)
    const [validatedConfirmationEmailTemplateForm, setValidatedConfirmationEmailTemplateForm] = useState(false)
    const [messageConfirmationEmailTemplateFormModal, setMessageConfirmationEmailTemplateFormModal] = useState("")
    const initConfirmationEmailTemplateFormData: IConfirmationEmailTemplate = {
        confirmationEmailSubject: "",
        confirmationEmailBody: ""
    }
    const [confirmationEmailTemplateFormData, setConfirmationEmailTemplateFormData] = useState(initConfirmationEmailTemplateFormData)

    const [showConfirmationEmailTemplateModal, setShowConfirmationEmailTemplateModal] = useState(false)
    const handleCloseConfirmationEmailTemplateModal = () => {
        setShowConfirmationEmailTemplateModal(false)
        setSelectedItem({
            id: 0,
            name: ""
        })
        setConfirmationEmailTemplateFormData(initConfirmationEmailTemplateFormData)
    }

    const handleShowConfirmationEmailTemplateModal = async (id: number | null, name: string) => {
        if (state.currentProviderId && id) {
            const res = await EventService.get(state.currentProviderId, id)

            if (res?.isSuccess) {
                setConfirmationEmailTemplateFormData({ ...confirmationEmailTemplateFormData, confirmationEmailSubject: res.data.confirmationEmailSubject, confirmationEmailBody: res.data.confirmationEmailBody })
                setSelectedItem({
                    id: id || 0,
                    name: name
                })
                setShowConfirmationEmailTemplateModal(true)
            } else {
                console.log(res?.message)
            }
        }
    }

    const handleConfirmationEmailTemplateFormControlChange = (e: ChangeEvent<HTMLInputElement>) => {
        setConfirmationEmailTemplateFormData({ ...confirmationEmailTemplateFormData, [e.target.name]: e.target.value })
    }

    const handleSaveConfirmationEmailTemplate = async (e: SyntheticEvent) => {
        e.preventDefault()

        setMessageConfirmationEmailTemplateFormModal("")

        if (!processing) {
            setProcessing(true)
            if (state.currentProviderId && selectedItem.id) {
                if (formConfirmationEmailTemplateRef.current?.checkValidity()) {
                    if (!confirmationEmailTemplateFormData.confirmationEmailBody) {
                        setMessageConfirmationEmailTemplateFormModal("Body is required.")
                    } else {
                        try {
                            const res = await EventService.updateConfirmationEmailTemplate(state.currentProviderId, selectedItem.id, confirmationEmailTemplateFormData)

                            if (res?.isSuccess) {
                                handleCloseConfirmationEmailTemplateModal()
                                setMessage(res.message)
                            } else {
                                setMessageConfirmationEmailTemplateFormModal(res?.message)
                            }
                        } catch (error) {
                            console.log(error)
                        }
                    }
                } else {
                    setValidatedConfirmationEmailTemplateForm(true)
                }
            }
            setProcessing(false)
        }
    }

    return (
        <>
            <ContentHeader title="Events" />

            <ContentBody>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <Card.Header>
                                    {hasPermission(Permissions.Events.Create) &&
                                        <Button variant="info" size="sm" onClick={handleAddNew}>
                                            <i className="fas fa-plus"></i> Create new event
                                        </Button>
                                    }
                                    <div className="card-tools">
                                        <InputGroup size="sm">
                                            <Form.Control
                                                type="search"
                                                placeholder="Search"
                                                ref={keywordRef as RefObject<HTMLInputElement>}
                                                onKeyUp={handleSearchInputKeyPress}
                                            />
                                            <Button variant="info" size="sm" onClick={handleSearch}>
                                                <i className="fas fa-search"></i>
                                            </Button>
                                        </InputGroup>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    {message !== "" && <Alert variant="info" onClose={() => setMessage("")} dismissible>{message}</Alert>}
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th style={{ textAlign: "center", width: "160px" }}>Thumbnail</th>
                                                <th>Name</th>
                                                <th>Start Date</th>
                                                <th>End Date</th>
                                                <th>Ticket Quantity</th>
                                                <th>Ticket Price</th>
                                                <th>Order Number</th>
                                                <th style={{ textAlign: "center", width: "100px" }}>Published</th>
                                                <th style={{ textAlign: "center", width: "80px" }}>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                tableData.data.map(item =>
                                                    <tr key={item.id}>
                                                        <td>{item.thumbnail && <img src={`${Configs.API_BASE_URL}${item.thumbnail}`} style={{ maxWidth: "100%" }} alt={item.name} />}</td>
                                                        <td>
                                                            <h6>#{item.id}. {item.name}</h6>
                                                            {item.timeZoneInfo && <p className="text-muted" style={{ fontSize: "11px", marginBottom: "0.5rem" }}>
                                                                <i className="fas fa-globe"></i> {item.timeZoneInfo.displayName}
                                                            </p>}
                                                            {hasPermission(Permissions.SeatTypes.View) &&
                                                                <Button variant="default" size="sm" onClick={() => handleShowSeatTypesModal(item.id, item.name)}>
                                                                    <i className="fas fa-tags text-info"></i> <span className="text-muted" style={{ fontSize: "11px" }}>Seat Types</span>
                                                                </Button>
                                                            }
                                                            {hasPermission(Permissions.Seats.View) &&
                                                                <Button variant="default" size="sm" onClick={() => handleShowSeatsModal(item.id, item.name)}>
                                                                    <i className="fas fa-chair text-info"></i> <span className="text-muted" style={{ fontSize: "11px" }}>Seats</span>
                                                                </Button>
                                                            }
                                                            {hasPermission(Permissions.Events.ConfirmationEmailTemplate) &&
                                                                <Button variant="default" size="sm" onClick={() => handleShowConfirmationEmailTemplateModal(item.id, item.name)}>
                                                                    <i className="fas fa-envelope text-info"></i> <span className="text-muted" style={{ fontSize: "11px" }}>Confirmation Email Template</span>
                                                                </Button>
                                                            }
                                                        </td>
                                                        <td>{Utils.dateToString(item.startDate)}</td>
                                                        <td>{Utils.dateToString(item.endDate)}</td>
                                                        <td>{Utils.formatNumber(item.ticketQuantity)}</td>
                                                        <td><span className="text-danger">{Utils.formatNumber(item.ticketPrice)}</span> {item.currency}</td>
                                                        <td>{item.orderNumber}</td>
                                                        <td>
                                                            <Form.Check type="switch" id={"published_" + item.id} checked={item.published} onChange={() => handlePublishedSwitch(item.id)} disabled={!hasPermission(Permissions.Events.Edit)} />
                                                        </td>
                                                        <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                                                            {hasPermission(Permissions.Events.Edit) &&
                                                                <Button variant="warning" size="sm" className="mr-1" onClick={() => handleEdit(item.id)}><i className="fas fa-pencil-alt"></i> Edit</Button>
                                                            }
                                                            {hasPermission(Permissions.Events.Delete) &&
                                                                <Button variant="danger" size="sm" onClick={() => showDeleteConfirmation(item.id, item.name)}><i className="fas fa-trash-alt"></i> Delete</Button>
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </Card.Body>
                                <Card.Footer>
                                    <Pagination hasNext={tableData.hasNext} hasPrevious={tableData.hasPrevious} pageIndex={tableData.pageIndex} pageSize={tableData.pageSize} totalPages={tableData.totalPages} totalRecords={tableData.totalRecords} handlePageChange={handlePageChange} />
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </ContentBody>

            <Modal show={showModal} onHide={handleCloseModal} backdrop="static" keyboard={false} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddOrRemoveLanguages selectedLanguages={formData.translations.map(x => x.language)} onChange={handleLanguagesChange} />
                    <Form noValidate validated={validatedForm} ref={formRef as RefObject<HTMLFormElement>}>
                        <Form.Group controlId="formFile" className="mb-3">
                            <Form.Label>Thumbnail</Form.Label>
                            <Card className="shadow-0">
                                {formData.thumbnail && <Card.Img variant="top" src={`${Configs.API_BASE_URL}${formData.thumbnail}`} style={{ width: "inherit", maxWidth: "300px", margin: "auto" }} />}
                                <Card.Body className="p-2">
                                    <Form.Control type="file" onChange={handleThumbnailChange} accept={Configs.IMAGE_EXTENSION} />
                                </Card.Body>
                            </Card>
                        </Form.Group>
                        <Tabs activeKey={activeTabKey} onSelect={(k) => setActiveTabKey(k || undefined)}>
                            {
                                formData.translations.sort((a, b) => { return (a.language.orderNumber || 0) - (b.language.orderNumber || 0) }).map(translate => {
                                    return (
                                        <Tab
                                            key={translate.languageId}
                                            eventKey={(translate.languageId).toString()}
                                            title={translate.language.name}
                                            className="p-3"
                                        >
                                            <Form.Group className="mb-3" controlId={"name_" + translate.languageId}>
                                                <Form.Label>Name</Form.Label>
                                                <Form.Control type="text" name="name" required value={translate?.name || ""} onChange={(e) => handleTranslateFormControlChange(e as any, translate.languageId)} />
                                                <Form.Control.Feedback type="invalid">Name is required.</Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group controlId={"description_" + translate.languageId}>
                                                <Form.Label>Description</Form.Label>
                                                <Form.Control as="textarea" rows={7} type="text" name="description" value={translate?.description || ""} onChange={(e) => handleTranslateFormControlChange(e as any, translate.languageId)} />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId={"venue_" + translate.languageId}>
                                                <Form.Label>Venue</Form.Label>
                                                <Form.Control type="text" name="venue" value={translate?.venue || ""} onChange={(e) => handleTranslateFormControlChange(e as any, translate.languageId)} />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId={"address_" + translate.languageId}>
                                                <Form.Label>Address</Form.Label>
                                                <Form.Control type="text" name="address" value={translate?.address || ""} onChange={(e) => handleTranslateFormControlChange(e as any, translate.languageId)} />
                                            </Form.Group>
                                            <Form.Check
                                                type="checkbox"
                                                id={`isActive_${translate.languageId}`}
                                                name="isActive"
                                                label="Active"
                                                checked={translate.isActive || false}
                                                onChange={(e) => handleTranslateFormControlChange(e as any, translate.languageId)}
                                            />
                                        </Tab>
                                    )
                                })
                            }
                        </Tabs>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="startDate">
                                    <Form.Label>Start Date</Form.Label>
                                    <DatePicker name="startDate" value={formData.startDate} onChange={handleFormControlChange}></DatePicker>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="endDate">
                                    <Form.Label>End Date</Form.Label>
                                    <DatePicker name="endDate" value={formData.endDate} onChange={handleFormControlChange}></DatePicker>
                                </Form.Group>
                            </Col>
                        </Row>
                        <TimeZoneSelect timeZoneId={formData.timeZoneId} onChange={handleFormControlChange}></TimeZoneSelect>
                        <fieldset className="mb-3">
                            <legend>Schedules</legend>
                            <Table>
                                <thead>
                                    <tr>
                                        <td style={{ width: "90px", borderTop: "none" }}>Start Time</td>
                                        <td style={{ width: "90px", borderTop: "none" }}>End Time</td>
                                        <td style={{ borderTop: "none" }}>Days</td>
                                        <td style={{ width: "48px", borderTop: "none" }}></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {formData.schedules.map((schedule, index) =>
                                        <tr key={index}>
                                            <td>
                                                <Form.Control type="time" name="startTime" required value={schedule.startTime || ""} onChange={(e) => handleScheduleFormControlChange(e as any, index)} />
                                            </td>
                                            <td>
                                                <Form.Control type="time" name="endTime" required value={schedule.endTime || ""} onChange={(e) => handleScheduleFormControlChange(e as any, index)} />
                                            </td>
                                            <td>
                                                <DaysOfWeekCheckboxes index={index} value={schedule.byDays} onChange={e => handleDaysChange(e, index)} />
                                            </td>
                                            <td>
                                                <Button variant="outline-danger" onClick={() => handleRemoveSchedule(index)} size="sm">
                                                    <i className="fas fa-minus"></i>
                                                </Button>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                            <Button variant="default" onClick={handleAddNewSchedule} size="sm" className="text-info" style={{ float: "right", padding: 0 }}>
                                <i className="fas fa-plus"></i> Add new Schedule
                            </Button>
                        </fieldset>
                        <Form.Group className="mb-1">
                            <Form.Label>Opening/Closing Time of Check-in Counters</Form.Label>
                            <Row>
                                <Col md={6}>
                                    <InputGroup>
                                        <InputGroup.Text id="openingTime">Opening Time</InputGroup.Text>
                                        <Form.Control type="number" min={0} name="openingTime" value={formData.openingTime || ""} onChange={(e) => handleFormControlChange(e as any)} />
                                        <InputGroup.Text>Minutes</InputGroup.Text>
                                    </InputGroup>
                                    <Form.Text>{formData.openingTime} Minutes Before Scheduled Start Time</Form.Text>
                                </Col>
                                <Col md={6}>
                                    <InputGroup>
                                        <InputGroup.Text id="closingTime">Closing Time</InputGroup.Text>
                                        <Form.Control type="number" max={formData.openingTime || undefined} name="closingTime" value={formData.closingTime || ""} onChange={(e) => handleFormControlChange(e as any)} />
                                        <InputGroup.Text>Minutes</InputGroup.Text>
                                    </InputGroup>
                                    <Form.Text>{Math.abs(formData.closingTime || 0)} Minutes {(formData.closingTime || 0) >= 0 ? <>Before</> : <>After</>} Scheduled Start Time</Form.Text>
                                </Col>
                            </Row>
                        </Form.Group>
                        <fieldset className="mb-3">
                            <legend>Contact Info</legend>
                            <Form.Group controlId="tel">
                                <Form.Label>Tel</Form.Label>
                                <Form.Control type="text" name="tel" value={formData.tel || ""} onChange={(e) => handleFormControlChange(e as any)} />
                            </Form.Group>
                            <Form.Group className="mt-3" controlId="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" name="email" value={formData.email || ""} onChange={(e) => handleFormControlChange(e as any)} />
                            </Form.Group>
                            <Form.Group className="mt-3" controlId="website">
                                <Form.Label>Website</Form.Label>
                                <Form.Control type="text" name="website" value={formData.website || ""} onChange={(e) => handleFormControlChange(e as any)} />
                            </Form.Group>
                        </fieldset>
                        <InputGroup className="mb-3 mt-4">
                            <InputGroup.Text>Ticket Price</InputGroup.Text>
                            <Form.Control type="number" min={0} name="ticketPrice" value={formData.ticketPrice || ""} onChange={(e) => handleFormControlChange(e as any)} />
                            <InputGroup.Text>Currency</InputGroup.Text>
                            <Form.Select name="currency" value={formData.currency || ""} onChange={(e) => handleFormControlChange(e as any)}>
                                <option value=""></option>
                                <option value="VND">VND</option>
                                <option value="USD">USD</option>
                            </Form.Select>
                            <InputGroup.Text>Ticket Quantity</InputGroup.Text>
                            <Form.Control type="number" min={0} name="ticketQuantity" value={formData.ticketQuantity || ""} onChange={(e) => handleFormControlChange(e as any)} />
                        </InputGroup>
                        <Form.Group controlId="orderNumber">
                            <Form.Label>Order Number</Form.Label>
                            <Form.Control type="number" name="orderNumber" value={formData.orderNumber || ""} onChange={(e) => handleFormControlChange(e as any)} />
                        </Form.Group>
                    </Form>
                    {messageFormModal !== "" && <Alert variant="danger" onClose={() => setMessageFormModal("")} dismissible>{messageFormModal}</Alert>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleCloseModal}><i className="fas fa-times"></i> Cancel</Button>
                    <Button variant="info" onClick={handleSave} disabled={processing}>
                        {processing ? <><Spinner animation="border" size="sm" /> Saving...</> : <><i className="fas fa-save"></i> Save</>}
                    </Button>
                </Modal.Footer>
            </Modal>

            <DeleteConfirmation show={displayConfirmationModal} onConfirm={handleDelete} onHide={hideConfirmationModal} id={selectedItem.id} name={selectedItem.name} />

            <SeatTypesModal eventId={selectedItem.id} title={selectedItem.name} show={showSeatTypesModal} onClose={handleCloseSeatTypesModal} />
            <SeatsModal eventId={selectedItem.id} title={selectedItem.name} show={showSeatsModal} onClose={handleCloseSeatsModal} />
            <Modal show={showConfirmationEmailTemplateModal} onHide={handleCloseConfirmationEmailTemplateModal} backdrop="static" keyboard={false} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Confirmation Email Template</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validatedConfirmationEmailTemplateForm} ref={formConfirmationEmailTemplateRef as RefObject<HTMLFormElement>}>
                        <Form.Group className="mb-3" controlId="confirmationEmailSubject">
                            <Form.Label>Subject</Form.Label>
                            <Form.Control type="text" name="confirmationEmailSubject" required value={confirmationEmailTemplateFormData?.confirmationEmailSubject || ""} onChange={handleConfirmationEmailTemplateFormControlChange} />
                            <Form.Control.Feedback type="invalid">Subject is required.</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Body</Form.Label>
                            <TextEditor contentHtml={confirmationEmailTemplateFormData.confirmationEmailBody || ""} onChange={contentHtml => { setConfirmationEmailTemplateFormData({ ...confirmationEmailTemplateFormData, confirmationEmailBody: contentHtml }) }} />
                        </Form.Group>
                        <Alert variant="secondary">
                            Template Variables: <br />{`{customerName}, {eventName}, {eventDate}, {eventStartTime}, {eventEndTime}, {tel}, {email}, {website}, {address}, {eventTicketId}, {createdOn}, {quantity}`}
                        </Alert>
                    </Form>
                    {messageConfirmationEmailTemplateFormModal !== "" && <Alert variant="danger" onClose={() => setMessageConfirmationEmailTemplateFormModal("")} dismissible>{messageConfirmationEmailTemplateFormModal}</Alert>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleCloseConfirmationEmailTemplateModal}><i className="fas fa-times"></i> Cancel</Button>
                    <Button variant="info" onClick={handleSaveConfirmationEmailTemplate} disabled={processing}>
                        {processing ? <><Spinner animation="border" size="sm" /> Saving...</> : <><i className="fas fa-save"></i> Save</>}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Events